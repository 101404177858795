import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Draft2018 } from '@state/draft-2018.actions';
import { NewCurriculum } from '@state/new-curriculum.actions';
import { OldCurriculum } from '@state/old-curriculum.actions';
import { filter, map } from 'rxjs/operators';

declare let gtag: Function;

@Component({
    selector: 'curriculum-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    constructor(private store: Store, private router: Router) {
        this.store.dispatch([new NewCurriculum.Load(), new OldCurriculum.Load(), new Draft2018.Load()]);
    }

    ngOnInit() {
        this.router.events.pipe(
            filter(e => e instanceof NavigationEnd),
            map((e: NavigationEnd) => {
                gtag('config', 'G-E9P7FJZEYE', {
                    'page_path': e.urlAfterRedirects
                })
            })
        ).subscribe();
    }
}
