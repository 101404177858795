import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'curriculum-elaborable',
    templateUrl: './elaborable.component.html',
    styleUrls: ['./elaborable.component.scss']
})
export class ElaborableComponent implements OnInit {
    @Input() value: string;
    @Input() elaborations: string[];

    displayVal = '';

    constructor() { }

    ngOnInit(): void {
    }

    getDisplayValue(): string {
        let result = '';
        let defIx = 0;

        const endDefSplit = this.value.split('</def>');

        // The <def>word</def> has a <def>definition</def>.
        for (let item of endDefSplit) {
            const startDefSplit = item.split('<def>');
            result += startDefSplit[0];

            if (startDefSplit.length > 1) {
                result += `<span class="elaborable ix-${defIx}">${startDefSplit[1]}</span>` + this.getElaboration(this.elaborations[defIx], defIx);
                defIx++;
            }

        }

        return result;
    }

    getElaboration(elaboration: string, ix: number) {
        return `<div class="elaboration ix-${ix}">${elaboration}</div>`;
    }
}
