<h4>Alberta Draft 2018 - {{subject}}</h4>
<div class="grade" *ngIf="!hasDataForGrade()">
    This version of the draft curriculum only covers grades K-4. Please refer to the 
    <a href="assets/2018%20Draft-K-4-Curriculum.pdf" target="_blank">2018 Draft Curriculum</a> document for more information.
</div>
<div class="grade" *ngIf="eus && eus.length === 0 && hasDataForGrade()">
    The curriculum for Fine Arts and Français immersion et littérature have not been added to the system yet. Please refer to the 
    <a href="assets/2018%20Draft-K-4-Curriculum.pdf" target="_blank">2018 Draft Curriculum</a> document for more information.
</div>
<div class="grade" *ngIf="eus && eus.length > 0 && hasDataForGrade()">
    <ng-container *ngFor="let eu of eus">
        <div class="eu">
            <small>Essential Understanding</small>
            {{ eu.content }}
        </div>
        <ng-container *ngFor="let gq of getGqs(eu)">
            <div class="gq">
                <small>Guiding Question</small>
                {{ gq.content }}
            </div>
            <ng-container *ngFor="let lo of gq.los">
                <div class="lo">
                    <small>Learning Outcome</small>
                    {{ lo.content }}
                    <div style="margin-top: 15px">
                        <small>Conceptual Knowledge</small>
                        <div [innerHTML]="getAsList(lo.cks)"></div>
                    </div>
                    <div>
                        <small>Procedural Knowledge</small>
                        <div [innerHTML]="getAsList(lo.pks)"></div>
                    </div>
                    <div>
                        <small>Competencies</small>
                        <div [innerHTML]="getAsList(lo.comp)"></div>
                    </div>
                    <div>
                        <small>Literacy</small>
                        <div [innerHTML]="getAsList(lo.lit)"></div>
                    </div>
                    <div>
                        <small>Numeracy</small>
                        <div [innerHTML]="getAsList(lo.num)"></div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>
</div>