import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'curriculum-gf2020',
    templateUrl: './gf2020.component.html',
    styleUrls: ['./gf2020.component.scss']
})
export class Gf2020Component implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
