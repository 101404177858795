import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NewCurriculumService {
  constructor(private http: HttpClient) {}

  getGqs(): Observable<ICourse[]> {
    return this.http.get<ICourse[]>('/assets/course.json');
  }

  getOrganizingIdeas(): Observable<ICurriculum[]> {
    return this.http.get<ICurriculum[]>('/assets/curriculum.json');
  }

  getGqsDec21(): Observable<ICourse[]> {
    return this.http.get<ICourse[]>('/assets/course-decv2.json');
  }

  getOrganizingIdeasDec21(): Observable<ICurriculum[]> {
    return this.http.get<ICurriculum[]>('/assets/curriculum-decv2.json');
  }

  getCourses(folder: string): Observable<ICourse[]> {
    return forkJoin({
      laneng: this.http.get<ICourse[]>(`assets/${folder}/course-laneng.json`),
      fardan: this.http.get<ICourse[]>(`assets/${folder}/course-fardan.json`),
      fardma: this.http.get<ICourse[]>(`assets/${folder}/course-fardma.json`),
      fargen: this.http.get<ICourse[]>(`assets/${folder}/course-fargen.json`),
      farart: this.http.get<ICourse[]>(`assets/${folder}/course-farart.json`),
      lanfra: this.http.get<ICourse[]>(`assets/${folder}/course-lanfra.json`),
      olafla: this.http.get<ICourse[]>(`assets/${folder}/course-olafla.json`),
      mat: this.http.get<ICourse[]>(`assets/${folder}/course-mat.json`),
      pde: this.http.get<ICourse[]>(`assets/${folder}/course-pde.json`),
      sci: this.http.get<ICourse[]>(`assets/${folder}/course-sci.json`),
      sss: this.http.get<ICourse[]>(`assets/${folder}/course-sss.json`),
    }).pipe(
      map((x) => {
        return [
          ...x.laneng,
          ...x.farart,
          ...x.fardan,
          ...x.fardma,
          ...x.fargen,
          ...x.lanfra,
          ...x.mat,
          ...x.olafla,
          ...x.pde,
          ...x.sci,
          ...x.sss,
        ];
      })
    );
  }

  getCurriculum(folder: string): Observable<ICurriculum[]> {
    return forkJoin({
      laneng: this.http.get<ICurriculum[]>(`assets/${folder}/curriculum-laneng.json`),
      fardan: this.http.get<ICurriculum[]>(`assets/${folder}/curriculum-fardan.json`),
      fardma: this.http.get<ICurriculum[]>(`assets/${folder}/curriculum-fardma.json`),
      fargen: this.http.get<ICurriculum[]>(`assets/${folder}/curriculum-fargen.json`),
      farart: this.http.get<ICurriculum[]>(`assets/${folder}/curriculum-farart.json`),
      lanfra: this.http.get<ICurriculum[]>(`assets/${folder}/curriculum-lanfra.json`),
      olafla: this.http.get<ICurriculum[]>(`assets/${folder}/curriculum-olafla.json`),
      mat: this.http.get<ICurriculum[]>(`assets/${folder}/curriculum-mat.json`),
      pde: this.http.get<ICurriculum[]>(`assets/${folder}/curriculum-pde.json`),
      sci: this.http.get<ICurriculum[]>(`assets/${folder}/curriculum-sci.json`),
      sss: this.http.get<ICurriculum[]>(`assets/${folder}/curriculum-sss.json`),
    }).pipe(
      map((x) => {
        return [
          ...x.laneng,
          ...x.farart,
          ...x.fardan,
          ...x.fardma,
          ...x.fargen,
          ...x.lanfra,
          ...x.mat,
          ...x.olafla,
          ...x.pde,
          ...x.sci,
          ...x.sss,
        ];
      })
    );
  }
}

export interface ITranslation {
  'en-ca': string;
  'fr-ca': string;
}

export interface IAlbertaDraft2021Curriculum {
  curriculum: ICurriculum[];
  course: ICourse;
}

export interface ICourse {
  code: string;
  names: ITranslation;
  keywordCodes: string[];
  categoryCode: string;
  intendedGradeLevel: string;
  curriculums: ICurriculum[];
}

export interface ICurriculum {
  code: string;
  parentCurriculumCode: string;
  categoryCode: string;
  typeCode: TypeCode;
  keywordCodes: string[];
  courseCodes: string[];
  curriculums: ICurriculum[];
  contents: ITranslation;
  sortOrder: number;
}

export enum TypeCode {
  organizingIdea = 'OI',
  knowledge = 'K',
  understanding = 'U',
  skillsAndProcedures = 'SP',
}
