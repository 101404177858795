import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class Draft2018Service {

    constructor(private http: HttpClient) { }

    get(): Observable<ICourse2018[]> {
        return this.http.get<ICourse2018[]>('/assets/curriculum2018.json');
    }
}

export interface ICourse2018 {
    course: string;
    eus: IEu[];
}

export interface IEu {
    content: string;
    gqs: IGq[];
}

export interface IGq {
    content: string;
    grade: string;
    los: ILo[];
}

export interface ILo {
    content: string;
    cks: string;
    pks: string;
    comp: string;
    lit: string;
    num: string;
}