<h4>Saskatchewan - {{subject}}</h4>
<ng-container *ngIf="category">
    <div class="strand" *ngFor="let strand of getStrands()">
        <div class="strand-group">
            <div class="name">{{strand.name}}</div>
            <div class="goals" *ngIf="strand.content"><em>{{strand.content}}</em></div>
        </div>
        <ng-container *ngIf="getLos(strand) as los">
            <ng-container *ngFor="let outcome of los; let ix = index;">
                <div class="category" *ngIf="outcome.category && (ix === 0 || los[ix-1].category !== outcome.category)">
                    {{outcome.category}}</div>
                <div class="outcome" [ngClass]="{'has-category': outcome.category}">
                    <div class="header">
                        <small>Outcome</small>
                        <a target="_blank" [href]="outcome.url">
                            <mat-icon>launch</mat-icon>
                        </a>
                    </div>

                    <div [innerHTML]="outcome.content"></div>
                    <div class="indicators">
                        <small>Indicators</small>
                        <p class="note" *ngIf="outcome.indicatorNote" [innerHTML]="outcome.indicatorNote"></p>
                        <ol>
                            <li *ngFor="let indicator of outcome.indicators" [innerHTML]="indicator"></li>
                        </ol>
                        <p class="note" *ngIf="outcome.postIndicatorNote" [innerHTML]="outcome.postIndicatorNote"></p>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
</ng-container>