<div class="content">
    <div class="panels">
        <curriculum-panel [panelType]="PanelType.Left" [isExpanded]="isExpanded" [filters]="leftFilters"
            [selectedCurriculum]="selectedLeftCurriculum" [selectedFilters]="selectedLeftFilters"
            (prevGrade)="onPrev(PanelType.Left)" (nextGrade)="onNext(PanelType.Left)"
            (filterChange)="onSelectionChange(PanelType.Left)">
        </curriculum-panel>
        <curriculum-panel [panelType]="PanelType.Right" [isExpanded]="isExpanded" [filters]="rightFilters"
            [selectedCurriculum]="selectedRightCurriculum" [selectedFilters]="selectedRightFilters"
            (prevGrade)="onPrev(PanelType.Right)" (nextGrade)="onNext(PanelType.Right)"
            (filterChange)="onSelectionChange(PanelType.Right)">
        </curriculum-panel>
    </div>
    <curriculum-footer></curriculum-footer>
</div>
<div class="mobile">
    <button class="filter-btn" (click)="isExpanded = !isExpanded">
        <span *ngIf="!isExpanded">
            Expand filters <mat-icon>expand_more</mat-icon>
        </span>
        <span *ngIf="isExpanded">
            Collapse filters <mat-icon>expand_less</mat-icon>
        </span>
    </button>
</div>