<h4>British Columbia - {{subject}}</h4>
<div class="category" *ngIf="category">
    <div class="header">
        <h3>Core Competencies</h3>
        <a target="_blank" [href]="category.url">
            <mat-icon>launch</mat-icon>
        </a>
    </div>
    <div class="core-competencies">
        <div class="core-competency" *ngFor="let coreCompetency of category.coreCompetencies"
            [style.borderColor]="coreCompetency.colour">
            {{coreCompetency.name}}
        </div>
    </div>

    <div class="header">
        <h3>Big Ideas</h3>
    </div>
    <div class="big-ideas">
        <div class="big-idea" *ngFor="let bigIdea of category.bigIdeas">
            <ng-container *ngIf="hasDef(bigIdea.name)">
                <span *ngFor="let word of getBigIdeaSplit(bigIdea.name)">
                    <span class="has-elaboration" *ngIf="hasDef(word); else noDef" [innerHTML]="word" (click)="bigIdea.isElaborationVisible = !bigIdea.isElaborationVisible"></span>
                    <ng-template #noDef>
                        {{word}}
                    </ng-template>
                </span>
            </ng-container>
            <span *ngIf="!hasDef(bigIdea.name)" [ngClass]="{'has-elaboration': !!bigIdea.elaborations}"
                (click)="bigIdea.isElaborationVisible = !bigIdea.isElaborationVisible">{{bigIdea.name}}</span>
            <div *ngIf="bigIdea.elaborations && bigIdea.isElaborationVisible" class="elaborations"
                [innerHTML]="bigIdea.elaborations"></div>
        </div>
    </div>

    <div class="header">
        <h3>Curricular Competency</h3>
        <button mat-raised-button (click)="onToggleCompetencyElaborations()">
            Elaborations <span *ngIf="!areAllCompetencyElaborationsVisible()">+</span><span
                *ngIf="areAllCompetencyElaborationsVisible()">-</span>
        </button>
    </div>
    <div class="curricular-competency">
        <em>Students are expected to be able to do the following:</em>
        <div *ngFor="let competencySection of category.curriculumCompetencies">
            <strong class="section-header" *ngIf="competencySection.section" [ngClass]="{'has-elaboration': !!competencySection.elaborations}"
                (click)="competencySection.isElaborationVisible = !competencySection.isElaborationVisible">{{competencySection.section}}</strong>
            <div *ngIf="competencySection.elaborations && competencySection.isElaborationVisible" class="elaborations"
                [innerHTML]="competencySection.elaborations"></div>
            <ul>
                <li *ngFor="let competency of competencySection.competencies">
                    <ng-container *ngFor="let value of competency.values">
                        <span [ngClass]="{'has-elaboration': !!value.elaborations}"
                            (click)="value.isElaborationVisible = !value.isElaborationVisible">{{value.name}}</span>
                        <div *ngIf="value.elaborations && value.isElaborationVisible" class="elaborations"
                            [innerHTML]="value.elaborations"></div>
                    </ng-container>
                </li>
            </ul>
        </div>
    </div>

    <div class="header">
        <h3>Content</h3>
        <button mat-raised-button (click)="onToggleContentElaborations()">
            Elaborations <span *ngIf="!areAllContentElaborationsVisible()">+</span><span
                *ngIf="areAllContentElaborationsVisible()">-</span>
        </button>
    </div>
    <div class="content">
        <em>Students are expected to know the following:</em>
        <ul>
            <li *ngFor="let content of category.content">
                <ng-container *ngFor="let value of content.values">
                    <span [ngClass]="{'has-elaboration': !!value.elaborations}"
                        (click)="value.isElaborationVisible = !value.isElaborationVisible">{{value.name}}</span>
                    <div *ngIf="value.elaborations && value.isElaborationVisible" class="elaborations"
                        [innerHTML]="value.elaborations"></div>
                    <ul *ngIf="value.content">
                        <li *ngFor="let content2 of value.content">
                            <ng-container *ngFor="let value2 of content2.values">
                                <span [ngClass]="{'has-elaboration': !!value2.elaborations}"
                                    (click)="value2.isElaborationVisible = !value2.isElaborationVisible">{{value2.name}}</span>
                                <div *ngIf="value2.elaborations && value2.isElaborationVisible" class="elaborations"
                                    [innerHTML]="value2.elaborations"></div>
                            </ng-container>
                        </li>
                    </ul>
                </ng-container>
            </li>
        </ul>
    </div>
</div>