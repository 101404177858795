import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { IPos } from '@services/old-curriculum.service';
import { Draft2018Service, ICourse2018 } from '@services/draft-2018.service';
import { Draft2018 } from './draft-2018.actions';

export class Draft2018StateModel {
    programs: ICourse2018[];
}

const defaults: Draft2018StateModel = {
    programs: []
};

@State<Draft2018StateModel>({
    name: 'draft2018',
    defaults: defaults
})
@Injectable()
export class Draft2018State {
    constructor(private draft2018Service: Draft2018Service) {

    }

    @Action(Draft2018.Load)
    onLoad(ctx: StateContext<Draft2018StateModel>, action: Draft2018.Load) {
        return this.draft2018Service.get().pipe(
            map(result => {
                ctx.patchState({ programs: result })
            })
        );
    }

    @Selector()
    static programs(state: Draft2018StateModel): ICourse2018[] {
        return state.programs;
    }
}
