<h2 style="color: #333">The Guiding Framework for the Design and Development of Kindergarten to Grade 12 Provincial
    Curriculum (Programs of Study) - 2016</h2>

<h2>Foreword</h2>
<p>Education is foundational to Alberta’s democratic and civil society.<sup>1</sup>
    It is the sum of all learning
    experiences at home, at school and in the community that influence students to create a positive
    future for themselves, their families and their communities. Education requires collaboration<sup>2</sup> and
    commitment among schools, families and communities to prepare all students to reach their
    full potential. </p>
<p class="footnote"><sup>1</sup> WHEREAS education is the foundation of a democratic and civil society” (Education Act, p. 10).</p>
<p class="footnote"><sup>2</sup> WHEREAS education is a shared responsibility and requires collaboration, engagement and empowerment of all partners in
    the education system to ensure that all students achieve their potential” (Education Act, p. 10).</p>
<p>There are many definitions of curriculum. This document refers to <span class="ul">provincial curriculum</span>, also
    known as <span class="ul">programs of study:</span> “what” students are expected to know, understand and be able to
    do
    in each subject and grade. Teachers have the flexibility to determine “how” students achieve the
    expected learning outcomes to bring the provincial curriculum to life in the classroom through
    meaningful learning activities.
</p>
<p>The primary role of <em>The Guiding Framework for the Design and Development of Kindergarten to
        Grade 12 Provincial Curriculum (Programs of Study) (The Guiding Framework)</em> ) is to set a
    common direction for provincial curriculum development, using plain language while preserving
    the complexity appropriate for professional teachers. The primary audiences of this document are
    curriculum developers, teachers, school and school authority administrators, trustees, parents and
    guardians.</p>
<p>Consultations on provincial curriculum and the processes to develop curriculum have been
    ongoing over the past years. <em>The Guiding Framework</em> builds on development activities and
    engagements, reviews and analysis of research by Alberta Education, as well as reviews of
    various discussion papers prepared by stakeholders, such as The Alberta Teachers’ Association
    and the College of Alberta School Superintendents and others. As well, it consolidates the
    ongoing research and reviews of pan-Canadian and international curriculum, recently developed
    or under development.
</p>
<p>The Introduction to the Alberta’s Kindergarten to Grade 12 (K-12) Provincial Curriculum is a declaration of what
    Albertans value in education. It outlines the vision for students as lifelong learners and the student values we
    aspire for all students to explore and achieve. In setting the direction for the provincial curriculum, this
    document also reflects government policy and commitments relating to economic diversification, climate change,
    wellness, pluralism, diversity, inclusion, First Nations, Métis and Inuit<sup>3</sup>
    education for reconciliation, and Francophone cultures and perspectives. Curriculum has a role in creating a
    positive future for Alberta, by preparing students for a more diversified economy, and by helping them to improve
    health outcomes and address global environmental challenges such as climate change.</p>
    <p class="footnote"><sup>3</sup> First Nations is a term to identify descendants of the original inhabitants of this land we now know as Canada. This term was created by these people to which it identifies (Assembly of First Nations, n.d.). The Métis are distinct peoples who have developed their own customs and way of life stemming beyond mere mixing of their historical ancestry of First Nations and European peoples (Métis Nation of Alberta, 2016). The Inuit are another distinct group of peoples who are the original inhabitants of the North American Arctic. They have a unique cultural heritage and language that vastly differs from First Nations and Métis peoples (Inuulitsivik, 2011–2016).</p>
<p>Provincial curriculum (also referred to in this document as “curriculum” or a program of studies) is a core element
    of formal education and the basis of learning experiences that students encounter and experience in a variety of
    environments. Provincial curriculum consists of the K-12 courses and programs of study<sup>4</sup>
    developed by Alberta Education and prescribed by the Minister of Education.</p>
    
    <p class="footnote"><sup>4</sup> “The Minister may do the following: a) prescribe courses of study or education programs” (School Act, Section 39(1)).</p>
<p><em>The Guiding Framework</em> also describes the purpose of curriculum and includes the common principles and
    standards that guide the development of curriculum. Additionally, it identifies the common features of subject-based
    curriculum for Language Arts (English Language Arts, French Language Arts, Français), Mathematics, Science, Social
    Studies, Arts Education and Wellness Education, including architecture and design.</p>
<p>Architecture is the systematic and logical arrangement of the common components of K-12 provincial curriculum. Design
    explains the relationships among the components identified in the architecture of provincial curriculum. It is the
    consistent application of curriculum philosophies, principles and standards, scope and sequence to achieve coherence
    within and across subjects and grades.</p>


<h3>Introduction to Alberta’s Kindergarten to Grade 12 Provincial Curriculum</h3>
<h4>Vision for Students</h4>
<p>Students are at the heart of Alberta’s Kindergarten to Grade 12 education system. The following vision articulates
    hopes and expectations for all students:</p>
<div class="quote"><em>Students are lifelong learners inspired to pursue their aspirations and interests; achieve
        fulfilment and success; and contribute to communities and the world.</em><sup>5</sup>
</div>
<p class="footnote">
    <sup>5</sup> – Education Business Plan 2015-18
    <br />
    – Modified from the first Whereas in Ministerial Order #001/2013 where it states “the fundamental goal of education in Alberta is to inspire all students to achieve success and fulfillment, and reach their full potential…”
    <br />
    – “WHEREAS education inspires students to discover and pursue their aspirations and interests and cultivates a love of learning and the desire to be lifelong learners” (Education Act, p. 10).
</p>
<h4>Student Values</h4>
<p>Values represent beliefs about the important and desirable characteristics for Alberta’s students. Student values<sup>6</sup>
    include the following:</p>
    <p class="footnote"><sup>6</sup> Student values are derived from Ministerial Order on Student Learning (#001/2013) and the Education Act.</p>
<ul>
    <li><em><strong>Democracy and Citizenship,</strong> by treating others ethically, with fairness and equity; by
            demonstrating social justice when taking action; by contributing to the common good; by building
            cross-cultural awareness and celebrating diversity in our pluralistic society; and by contributing to local
            and global communities;</em></li>
    <li><em><strong>Belonging and Identity,</strong> by exploring identity and developing a sense of self and belonging;
            by confidently interacting and engaging with others; by embracing opportunities to learn through interaction
            with others; by pursuing dreams, talents and aspirations; and by recognizing diverse abilities and the
            importance of cultures and languages as part of a bilingual, multicultural, inclusive and pluralistic
            society;</em></li>
    <li><em><strong>Integrity and Respect,</strong> by demonstrating concern for self and others; by building and
            nurturing healthy relationships; by demonstrating empathy and compassion; by affirming truth; and by acting
            honourably and ethically;</em></li>
    <li><em><strong>Perseverance and Excellence,</strong> by demonstrating optimism, confidence, courage and resiliency
            when confronted with challenges or pursuing new ideas; by setting goals and being accountable for results;
            by striving for well-being of self and others; and by making responsible choices and adapting to change;
            and</em></li>
    <li><em><strong>Innovation and Stewardship,</strong> by thinking logically, creatively and critically to foster
            ingenuity, imagination and curiosity; by taking risks; by achieving goals through hard work; and by taking
            personal and collective responsibility for the public good and to improve community, foster environmental
            stewardship and ensure a sustainable world with a hopeful future.</em></li>
</ul>
<h3>Considerations for Design and Development of Alberta’s Kindergarten to Grade 12 Provincial Curriculum</h3>
<p>Drawing upon research, recent consultations on provincial curriculum and what Albertans value in education, the
    following considerations will underpin the design and development of Alberta’s K–12 provincial curriculum.</p>
<h4>Nature of Learning and the Learner</h4>
<p>Understanding the nature of learning and the learner is the starting point for building a learner-focused,
    knowledge-centred and meaningful provincial curriculum for students in Alberta. Research in the past few decades on
    the nature of learning highlights the complexity of what once appeared to be a fairly straightforward, laddered
    process but is now currently understood as an embodied and embedded dynamic web.<sup>7</sup></p>
        <p class="footnote"><sup>7</sup> Davis, Sumara, & Luce-Kapler, 2008.</p>
<p>Provincial curriculum must resonate with the embodied understandings of learning.<sup>8</sup>
    The study of learning is transdisciplinary, drawing upon neurology, psychology, biology, sociology, anthropology and
    ecology. Neuroscience, cognitive neuroscience and developmental psychology affirm that learning is a complex,
    nonlinear and dynamic process. The dominant model of the human mind in Western societies still tends to view the
    brain as the core organ that carries much of consciousness and learning. Although understanding the brain is
    essential, brains are part of bodies, not the other way around. For example, neuroscience research reveals that
    exercise increases the efficiency of neural networks that are important for learning; studies of school-aged
    children have corroborated the positive effects of exercise on cognitive functions. Learning is, therefore, active,
    embodied and contextualized.</p>
    <p class="footnote"><sup>8</sup> Learners cannot be studied independently of the contexts of their existences. The notion that learning is situated or embedded is integral to many contemporary theories, including indigenous pedagogy, social constructionism, situated cognition, sociocultural theory, cultural–historical theory, activity theory, actor–network theory, distributed cognition and semiotic pedagogy.</p>
<p>Learning involves the mindful and effortful involvement of students in the individual and social processes of
    knowledge and skill acquisition through interaction with the environment. Learning thrives in contexts that view
    learners as central participants, encourage engagement and activate learners’ prior knowledge and evolving
    understanding of themselves as learners.<sup>9</sup> The capacity to learn
    enables students to transfer knowledge across contexts and to address unfamiliar problems. This capacity is built
    over time and through multiple opportunities to utilize foundational learning and deepen understanding. Meaningful
    learning experiences based on real-life situations or community involvement through Elders or others, service
    learning, career development, field experiences and academic studies can engage and motivate students to learn.
    Emotional and cognitive aspects of learning are interdependent and critical to learners’ intrinsic motivation.
    Knowledge and understanding are built through reflection and interaction with others.<sup
       >10</sup></p>
        <p class="footnote"><sup>9</sup> Istance, & Dumont, 2010.</p>
        <p class="footnote"><sup>10</sup> Hinton, & Fischer, 2010.</p>
<p>Learning is also embedded. It is embedded in relationships, culture, family, Elders, Knowledge Keepers, community,
    land, connections, memory and history. Embedded understandings of learning highlight the participatory and
    collective nature of learning. School settings that acknowledge the embedded nature of learning recognize that
    knowledge, skills and attitudes need to be connected and related to the world and the culture and community, rather
    than abstracted, dislocated or decontextualized fragments.</p>
<p>Parents, guardians, other family members, significant adults and peers influence and support the healthy mental and
    physical well-being of learners throughout their growth and development. Developing positive relationships with
    these role models, in addition to teachers, principals, coaches and Elders, can help students make good decisions
    and life choices throughout their schooling.</p>
<p>Culture and society have significant influence on how children develop and learn. Children bring their culturally
    constructed understandings and experiences of the social and natural world and their unique capabilities, attitudes
    and dispositions to their various learning environments. In order to reach their potential, learners need to be
    intellectually engaged and experience belonging, acceptance and resiliency.</p>
<h4 style="color: #333">Kindergarten to Grade 12 Provincial Curriculum Considerations</h4>
<p>Student engagement is one branch of educational research that has been particularly attentive to the
    transdisciplinary character of learning, drawing upon both the embodied and embedded aspects. Provincial curriculum
    that intellectually engages students calls for designs that help students develop interconnected pathways within a
    discipline and across disciplines. It provides opportunities to bind people together to build social cohesion and
    produce minds that thirst to build knowledge throughout the course of their lives.</p>
<p>Alberta’s provincial curriculum is structured and designed to activate deep, meaningful learning and understanding
    by:</p>
<ul>
    <li>providing the opportunities needed for depth of learning and understanding to emerge. This means that students
        must experience concepts numerous times and in numerous ways through various disciplines by building on prior
        knowledge and experiences;</li>
    <li>developing subject scopes and sequences that facilitate both in-depth learning in the subject and
        cross-curricular approaches to learning through competencies and literacy and numeracy that transcend subjects,
        and are critical in ensuring coherence within and across subjects;</li>
    <li>integrating competencies with subject- and grade-specific conceptual and/or procedural knowledge in creating
        learning outcomes that ensure students, in “knowing things”, are also able to apply what they know. This means
        going beyond “learning about” to learning the ways of knowing, doing and being, and acknowledging that ideas
        need to be brought into relationship with each other because the problem, topic and concept at hand is complex.
        Teachers and students need to be able to make connections within and outside of the school or the subject;
        provincial curriculum must therefore be created to allow for these connections to emerge; and</li>
    <li>requiring, respecting and sustaining multiple ways of knowing, acknowledging the embedded nature of learning.
        Individuals, communities and cultural groups hold different ways of knowing. In addition, each of the
        disciplines have particular ways of working and ways of knowing.</li>
</ul>
<p>Provincial curriculum is organized into a continuum that aligns with the descriptions of the three transitions from
    early to middle to later schooling described below. These are not meant to be discrete, but rather overlapping
    developmental progressions. Current developmental theories take a multidimensional perspective to describe and
    explain child development as a complex process that involves multiple layers of interacting causes for change:
    physical/molecular, biological, psychological, social and cultural. Childhoods differ depending on their lived
    social and cultural circumstances. Developmental science is paying increasing attention to cultural, ethnic, gender
    and socioeconomic variations in developmental processes and outcomes. There are multiple ways of being a child.</p>
<h4 style="color: #333">The Early Years: Kindergarten–Grade 4</h4>
<p>The nature of children’s early experiences has long-term impact on their learning and personal growth. Young learners
    need rich and varied opportunities to explore their environments, use language, engage with peers and interact with
    family members at home, with responsive adults at school and in their communities. Children develop and mature as
    active, independent and collaborative learners when they are able to make connections between what they learn in
    school and in their life.</p>
<p>Children strive for intellectual independence. Children are curious and have questions. They want to know why things
    are the way they are. Children progress through their intellectual growth and develop a reasoning mind that can
    apply the power of their imaginations. Questions of morality, fairness, mercy and justice fascinate young students
    as they become more socially oriented and compassionate towards others.</p>
<p>Provincial curriculum and related learning experiences in the early years and throughout schooling must support
    students in thinking of themselves as confident, curious, creative and innovative; and as responsible contributors,
    capable communicators and problem solvers. It is in the early years that the foundation is laid for later learning.
    Throughout schooling, curriculum needs to engage students in rich learning experiences while developing a solid base
    of literacy and numeracy and social skills. Curriculum also needs to develop in students the understandings and
    competencies that both relate to, and make, connections across subjects. While the curriculum includes subjects with
    their distinctive knowledge and understandings, curriculum provides greater opportunity for drawing together
    understandings within and across subjects.</p>
<h4 style="color: #333">The Middle Years: Grades 5–10</h4>
<p>Adolescence is a period of rapid physical, emotional, cognitive and social development. Identity formation is
    critical to learners in the middle years as they transition from childhood to adolescence. During adolescence,
    students have an increased sense of self and strive for independence. Learners build upon their skills and knowledge
    while assessing and enhancing their competencies, and identifying their strengths, talents and interests for further
    study and careers. Students in the middle years also seek social, academic and intellectual engagement.</p>
<p>Teachers and students work together as a community of learners to support each other in learning. Students are
    increasingly engaged in their learning and become active citizens of their school and community. Students in these
    years begin thinking about their future after schooling and initiate discussions with peers, teachers and family
    members on how to reach educational and occupational goals. During these years, a supportive environment and high
    expectations are critical in creating positive and optimistic attitudes towards learning.</p>
<p>Provincial curriculum and programming<sup>11</sup>
    in these years provides students with opportunities to develop and practise empathy, care-giving and service to
    others. Building these positive relationships within the community helps students to experience the relevance of
    what they learned in school within a broader context.</p>
    <p class="footnote"><sup>11</sup>Curriculum is K–12 programs of study that set the standards for student learning and are prescribed by the Minister of Education. “Programming takes place at the local level and involves effective student engagement with the programs of study. Programming decisions are made by school authorities, schools, teachers and students” (Alberta Education, 2015a, September, p. 5).</p>
<h4 style="color: #333">The Later Years: Grades 11–12</h4>
<p>Learners are increasingly engaged in, and curious about, events and issues and have a heightened awareness and
    capacity to act for the benefit of local and global communities. Learning environments that challenge and support
    learners in the later years need to be learning-focused, supportive and knowledge-centred. As adolescents transition
    into adaptive adulthood, they benefit from learning opportunities that are relevant to their talents, passions,
    future pathways and career aspirations. They acquire information on post-secondary careers and studies, and
    participate in specialized subject-based educational opportunities that lead to specific occupations and related
    studies.</p>
<p>Students need to be fully aware of these flexible and innovative pathways-based programming opportunities. Students
    also need to be self-aware and confident in their strengths and areas requiring growth. Raising aspirations of
    students through discussions with family, counsellors, employers, and post-secondary and community members can
    ensure that decisions relating to further studies and careers are made with full knowledge of options, as well as
    their interests and needs.</p>
<p>Provincial curriculum and programming in these years reflect increased specialization and differentiation in
    providing an academic foundation and career preparation for adulthood. Students may benefit from flexible and
    innovative programming options, such as dual credentialing, that blur the boundaries among Kindergarten to Grade 12
    and post-secondary education, work-based training, internship and employment. These options could include full- or
    part-time apprenticeship, dual-credit training and education, part-time schooling combined with part-time training
    pathways, work study, self-employment or volunteering in a community-based social or cultural enterprise.</p>
<h4>Pluralism and Diversity<sup>12</sup></h4>
<p class="footnote"><sup>12</sup> 
    – Standard 2: Curriculum includes multiple, diverse perspectives that reflect our pluralistic society and the important roles and responsibilities of Alberta citizens within provincial, national and international contexts. NOTE: All standards are provided in Appendix A.
    <br />
    - “All courses or programs of study and instructional materials used in a school must reflect the diverse nature and heritage of society in Alberta, promote understanding and respect for others and honour and respect the common values and beliefs of Albertans” (School Act, Section 3(1)).</p>
<p>As a pluralistic society, Alberta recognizes and celebrates diversity. Pluralism is intended to safeguard and foster
    understanding and respect for social, cultural, religious and ethnic diversity. Provincial curriculum is intended to
    reflect the values of society and prepare students to be contributing citizens of our pluralistic and increasingly
    diverse society.</p>
<p>Embracing diversity is essential for fostering social cohesion in a pluralistic society. Social cohesion is a process
    that requires the development of relationships within and among communities and a commitment to respectful dialogue
    and openness. In promoting social cohesion, the curriculum endeavours to develop an understanding of the need for
    civic responsibilities, respecting individual and collective rights, building shared values and appreciating the
    rule of law and democracy.</p>
<p>Pluralism requires support for social justice for all people and groups to ensure that students are not prevented
    from achieving the full benefits of participation in society. This also addresses injustice faced by those who
    historically were and continue to be marginalized or experience discrimination.</p>
<p>Ethnicity, socioeconomic conditions, community, religion, culture, gender expressions and gender identities are among
    various forms of identification that people—including immigrant and refugee families—live with and experience in a
    variety of ways. Through the provincial curriculum, students value diversity and recognize differences as positive
    attributes. They recognize the evolving nature of individual and collective identities in working out differences,
    celebrating commonalities and in constructing social cohesion. Students are encouraged to develop a commitment to
    respect differences and foster inclusiveness and understand and appreciate each other’s values. Students as ethical
    and engaged citizens, develop respect for democratic principles and processes for decision making.</p>
<p>Alberta’s provincial curriculum strives to ensure that differences among learners do not impede participation in
    school and community, academic achievement or capacity to access further study and careers; which leads them to
    become contributing members of society. The curriculum can promote understanding of the roles and contributions of
    linguistic, cultural and ethnic groups in Canada. By helping students learn about themselves in relation to others
    and function as citizens in a pluralistic society that values diversity, the curriculum also helps students
    understand how diversity and differences are assets that enrich lives and are essential to creating healthy
    communities.</p>
<p>Finally, the growing diversity in the population can help advance and develop Alberta’s strategic international
    interests and relationships. Alberta’s provincial curriculum needs to prepare students to work and succeed in a
    world of diversity. Global and cultural understanding gained from the provincial curriculum is vital to Alberta’s
    prosperous, value-added and sustainable economy.</p>
<h4>Inclusion<sup>13</sup>
</h4>
<p class="footnote"><sup>13</sup>
    – Standard 1: Curriculum is designed to enable learning opportunities that are equitable, inclusive and accessible to all Alberta students. NOTE: All standards are provided in Appendix A.
    <br />
    – “WHEREAS the Government of Alberta recognizes the importance of an inclusive education system that provides each student with the relevant learning opportunities and supports necessary to achieve success” (Education Act, p. 11).</p>
<p>A high-quality, publicly funded education system is crucial to Alberta’s democratic society. In 2005, the United
    Nations Educational, Scientific and Cultural Organization’s (UNESCO) <em>Guidelines for Inclusion: Ensuring Access
        to Education for All</em> recognized that it is essential that an educational system be equitable and inclusive
    in order for all learners to receive a high-quality education. Setting high expectations for all children and
    students in Alberta’s provincial curriculum is fundamental to their success.</p>
<p>Inclusion is a way of thinking and acting that demonstrates universal acceptance of, and belonging for, all children
    and students. Alberta’s inclusive K-12 education system embraces diversity and learner differences, and promotes
    equitable opportunities for all learners in safe and caring environments. Alberta’s Inclusive Education Policy<sup>14</sup>
    is built on a values-based approach to accepting responsibility for all children and students. It takes into account
    learners’ diverse backgrounds, interests and experiences to develop the foundation needed for lifelong learning.</p>
    <p class="footnote"><sup>14</sup> Alberta’s Inclusive Education Policy states, “… all children and students (Kindergarten to Grade 12), regardless of race, religious belief, colour, gender, gender identity, gender expression, physical disability, mental disability, family status or sexual orientation, or any other factor(s), have access to meaningful and relevant learning experiences that include appropriate instructional supports” (Alberta Education, 2015a, September, p. 25).</p>
<h4>First Nations, Métis and Inuit Experiences and Perspectives<sup>15</sup>
</h4>
<p class="footnote"><sup>15</sup>
    – Standard 3: Curriculum includes ways of knowing and diverse perspectives, in historical and contemporary contexts, of First Nations, Métis and Inuit. NOTE: All standards are provided in Appendix A.
    <br />
    – “WHEREAS the Government of Alberta is committed to encouraging the collaboration of all partners in the education system to ensure the educational success of Alberta’s First Nations, Métis and Inuit students” (Education Act, p. 11).</p>
<p>As the first peoples, First Nations, Métis and Inuit include many diverse peoples and unique cultures across Canada.
    Although there may be common threads that run through the different groups, communities have unique cultures,
    traditions, languages, experiences and perspectives which form their worldview. Within Alberta, there are 48 First
    Nations in the territories across Treaty 6, Treaty 7, Treaty 8, and eight Métis Settlements as well as many First
    Nations, Métis and Inuit living in urban and rural communities. Each of these communities have unique cultures and
    perspectives. All students within these communities need to see themselves within Alberta’s provincial curriculum.
</p>
<p>Elders and Knowledge Keepers are recognized in their respective communities as holders of cultural knowledge, wisdom
    and oral tradition. They are essential in transferring, preserving and maintaining knowledge, cultures, languages
    and traditions.</p>
<p>Diverse First Nations, Métis and Inuit experiences and perspectives across the curriculum provides means for
    reconciliation.<sup>16</sup>
    Increasing inter- and cross-cultural understanding and initiating shifts in thinking and in attitudes will build
    trust to improve relationships among all Albertans. The inclusion of First Nations, Métis and Inuit historical and
    contemporary experiences and contributions, residential schools and their legacy, and treaties will help rectify
    social injustices and support better relationships. First Nations, Métis and Inuit songs, stories, histories,
    languages, arts, sciences, and contributions to the rich history of Alberta need to be part of every Alberta
    student’s education. First Nations, Métis and Inuit experiences and perspectives are included and reflected
    throughout Kindergarten to Grade 12 provincial curriculum; every student in the province, not only First Nations,
    Métis and Inuit students, will learn about the diverse Indigenous peoples of this land and how First Nations, Métis
    and Inuit contribute to the vibrancy and fabric of Alberta and Canadian society.</p>
    <p class="footnote"><sup>16</sup> The Truth and Reconciliation Commission defines ‘reconciliation’ as “an ongoing process of establishing and maintaining respectful relationships. A critical part of this process involves repairing damaged trust by making apologies, providing individual and collective reparations, and following through with concrete actions that demonstrate real societal change. Establishing respectful relationships also requires the revitalization of Indigenous law and legal traditions. It is important that all Canadians understand how traditional First Nations, Inuit, and Métis approaches to resolving conflict, repairing harm, and restoring relationships can inform the reconciliation process” (Sinclair, Littlechild, & Wilson, 2015, p. 121).</p>
<p>To address the <em>Truth and Reconciliation Commission of Canada: Calls to Action</em> and the <em>United Nations
        Declaration on the Rights of Indigenous Peoples</em>Peoples, Alberta is committed to rebalancing the education
    system by including Indigenous ways of knowing in curriculum to advance reconciliation for all Albertans.</p>
<h4>Francophone Cultures and Perspectives<sup>17</sup>
</h4>
<p class="footnote"><sup>17</sup> – Standard 4: Curriculum includes the diverse perspectives, in historical and contemporary contexts, of Francophones living in Alberta, Canada and the world. NOTE: All standards are provided in Appendix A.
    <br />
    – “If an individual has rights under section 23 of the Canadian Charter of Rights and Freedoms to have the individual’s children receive school instruction in French, the individual’s children are entitled to receive that instruction in accordance with those rights wherever in the Province those rights apply” (School Act, Section 10(1)).</p>
<p>As representatives of the linguistic duality of Canada, Francophone communities in Alberta continue to grow and
    actively contribute to Alberta’s economy, cultural mosaic and society as a whole. Today’s Francophone communities
    include generations of Franco-Albertans and newcomers from other Canadian provinces and territories, as well as
    people from many countries around the world. As such, Francophone communities are a vibrant and integral part of
    Alberta’s diverse society.</p>
<p>The inclusion of Francophone perspectives in Alberta’s provincial curriculum enables every student in the province,
    not only Francophone students, to gain a deeper understanding and appreciation of the linguistic, historical,
    cultural and contemporary contributions of Francophones and their communities in Alberta, Canada and the world.
    Where appropriate, Alberta’s provincial curriculum provides opportunities for all students to experience and gain
    valuable insights into the linguistic duality and cultural diversity that shape Canada’s identity.</p>
<p>Section 23 of the <em>Canadian Charter of Rights and Freedoms</em> (1982) gives Francophones and Anglophones, who
    live in a minority setting, the right to a primary and secondary education in their official language. In the
    context of Francophone education in Alberta, provincial curriculum for Francophone students incorporates the
    foundational pillars of language, identity, culture and community integration to develop and affirm Francophone
    students’ sense of identity and belonging.</p>
<h3>Alberta’s Kindergarten to Grade 12 Provincial Curriculum (Programs of Study)</h3>
<p>The vision for students, the student values, and the preceding considerations combine to shape Alberta’s K–12
    provincial curriculum. Curriculum also states, in subjects and by grade, what students are expected to understand,
    know, be able to do and be able to put into action while developing dispositions to act.</p>
<h4>Purpose</h4>
<p>Alberta’s provincial curriculum helps students create a positive future for themselves, their communities and
    society. It provides students with pathways to the world of work and post-secondary education related to their
    career interests. For example, provincial curriculum enables greater awareness of opportunities for Alberta’s
    economic diversification within the global economy and the need to address global environmental challenges such as
    climate change. Alberta’s curriculum identifies student learning outcomes to ensure all Alberta students have access
    to inclusive and equitable learning opportunities regardless of where they live or go to school. Courses or programs
    of study prescribed by the Minister of Education support and align with the <em>Ministerial Order on Student
        Learning: Goals and Standards Applicable to Education in Alberta</em> (#001/2013) <em>(MO)</em>.</p>
<p>While Alberta Education determines “what” students need to learn in provincial curriculum (programs of study),
    teachers use their professional judgement to determine “how” students achieve the learning outcomes in the
    provincial curriculum (programs of study).</p>
<p>Provincial curriculum is critical to developing students’ knowledge and understanding in subjects and their
    applications in daily life. Curriculum reflects an appropriate balance between subject conceptual and procedural
    knowledge<sup>18</sup>,
    competencies that deepen understanding, and a strong foundation of literacy and numeracy.</p>
    <p class="footnote"><sup>18</sup> Conceptual knowledge (what students should know) ranges from simple isolated facts to knowledge involving complex relations and connections. It includes the facts, symbols, rules, principles and concepts that constitute the subject. Procedural knowledge (what students can do; i.e., strategies, steps and approaches) ranges on a continuum from the simple to deeper understanding, from knowing how, to knowing how and why to carry out a process (Baroody, Feil, & Johnson, 2007, March).</p>
<p>The provincial curriculum is the starting point for teachers to apply their professional judgement to plan and design
    equitable student learning experiences that capitalize on students’ engagement and motivation by taking into account
    their interests, abilities, preferences and backgrounds as well as local contexts. Such learning experiences broaden
    perspectives and enable students to create opportunities, challenge the status quo, take initiative to achieve their
    dreams, and take action to create a better world. Through the study of subjects that reinforce literacy and numeracy
    and develop competencies, students use their abilities to communicate respectfully, synthesize ideas, collaborate
    with others, think critically and solve complex problems.</p>
<p>To ensure that provincial curriculum meets its intended purpose, development is guided by the consistent and holistic
    application of principles and standards.</p>
<h4>Features Common to Provincial Curriculum</h4>
<p>The content of Alberta’s K-12 provincial curriculum consists of three common features that ensure consistency. These
    three features are:</p>
<ul>
    <li><strong>Common architecture and design:</strong> In addition to conforming to the common principles and
        standards, curriculum also has a common architecture and design to help teachers plan learning experiences. The
        architecture is the systematic and logical arrangement of the common components of provincial curriculum. The
        design explains the relationships among the components identified in the architecture of provincial curriculum.
    </li>
    <li><strong>Common approach to the development of learning outcomes:</strong> The common approach to defining the
        scope and depth of learning outcomes and the associated competencies in curriculum ensures equity of learning
        opportunities for all students and consistent design and implementation of learning experiences.</li>
    <li><strong>Common language:</strong> The use of common language results in a high level of consistency across
        curriculum, while at the same time recognizing and preserving the nature of knowledge—its custom and
        practices—that is specific to a particular subject. This enables teachers to communicate both across subjects
        and grades with students, parents/guardians and colleagues.</li>
</ul>
<h4>Principles</h4>
<p>Principles<sup>19</sup> provide direction and
    guidance and ensure consistency in the common design and development of all provincial curriculum. These principles
    are interdependent and apply to all subjects. They:</p>
    <p class="footnote"><sup>19</sup> Adapted from Alberta Education (2015b, September).</p>
<ul>
    <li><em>support students’ development and application of literacy, numeracy and competencies within, and across, all
            subjects;</em></li>
    <li><em>support students’ acquisition and application of knowledge within and across all subjects so that students
            gain deep understandings;</em></li>
    <li><em>engage and support students’ learning needs and facilitate informed choice and flexible approaches to
            learning;</em></li>
    <li><em>respond to the emerging needs of a global, knowledge-based society and prepare students for life in a
            complex and interconnected world;</em></li>
    <li><em>broaden students’ perspectives by valuing, respecting and nurturing the diverse ways of experiencing and
            understanding the world; and</em></li>
    <li><em>encourage students to achieve their potential by recognizing that there are diverse ways of developing and
            demonstrating learning.</em></li>
</ul>
<h4>Standards</h4>
<p>Standards<sup>20</sup> for the Development of
    Kindergarten to Grade 12 Provincial Curriculum (Programs of Study), as well as Learning and Teaching Resources, and
    Assessments have been created to ensure that the principles are reflected in all provincial curriculum. Of the 12
    standards, the first eight standards have been developed related to the curriculum content. The application of these
    standards ensures that the curriculum meets the purpose for which it was designed and developed.</p>
    <p class="footnote"><sup>20</sup> Adapted from Alberta Education (2016, February).</p>
<p>Provincial Curriculum:</p>
<ol>
    <li>Curriculum is designed to enable learning opportunities that are equitable, inclusive and accessible to all
        Alberta students.</li>
    <li>Curriculum includes multiple, diverse perspectives that reflect our pluralistic society and the important roles
        and responsibilities of Alberta citizens within provincial, national and international contexts.</li>
    <li>Curriculum includes ways of knowing and diverse perspectives, in historical and contemporary contexts, of First
        Nations, Métis, and Inuit.</li>
    <li>Curriculum includes the diverse perspectives, in historical and contemporary contexts, of Francophones living in
        Alberta, Canada and the world.</li>
    <li>Curriculum is developmentally appropriate and written in clear and concise language for its intended audience
        and includes observable or measurable learning outcomes.</li>
    <li>Curriculum is written to facilitate holistic student development.</li>
    <li>Curriculum provides clear evidence of support for literacy and numeracy within and across subjects.</li>
    <li>Curriculum provides clear evidence of competencies within and across subjects.</li>
</ol>
<p>Assessments:</p>
<ol start="9">
    <li>Assessments, whether classroom-based or provincial, are purposeful, fair and respectful of diverse learning
        needs.</li>
    <li>Provincial assessments include the reporting of student learning and student achievement.</li>
</ol>
<p>Process:</p>
<ol start="11">
    <li>Quality curriculum is developed and reviewed regularly to ensure continual improvement using consistent
        processes as outlined in Alberta’s three interconnected phases: Shaping, Developing and Implementing.</li>
    <li>Curriculum is reviewed for accuracy, currency and relevancy within a 48-month cycle.</li>
</ol>
<p>The standards for provincial curriculum will also be applicable to learning and teaching resources. Elaborations on
    each standard are provided in Appendix A.</p>
<h4>Subjects</h4>
<p>Each subject has a body of knowledge that is specific to that domain of study. This body of knowledge includes the
    facts, symbols, rules, principles and concepts (conceptual knowledge) that constitute the subject. Each subject also
    has its strategies and steps and systemic approach to problem solving (procedural knowledge).</p>
<p>Provincial curriculum includes both subject conceptual and procedural knowledge. Conceptual and procedural knowledge
    (as identified in the learning outcomes in the programs of study) provide the subject content and context for the
    development of competencies, and literacy and numeracy. Through developing and applying literacy, numeracy and
    competencies, students acquire understanding that facilitates meaningful connections across and within subjects.
    Students then apply their knowledge to new situations, improve upon ideas, solve problems and make decisions.</p>
<p>Taken together, Alberta’s provincial curriculum contributes to achieving the goal of an inclusive K-12 education
    system and the outcomes of the <em>MO.</em></p>
<h4>Competencies</h4>
<p>Students are the artists, scientists, thinkers, innovators and leaders both now and in the future. They will be
    tasked with solving the problems of today, while imagining and creating a new tomorrow. Competencies are defined as
    interrelated sets of attitudes, skills and knowledge that are drawn upon and applied to a particular context for
    successful learning and living.<sup>21</sup>
</p>
<p class="footnote"><sup>21</sup> This definition of competencies was developed by Alberta Education with stakeholders during research roundtables with educators, parent groups, teachers, school administrators, researchers, employers and non-government organizations in 2011.</p>
<p>Competencies are critical for preparing students to successfully navigate their personal journeys and contribute to
    family, community and society. Competencies accentuate aspects of learning that apply across all subjects. Alberta’s
    provincial curriculum emphasizes the following eight competencies which are a streamlined expression of the
    competencies identified in the <em>MO.</em> These competencies are based on international research<sup>22</sup>
    and reflect priorities shared by education stakeholders across Alberta:</p>
<div class="word-list">
    <div class="col">
        <div>Critical Thinking</div>
        <div>Communication</div>
        <div>Problem Solving</div>
        <div>Collaboration</div>
    </div>
    <div class="col">
        <div>Managing Information</div>
        <div>Cultural and Global Citizenship</div>
        <div>Creativity and Innovation</div>
        <div>Personal Growth and Well-being</div>
    </div>
</div>
<p class="footnote"><sup>22</sup> See Amadio (2013); Ananiandou, & Claro (2009); Bristow, & Patrick (2014); Christensen, & Lane (2016); European Commission/EACEA/Eurydice (2012); Organisation for Economic Co-operation and Development (2003); Ontario Ministry of Education (2016).</p>
<p>Alberta’s Kindergarten to Grade 12 provincial curriculum is designed to engage students in exploring, developing and
    applying competencies in relevant subject content and contexts.</p>
<p>Learning outcomes strategically and clearly combine selected competencies with subject-specific conceptual and/or
    procedural knowledge. Students draw upon and apply competencies to achieve learning outcomes. Teachers may focus on
    additional competencies where they present opportunities to enhance student learning according to local needs,
    interests or contexts.</p>
<h4>Competency Descriptions and Indicators (Appendix B)</h4>
<p>Competency descriptions provide an overview of key features of each competency. They holistically describe the
    attitudes, skills and knowledge associated with each competency.</p>
<p>Competency indicators identify specific aspects of a competency that are transferable across subjects or contexts.
    Competency indicators:</p>
<ul>
    <li>focus the scope of each competency;</li>
    <li>help teachers determine which aspects of a competency are evident within learning outcomes, learning experiences
        or assessments; and</li>
    <li>describe cognitive, affective or psychomotor behaviours to help identify situations where a competency may be
        applied or developed.</li>
</ul>
<p>Teachers draw upon the language or content of the descriptions and indicators to:</p>
<ul>
    <li>share understandings with students;</li>
    <li>develop learning experiences;</li>
    <li>create assessments; and</li>
    <li>communicate with parents and guardians, employers and community-based social and cultural enterprises regarding
        competencies.</li>
</ul>
<p>Teachers work with students to develop competencies over time. Teachers use their professional judgement to design
    learning experiences and select assessment strategies that are appropriate for grade, subject and context. A
    curriculum that promotes the development of competencies across all subjects and grades supports students in
    becoming lifelong learners inspired to pursue their aspirations.</p>
<h4>Literacy and Numeracy</h4>
<p>Literacy and numeracy are critical in helping students make meaning of the world around them. They open doors and
    opportunities for individuals to acquire the knowledge they need to achieve personal goals and build a better
    quality of life. Literacy and numeracy lay the foundation for lifelong learning and for active participation in
    society. Literacy and numeracy begin at birth and develop throughout one’s lifetime.</p>
<p>In Alberta, literacy is defined as the ability, confidence and willingness to engage with language to acquire,
    construct and communicate meaning in all aspects of daily living.<sup>23</sup>
    Students transfer the knowledge, skills and strategies learned primarily in Language Arts to all other subjects in
    purposeful ways and to everyday situations at school, at home and in the community. Students also acquire and apply
    literacy that is unique to each subject.</p>
    <p class="footnote"><sup>23</sup> Alberta Education, 2015.</p>
<p>In Alberta, numeracy is defined as the ability, confidence and willingness to engage with quantitative or spatial
    information to make informed decisions in all aspects of daily living.<sup>24</sup> Students transfer the knowledge, skills and strategies learned
    primarily in Mathematics to all other subjects in purposeful ways and to everyday situations at school, at home and
    in the community. Students’ numeracy skills grow as the quantitative and spatial understandings specific to each
    subject are learned and applied.</p>
    <p class="footnote"><sup>24</sup> Alberta Education, 2015.</p>
<p>Literacy and numeracy progressions describe key literacy and numeracy milestones along a continuum of developmentally
    appropriate expectations and behaviours across all subjects from Kindergarten through Grade 12. The progressions are
    organized around components,<sup>25</sup> elements,<sup>26</sup> and
    descriptors.<sup>27</sup>
    The progressions provide a common language and construct for all teachers to help students reinforce, acquire or
    apply literacy and numeracy when engaging with subject learning outcomes.</p>
    <p class="footnote"><sup>25</sup> There are two components: Awareness and Knowledge, and Understanding.</p>
    <p class="footnote"><sup>26</sup> Elements describe the essential building blocks of literacy and numeracy.</p>
    <p class="footnote"><sup>27</sup> Descriptors explain what students can typically demonstrate by the end of their particular age/division grouping.</p>
<p>Literacy and numeracy are built and refined through learning the subject; in turn, conceptual and procedural
    knowledge are developed through literacy and numeracy. In addition, the development of literacy and numeracy skills
    add depth and richness to students acquiring competencies within subject learning outcomes.</p>
<p>Provincial curriculum will show the connections between learning outcomes and relevant literacy and numeracy
    progressions that are developed and applied in a given subject.</p>
<h3>The Architecture and Design of Kindergarten to Grade 12 Provincial Curriculum (Programs of Study)</h3>
<p><em>The Guiding Framework</em> establishes the common direction, principles and standards that guide the development
    of provincial curriculum, and identifies the common features for all curriculum. The common direction, principles
    and standards result in a common architecture<sup>28</sup>
    and design<sup>29</sup>
    that provide consistency across all subjects.</p>
    <p class="footnote"><sup>28</sup> Architecture is the systematic and logical arrangement of the common components of K–12 provincial curriculum.</p>
    <p class="footnote"><sup>29</sup> Design explains the relationships among the components identified in the architecture of provincial curriculum. It is the consistent application of principles and standards to develop scopes and sequences, essential understandings and guiding questions, and learning outcomes.</p>
<p>The common architecture and design, along with the common language and the common approach to the development of
    learning outcomes facilitates teacher planning in and across all subjects and grades, interdisciplinary teaching,
    cross-curricular learning experiences for students and comprehensive professional development. Ultimately, the
    common architecture and design facilitates a more effective, equitable and simultaneous implementation of provincial
    curriculum in English and French throughout the province.</p>
<p>The common architecture and design ensures that each subject consistently focuses on competencies, literacy and
    numeracy, supports inclusion, pluralism and diversity, includes Francophone cultures and perspectives, includes
    First Nations, Métis and Inuit cultures and perspectives, and advances First Nations, Métis and Inuit education for
    reconciliation.</p>
<h4>Provincial Curriculum: Architecture and Design</h4>
<p>The architecture and design is the systematic and logical arrangement of the common components of all future
    Kindergarten to Grade 12 provincial programs of study in all subjects. The following visual describes the
    arrangement of the common components.</p>
<div class="levels">
    <div class="level-1">Introduction to Alberta’s Kindergarten to Grade 12 Provincial Curriculum</div>
    <div class="level-2">Kindergarten to Grade 12 Subject Introduction</div>
    <div class="level-3">
        <div>Kindergarten to Grade 12 Subject Scope and Sequence</div>
        <div class="white">Essential Understandings and Guiding Questions</div>
    </div>
    <div class="level-4">Subject Learning Outcomes</div>
</div>
<p>The following sections address each common component of K-12 provincial curriculum.</p>
<div class="levels">
    <div class="level-1">Introduction to Alberta’s Kindergarten to Grade 12 Provincial Curriculum</div>
</div>
<p>The Introduction to the Alberta’s Kindergarten to Grade 12 Provincial Curriculum (Common Introduction) (pages 3–11)
    defines the vision for students, the student values, and describes the nature of learning and the learner. The
    Common Introduction also reflects government policy and commitments related to inclusion, pluralism and diversity,
    First Nations, Métis and Inuit education for reconciliation, and Francophone cultures and perspectives.</p>
<p>The Common Introduction sets the direction (philosophical, legislative and policy underpinnings) for all curriculum.
    It identifies the features that ensure consistency in the design and development of curriculum in all subjects
    through the application of principles and standards and a common approach to developing learning outcomes.</p>
<div class="levels">
    <div class="level-2">Kindergarten to Grade 12 Subject Introduction</div>
</div>
<p>The subject introduction describes the spirit and nature of the subject and how it is organized, practised and
    applied in real life. The introduction outlines the major purpose of the subject and describes the subject as it
    relates to the education of students in Kindergarten to Grade 12. A rationale provides the justification for why the
    subject is learned and how the subject addresses student and societal needs now and in the future.</p>
<p>The subject introduction also describes how the:</p>
<ul>
    <li>subject fosters understanding of the roles and contributions of linguistic, cultural and ethnic groups in Canada
        and how the subject supports students in achieving a balanced understanding of social, cultural, political,
        economic and environmental perspectives that shape Alberta and its relationship with the rest of Canada and the
        world;</li>
    <li>needs of diverse learners are met within the subject through accessing, expressing and engaging with subject
        content while taking into consideration the nature of learning and the learner;</li>
    <li>subject addresses the role, significance and impact of the contributions made by First Nations, Métis and Inuit
        cultures and perspectives that are significant to the subject, and how the subject engages traditional
        knowledge, experiences, languages and ways of knowing of Indigenous peoples<sup>30</sup>;
    </li>
    <li>subject addresses the role, significance and impact of the contributions made to the subject by Francophones,
        including significant individuals, stories, ideas and histories in local and global contexts;</li>
    <li>competencies are developed within the subject, by describing the unique role and nature of the subject in
        developing all or certain competencies, in addition to how the competencies contribute to deep understanding of
        the subject and facilitate student engagement; and</li>
    <li>subject provides opportunities to further develop, strengthen and enhance literacy and numeracy.</li>
</ul>
<p class="footnote"><sup>30</sup><span>The term Indigenous refers to people practising unique traditions, who retain social, cultural, economic and political characteristics that are distinct from those of the dominant societies in which they live. (United Nations Permanent Forum on Indigenous Issues <a href="http://www.un.org/esa/socdev/unpfii/documents/5session_factsheet1.pdf" target="_blank">http://www.un.org/esa/socdev/unpfii/documents/5session_factsheet1.pdf</a></span>)</p>
<p>In summary, the subject introduction ensures that each subject contributes to the vision for students, and the
    student values, and meets students’ needs, cultural identity and perspectives in a consistent way.</p>
<div class="levels">
    <div class="level-3">
        <div>Kindergarten to Grade 12 Subject Scope and Sequence</div>
        <div class="white">Essential Understandings and Guiding Questions</div>
    </div>
</div>
<p>The K-12 subject scope and sequence outlines the continuum of learning from one grade to the next. Scope refers to
    the breadth and depth while sequence refers to how the learning is ordered, presented and scaffolded. Scopes and
    sequences consist of two parts: the essential understandings and the guiding questions.</p>
<p>The subject scope and sequence reflects an understanding of the nature of learning and the learner, supports
    inclusion, pluralism and diversity, First Nations, Métis and Inuit education for reconciliation, and Francophone
    cultures and perspectives.</p>
<p>The K-12 scope and sequence for each subject demonstrates the building of deeper knowledge and understandings over
    the grades, and ensures horizontal and vertical alignment of conceptual and procedural knowledge within the subject.
    Scopes and sequences provide the opportunity for planned alignment and connections across subjects, enabling
    interdisciplinary learning opportunities as appropriate.</p>
<h4 style="color: #333;">Essential Understandings and Guiding Questions<sup>31</sup>
</h4>
<p class="footnote"><sup>31</sup> Alberta’s Essential Understandings and Enduring Understandings (Wiggins, & McTighe, 1998) differ primarily in their purposes.
    Essential Understandings are broad statements that capture the subject’s relevant and important conceptual and procedural knowledge to be included in the curriculum. They may span one or more grades. They also ensure the K–12 curriculum is coherent: vertically in the subject from one grade to the next and horizontally across subjects in a grade.
    Enduring Understandings, comprised of two concepts connected by a verb, are derived from the Common Core State Standards (National Governors Association Center for Best Practices, Council of Chief State School Officers, 2010) and are used primarily by teachers to design instruction.</p>
<p><strong>Essential Understandings</strong> are broad statements that frame subject understandings of value to self,
    society and the subject. They may span over K-12, or be unique to a grade or series of grades. Some may be shared
    across subjects. The essential understandings provide the context for and the relevance to the guiding questions and
    the learning outcomes. For example, Canadian laws affirm people’s individual and collective rights and foster social
    cohesion, by recognizing the diversity of people living in Canada.</p>
<p><strong>Guiding Questions</strong> are derived from the essential understandings and frame the learning outcome(s).
    They are engaging and challenging questions for students and are written at a grade-appropriate level. When an
    essential understanding spans multiple grades, the guiding question introduces “gradedness.” It also guides the
    selection of the conceptual knowledge and/or procedural knowledge that will be developed in the learning
    outcome(s).<sup>32</sup>
    For example, “How have various groups of people in Canada experienced discrimination by government and how have
    these groups and government responded?”</p>
    <p class="footnote"><sup>32</sup> The term learning outcome describes what students are expected to know, and understand, be able to do and put into action after the completion of a planned process of learning experiences.</p>
<div class="levels">
    <div class="level-4">Subject Learning Outcomes</div>
</div>
<p>Subject learning outcomes are derived from the guiding questions, and define by subject and grade the conceptual
    and/or procedural knowledge that students need to acquire. Conceptual knowledge is comprised of concrete and
    abstract concepts and procedural knowledge is comprised of techniques, strategies, processes and products. These are
    identified and defined for each learning outcome(s).</p>
<p>Subject knowledge is integrated with one or more selected competencies to form the learning outcome, because
    competencies cannot be acquired in isolation and are only meaningful when integrated with subject knowledge.
    Learning outcomes, therefore, enable the concurrent development and application of subject conceptual and/or
    procedural knowledge and competencies.</p>
<p>The integration of knowledge and competencies ensures students—in addition to acquiring knowledge—are also able to
    apply that knowledge and to make connections within and outside of the school or the subject. For a responsive and
    relevant provincial curriculum, this means going beyond “learning about” to “learning to do.” Competencies are,
    therefore, selected with care by Alberta Education to achieve a balance among the competencies within a subject and
    a grade, and for ease of use by teachers.</p>
<p>The provincial curriculum shows the connections between the learning outcomes and the relevant literacy and numeracy
    progressions. The progressions are selected and identified by Alberta Education to indicate the literacy and
    numeracy that students will use, develop and enhance through the learning outcomes, and for ease of use by teachers.
</p>
<img src="assets/images/gf_outcomes.png" />
<p>Learning outcomes bring together and therefore support the concurrent development of subject conceptual and
    procedural knowledge, competencies and literacy and numeracy.</p>
<p>Programs of study will therefore reinforce literacy, numeracy and competencies. Both competency indicators and
    literacy and numeracy progressions are provided to help teachers support student learning.</p>
<p>Provincial curriculum in all subjects will have a common architecture and design to ensure greater consistency and
    coherence across the K-12 curriculum while supporting and reinforcing competencies and literacy and numeracy.
    Significantly, the common architecture and design makes it easier and less time-consuming for teachers to apply
    their professional judgement to plan and teach in and across subjects, while enhancing the overall learning
    experiences for students. Teachers will have greater flexibility in meeting the learning needs of every student
    within inclusive learning environments where diversity is embraced and where every student is made to feel welcome,
    cared for, respected and safe.</p>
<h3>Appendix A: Standards for the Development of Kindergarten to Grade 12 Provincial Curriculum<sup>33</sup>,
    as well as Learning and Teaching Resources, and Assessments</h3>
    <p class="footnote"><sup>33</sup> Curriculum consists of the Kindergarten to Grade 12 (K–12) courses and programs of study developed by Alberta Education and prescribed by the Minister of Education.</p>
<p>The Standards for Curriculum Development, as well as standards for Learning and Teaching Resources, and Assessments
    build upon the principles, and set out requirements and specifications that are used consistently by developers,
    partners and stakeholders to ensure that provincial curriculum meets the purpose for which it is designed. The use
    of Standards by curriculum developers is required so that goals and targets for Alberta’s K-12 provincial
    curriculum—as identified in the <em>Ministerial Order on Student Learning: Goals and Standards Applicable to
        Education in Alberta</em> (#001/2013) —are met.</p>
<p>The following Standards are applied holistically across subjects. They include Standards both for curriculum as a
    product and for curriculum development as a process. The numbering of the Standards and the ordering of the bullets
    do not imply a hierarchy of importance. The Standards are all of equal importance. The Standards for provincial
    curriculum will also be applied to learning and teaching resources.</p>
<h4>Standards</h4>
<h5>Inclusion, Accessibility and Equity</h5>
<div class="standard">
    <div class="left"><strong>Standard 1:</strong></div>
    <div class="right"><strong>Curriculum is designed to enable learning opportunities that are equitable, inclusive and
            accessible to all Alberta students.<sup>34</sup></strong>
    </div>
</div>
<div class="standard list">
    <div class="left"></div>
    <div class="right">
        <ul>
            <li>Curriculum is designed to support diverse learning needs of all students. </li>
            <li>Curriculum is accessible in digital and print formats to enable learning with flexible timing and pacing
                in a variety of learning environments. </li>
            <li>Curriculum is written to enable the creation of learning opportunities that meet the specific needs of
                students, including those who require targeted or individualized instructional supports. </li>
            <li>Francophone students in Alberta have access to rich linguistic and cultural learning opportunities that
                support their unique identity through curriculum that is adapted, not directly translated, where
                appropriate. </li>
            <li>Curriculum is written to create opportunities for First Nations, Métis and Inuit ways of knowing.</li>
        </ul>
    </div>
</div>
<p class="footnote"><sup>34</sup> For the purposes of these standards, the term students refers to all learners enrolled in Alberta’s ECS to Grade 12 education system.</p>
<h5>Multiple Perspectives and Diversity</h5>
<div class="standard">
    <div class="left"><strong>Standard 2:</strong></div>
    <div class="right"><strong>Curriculum includes multiple, diverse perspectives that reflect our pluralistic society
            and the important roles and responsibilities of Alberta citizens within provincial, national and
            international contexts.</strong></div>
</div>
<div class="standard list">
    <div class="left"></div>
    <div class="right">
        <ul>
            <li>Curriculum supports students in achieving a balanced understanding of social, cultural, political,
                economic and environmental perspectives that shape Alberta and its relationship with the rest of Canada
                and the world.</li>
            <li>Curriculum includes or reflects the diversity of Alberta’s students and society, by taking into
                consideration historical and contemporary contexts, gender, ethnicity, culture, beliefs and other
                distinguishing factors of identity.</li>
            <li>Curriculum supports students in becoming active, ethical and responsible citizens who make well-informed
                decisions and meaningful contributions to their families and to local and global communities.</li>
        </ul>
    </div>
</div>
<div class="standard">
    <div class="left"><strong>Standard 3:</strong></div>
    <div class="right"><strong>Curriculum includes ways of knowing and diverse perspectives, in historical and
            contemporary contexts, of First Nations, Métis, and Inuit.</strong></div>
</div>
<div class="standard list">
    <div class="left"></div>
    <div class="right">
        <ul>
            <li>First Nations, Métis and Inuit traditional knowledge, experiences and languages and cultures are evident
                within meaningful, relevant and authentic learning outcomes.</li>
            <li>Curriculum includes First Nations, Métis and Inuit histories, including residential schools and their
                legacy, and treaties and the diverse contributions of First Nations, Métis and Inuit to Alberta and
                Canada.</li>
        </ul>
    </div>
</div>
<div class="standard">
    <div class="left"><strong>Standard 4:</strong></div>
    <div class="right"><strong>Curriculum includes the diverse perspectives, in historical and contemporary contexts, of
            Francophones living in Alberta, Canada and the world.</strong></div>
</div>
<div class="standard list">
    <div class="left"></div>
    <div class="right">
        <ul>
            <li>Curriculum includes and respects the unique perspectives and experiences of Francophones.</li>
        </ul>
    </div>
</div>
<h5>Comprehensive, Developmentally Appropriate Scope and Sequence and Learning Outcomes</h5>
<div class="standard">
    <div class="left"><strong>Standard 5:</strong></div>
    <div class="right"><strong>Curriculum is developmentally appropriate and written in clear and concise language for
            its intended audience and includes observable or measurable learning outcomes.</strong></div>
</div>
<div class="standard list">
    <div class="left"></div>
    <div class="right">
        <ul>
            <li>Programs of study present a coherent K–12 scope and sequence that effectively achieves the balance
                between required subject content—including conceptual and procedural knowledge that enables students to
                progress to more challenging courses and programs from one grade to the next—and student-centred
                opportunities for deep learning.</li>
            <li>Learning outcomes in programs of study state actions that are developmentally appropriate to be
                accomplished by students, and are written in the active voice.</li>
            <li>Learning outcomes are written to be observed or measured, either qualitatively or quantitatively, to
                enable reporting of student progress, development or achievement.</li>
            <li>Learning outcomes at each grade are sufficient in number to encompass the depth and breadth of subject
                content and, as appropriate, include subject-specific terminology that will be familiar to the intended
                audience of teachers.</li>
            <li>Learning and teaching resources and provincial assessments align with learning outcomes in the programs
                of study and use clear language that is appropriate for students and can be understood by the broader
                community.</li>
        </ul>
    </div>
</div>
<div class="standard">
    <div class="left"><strong>Standard 6:</strong></div>
    <div class="right"><strong>Curriculum is written to facilitate holistic student development.</strong></div>
</div>
<div class="standard list">
    <div class="left"></div>
    <div class="right">
        <ul>
            <li>Curriculum makes clear the relationship among concepts within and across subjects through content
                selection that provides opportunities for students to synthesize related ideas, apply acquired knowledge
                and make connections among past, present and future learning.</li>
            <li>Curriculum provides students with opportunities to develop over time and across interconnected learning
                domains: intellectual, physical, social, spiritual and emotional.</li>
        </ul>
    </div>
</div>
<div class="standard">
    <div class="left"><strong>Standard 7:</strong></div>
    <div class="right"><strong>Curriculum provides clear evidence of support for literacy and numeracy within and across
            subjects.</strong></div>
</div>
<div class="standard list">
    <div class="left"></div>
    <div class="right">
        <ul>
            <li>Learning outcomes are designed to support the development of literacy and numeracy skills within and
                across subjects.</li>
            <li>Assessments and learning and teaching resources align with the literacy and numeracy progressions.</li>
        </ul>
    </div>
</div>
<div class="standard">
    <div class="left"><strong>Standard 8:</strong></div>
    <div class="right"><strong>Curriculum provides clear evidence of competencies within and across subjects.</strong>
    </div>
</div>
<div class="standard list">
    <div class="left"></div>
    <div class="right">
        <ul>
            <li>Curriculum is designed to provide opportunities for students to explore, develop and apply competencies
                within and across subjects to a degree that they are observable and/or measurable.</li>
            <li>Competency indicators identify attributes of a competency that students explore, develop and demonstrate
                while working toward achieving learning outcomes in each subject. Demonstration of the competency
                indicators will vary by grade, subject or context.</li>
        </ul>
    </div>
</div>
<h5>Assessments</h5>
<div class="standard">
    <div class="left"><strong>Standard 9:</strong></div>
    <div class="right"><strong>Assessments, whether classroom-based or provincial, are purposeful, fair and respectful
            of diverse learning needs. (Provincial Assessments are meant to complement, not replace, day-to-day teacher
            observations and classroom assessment.)</strong></div>
</div>
<div class="standard list">
    <div class="left"></div>
    <div class="right">
        <ul>
            <li>Student assessments are accessible and measure achievement of the learning outcomes from provincially
                approved programs of study.</li>
            <li>Learning and teaching resources include assessments that are developed to collect varied evidence of
                student achievement over time, to measure the degree to which students are able to apply what they have
                learned, and to guide, inform and support further learning.</li>
            <li>Classroom assessment supports, including learning and teaching resources, are equitable for all
                students.</li>
            <li>Classroom assessment supports, including learning and teaching resources, are written to be transparent
                to all users (e.g., students, teachers, parents, educators) regarding what is being assessed, the method
                of assessment, performance standards that will be utilized to describe the learning, and how the results
                obtained will be used.</li>
        </ul>
    </div>
</div>
<div class="standard">
    <div class="left"><strong>Standard 10:</strong></div>
    <div class="right"><strong>Provincial assessments include the reporting of student learning and student
            achievement.</strong></div>
</div>
<div class="standard list">
    <div class="left"></div>
    <div class="right">
        <ul>
            <li>Assessment information helps inform and enhance instruction for students</li>
            <li>Assessment information assures Albertans that the education system meets the needs of students, achieves
                the outcomes of the <em>Ministerial Order on Student Learning</em> (#001/2013) and ensures that
                province-wide standards are maintained.</li>
            <li>Diploma Examinations certify the level of individual student achievement in selected 30-level courses.
            </li>
        </ul>
    </div>
</div>
<h4>Standards for Provincial Curriculum Development Processes</h4>
<h5>Consistent Processes for Development and Review</h5>
<div class="standard">
    <div class="left"><strong>Standard 11:</strong></div>
    <div class="right"><strong>Quality curriculum is developed and reviewed regularly to ensure continual improvement
            using consistent processes as outlined in Alberta’s three interconnected phases: Shaping, Developing and
            Implementing.</strong></div>
</div>
<div class="standard list">
    <div class="left"></div>
    <div class="right">
        <ul>
            <li>Curriculum is developed through the use and application of a common architecture and design for all
                Kindergarten to Grade 12 subjects.</li>
            <li>Curriculum development processes are designed to ensure that programs of study, assessments, and
                learning and teaching resources are aligned and mutually supportive.</li>
            <li>Curriculum developers determine how literacy, numeracy and competencies are related to the learning
                outcomes for each subject.</li>
            <li>The curriculum is developed and available on a digital platform and is available for printing to
                facilitate ease of use by teachers and curriculum developers in a variety of learning environments.</li>
            <li>The process of curriculum development ensures the engagement of stakeholders including subject experts
                such as teachers, post-secondary professors and other educators, parents, students, Albertans, groups
                and industry, and notably Francophone and First Nations, Métis and Inuit communities.</li>
            <li>Curriculum is developed and implemented simultaneously in French and English.</li>
            <li>Curriculum is implemented as a collaborative ongoing process, continuously building capacity with
                stakeholders.</li>
        </ul>
    </div>
</div>
<div class="standard">
    <div class="left"><strong>Standard 12:</strong></div>
    <div class="right"><strong>Curriculum is reviewed for accuracy, currency and relevancy within a 48-month
            cycle.</strong></div>
</div>
<div class="standard list">
    <div class="left"></div>
    <div class="right">
        <ul>
            <li>A review of the components of curriculum will occur at least once within 48 months of implementation to
                allow for improvements and updates based on research and feedback from the field and subject experts.
            </li>
            <li>An emergent review is initiated whenever there are significant, time-sensitive developments that affect
                the content of a subject.</li>
        </ul>
    </div>
</div>
<h3>Appendix B: Competencies</h3>
<p>Competencies are combinations of knowledge, skills and attitudes that students develop and apply for successful
    learning, living and working. They emphasize aspects of learning that apply within and across all subjects.
    Alberta’s Kindergarten to Grade 12 provincial curriculum promotes development of the following competencies, which
    are a streamlined expression of the competencies in the <em>Ministerial Order on Student Learning: Goals and
        Standards Applicable to Education in Alberta</em> (#001/2013):</p>
<div class="word-list-no-disc">
    <div class="col">
        <div><strong>Critical Thinking</strong></div>
        <div><strong>Communication</strong></div>
        <div><strong>Problem Solving</strong></div>
        <div><strong>Collaboration</strong></div>
    </div>
    <div class="col">
        <div><strong>Managing Information</strong></div>
        <div><strong>Cultural and Global Citizenship</strong></div>
        <div><strong>Creativity and Innovation</strong></div>
        <div><strong>Personal Growth and Well-being</strong></div>
    </div>
</div>
<h4>Competency Descriptions and Indicators<sup>35</sup></h4>
<p class="footnote"><sup>35</sup> Alberta Education, 2016, May.</p>
<p>Competency descriptions provide an overview of key features of each competency. They holistically describe attitudes,
    skills and knowledge associated with each competency.</p>
<p>Competency indicators identify specific aspects of a competency that are transferable across subjects or contexts.
    Competency indicators:</p>
<ul>
    <li>focus the scope of each competency;</li>
    <li>help teachers determine which aspects of a competency are evident within learning outcomes, learning experiences
        or assessments; and</li>
    <li>describe cognitive, affective or psychomotor behaviours to help identify situations where a competency may be
        applied or developed.</li>
</ul>
<p>Teachers may use one or more indicators to identify situations that develop a particular competency. The ordering of
    the bullets do not imply a hierarchy of importance.</p>
<p><strong>Critical Thinking</strong> involves using reasoning and criteria to conceptualize, evaluate or synthesize
    ideas. Students reflect on their thinking to improve it. They challenge assumptions behind thoughts, beliefs or
    actions. Students value honesty, fairness and open-mindedness.</p>
<p>Indicators:</p>
<ul>
    <li>questioning and analyzing evidence, assertions or assumptions;</li>
    <li>applying reasoned approaches or relevant criteria to conceptualize, analyze or make judgements;</li>
    <li>reflecting upon and evaluating reasoning behind thoughts, beliefs or actions;</li>
    <li>synthesizing thoughts and information to discover or extend understandings; and</li>
    <li>demonstrating intellectual integrity, fairness and open-mindedness.</li>
</ul>
<p><strong>Problem Solving</strong> involves selecting strategies and resources to move from what is known to what is
    sought. Students analyze situations, create plans of action and implement solutions. They evaluate alternatives and
    their consequences. Students approach challenges with creativity, flexibility, and determination.</p>
<p>Indicators:</p>
<ul>
    <li>identifying what is known and what is required to clarify a problem;</li>
    <li>exploring problem-solving strategies using relevant information, resources or criteria;</li>
    <li>assessing options to generate courses of action;</li>
    <li>evaluating the impact of possible solutions to carry out the most viable option; and</li>
    <li>approaching challenges with creativity, flexibility and determination.</li>
</ul>
<p><strong>Managing Information</strong> involves organizing and using information for specific purposes. Students
    access, interpret, evaluate and share information from a variety of digital and non-digital sources. They are
    ethical and effective in how they use and share information. Students value reliability, validity and integrity of
    information.</p>
<p>Indicators:</p>
<ul>
    <li>assessing information from a variety of digital or non-digital sources;</li>
    <li>synthesizing or organizing multiple pieces of information to enhance or clarify understanding;</li>
    <li>evaluating authenticity, reliability or validity to appropriately interpret or use information; and</li>
    <li>using, sharing or storing information effectively and ethically.</li>
</ul>
<p><strong>Creativity and Innovation</strong> involves generating and applying ideas to create something of value.
    Students recognize opportunities to apply ideas in new ways. They are open to and play with ideas, take risks and
    adapt to changing conditions. Students demonstrate optimism, initiative and ingenuity.</p>
<p>Indicators:</p>
<ul>
    <li>recognizing opportunities and imagining possibilities to apply ideas in new ways;</li>
    <li>exploring or playing with ideas, materials or processes to create something new;</li>
    <li>evaluating and adapting ideas, materials or processes in response to feedback or emerging conditions; and</li>
    <li>demonstrating initiative, resourcefulness and perseverance when transforming ideas into actions, products or
        services.</li>
</ul>
<p><strong>Communication</strong> involves sharing ideas through oral, written or non-verbal media. Students engage in
    formal and informal exchanges with others. They consider how culture, context and experience impact messaging.
    Students demonstrate respect, empathy and responsibility when communicating with others.</p>
<p>Indicators:</p>
<ul>
    <li>clarifying the purpose or intention of a message in relation to audience, context or culture;</li>
    <li>considering perspectives, emotions and experiences when seeking shared understandings;</li>
    <li>decoding and interpreting ideas or information shared through verbal or non-verbal formats;</li>
    <li>expressing ideas or concepts using appropriate language, conventions or protocols; and</li>
    <li>demonstrating respect and responsibility when communicating with others.</li>
</ul>
<p><strong>Collaboration</strong> involves working with others to achieve a common goal. Students participate, exchange
    ideas and share responsibilities. They respect competing views and nurture positive relationships. Students are
    adaptable, willing to compromise and value the contributions of others.</p>
<p>Indicators:</p>
<ul>
    <li>sharing responsibilities and supporting others to achieve a common goal;</li>
    <li>demonstrating sensitivity to diverse cultures, audience or contexts when working with others;</li>
    <li>exhibiting reciprocity and trust when sharing ideas or roles; and</li>
    <li>valuing flexibility, compromise and the contributions of others to nurture positive working relationships.</li>
</ul>
<p><strong>Cultural and Global Citizenship</strong> involves actively engaging with cultural, environmental, political
    or economic systems. Students acknowledge First Nations, Métis, Inuit, Francophone or other perspectives when taking
    action on local or global issues. They advocate for the dignity and well-being of individuals and communities.
    Students value equity and diversity, and believe in their capacity to make a difference.</p>
<p>Indicators:</p>
<ul>
    <li>considering diverse perspectives when examining interactions between cultural, environmental, political or
        economic systems and communities;</li>
    <li>analyzing various ways in which decisions are made within cultural, environmental, political or economic
        systems;</li>
    <li>demonstrating responsible citizenship through actions that contribute to healthy and sustainable communities;
    </li>
    <li>evaluating the impact of decisions or actions on the dignity and well-being of individuals or communities; and
    </li>
    <li>valuing equity and diversity and believing in the capacity to make a difference.</li>
</ul>
<p><strong>Personal Growth and Well-being</strong> involves managing emotional, intellectual, physical, social and
    spiritual aspects of living. Students set learning, career or wellness goals and work toward them. They draw upon
    their strengths to develop interests, skills and talents. Students are reflective, resourceful and optimistic and
    they strive for personal excellence.</p>
<p>Indicators:</p>
<ul>
    <li>identifying interests, values or skills to set learning, life or career goals;</li>
    <li>exploring, selecting or adapting strategies and resources that support personal growth in life, school or career
        pathways;</li>
    <li>making choices or taking action to promote the safety and well-being of self or others;</li>
    <li>building healthy relationships to support growth and well-being of self and others; and</li>
    <li>demonstrating optimism, flexibility or resilience when adapting to new situations and transitions.</li>
</ul>
<h3>References</h3>
<ul>
    <li>This list contains only those resources that are cited in the document.</li>
</ul>
<div class="reference">Alberta Education. (1997). Teaching Quality Standard Applicable to the Provision of Basic Education in Alberta. Ministerial Order (#016/97). Retrieved from <a href="https://education.alberta.ca/media/1626523/english-tqs-card-2013_3.pdf" target="_blank">https://education.alberta.ca/media/1626523/english-tqs-card-2013_3.pdf</a></div>
<div class="reference">Alberta Education. (2007). Primary programs framework for teaching and learning (kindergarten to grade 3): Guiding principles. Edmonton, AB: Alberta Education. Retrieved from <a href="https://education.alberta.ca/media/656591/guiding.pdf" target="_blank">https://education.alberta.ca/media/656591/guiding.pdf</a></div>
<div class="reference">Alberta Education. (2013). Ministerial order on student learning (#001/2013). Retrieved from <a href="http://education.alberta.ca/department/policy/standards/goals.aspx" target="_blank">http://education.alberta.ca/department/policy/standards/goals.aspx</a></div>
<div class="reference">Alberta Education. (2015). Revised draft literacy and numeracy progressions (unpublished).</div>
<div class="reference">Alberta Education. (2015a, September). Guide to education: ECS to Grade 12, 2015–2016. Edmonton, AB: Alberta Education. Retrieved from <a href="https://education.alberta.ca/media/1626501/guide_to_ed_school_act_2015-june-4-with-active-links.pdf" target="_blank">https://education.alberta.ca/media/1626501/guide_to_ed_school_act_2015-june-4-with-active-links.pdf</a></div>
<div class="reference">Alberta Education. (2015b, September). Revised draft guiding principles for curriculum development (unpublished).</div>
<div class="reference">Alberta Education. (2016, February). Revised draft standards for curriculum development (unpublished).</div>
<div class="reference">Alberta Education. (2016, May). Streamlined expression of competencies—descriptions, indicators and examples. Retrieved from <a href="https://education.alberta.ca/media/3115412/competency-descriptions-indicators-and-examples-approved.pdf" target="_blank">https://education.alberta.ca/media/3115412/competency-descriptions-indicators-and-examples-approved.pdf</a></div>
<div class="reference">Amadio, M. (2013). A rapid assessment of curricula for general education focusing on cross-curricular themes and generic competences or skills. Background paper prepared for the Education for All Global Monitoring Report 2013/2014, Teaching and learning: Achieving quality for all. Geneva, UNESCO IBE. Retrieved from <a href="http://unesdoc.unesco.org/images/0022/002259/225939e.pdf" target="_blank">http://unesdoc.unesco.org/images/0022/002259/225939e.pdf</a></div>
<div class="reference">Ananiadou, K., & Claro, M. (2009). 21st century skills and competences for new millennium learners in OECD countries. OECD Education Working Papers, No. 41. Paris: OECD Publishing. Retrieved from <a href="http://dx.doi.org/10.1787/218525261154" target="_blank">http://dx.doi.org/10.1787/218525261154</a></div>
<div class="reference">Assembly of First Nations. (n.d.). A declaration of First Nations. Retrieved from <a href="http://www.afn.ca/en/about-afn/a-declaration-of-first-nations" target="_blank">http://www.afn.ca/en/about-afn/a-declaration-of-first-nations</a></div>
<div class="reference">Baroody, A. J., Feil, Y., & Johnson, A. R. (2007, March). An alternative reconceptualization of procedural and conceptual knowledge. Journal for Research in Mathematics Education, 38(2), 115–131.</div>
<div class="reference">Bristow, S., & Patrick, S. (2014). An international study in competency education: Postcards from abroad. CompetencyWorks Issue Brief. Vienna, VA: International Association for K–12 Online Learning. Retrieved from <a href="http://www.inacol.org/wp-content/uploads/2015/03/CW-An-International-Study-in-Competency-Education-Postcards-from-Abroad-October-2014.pdf" target="_blank">http://www.inacol.org/wp-content/uploads/2015/03/CW-An-International-Study-in-Competency-Education-Postcards-from-Abroad-October-2014.pdf</a></div>
<div class="reference">Canadian Charter of Rights and Freedoms. (1982). Part I of the Constitution Act, 1982, RSC 1985, app. II, no. 44.</div>
<div class="reference">Christensen, N., & Lane, J. (2016). Know, do, understand: Development of competencies in Canada’s schoolchildren. Calgary, AB: Canada West Foundation. Retrieved from <a href="http://cwf.ca/wp-content/uploads/2016/03/HCP_KnowDoUnderstand_Report_MARCH2016-2.pdf" target="_blank">http://cwf.ca/wp-content/uploads/2016/03/HCP_KnowDoUnderstand_Report_MARCH2016-2.pdf</a></div>
<div class="reference">Cultural Survival. (n.d.). Who are Indigenous peoples? Retrieved from <a href="https://www.culturalsurvival.org/who-are-indigenous-peoples" target="_blank">https://www.culturalsurvival.org/who-are-indigenous-peoples</a></div>
<div class="reference">Davis, B., Sumara, D., & Luce-Kapler, R. (2008). Engaging minds: Changing teaching in complex times. New York, NY: Routledge.</div>
<div class="reference">Education Act (2012, cE-0.3). Edmonton, AB: Alberta Queen’s Printer.</div>
<div class="reference">European Commission/EACEA/Eurydice. (2012). Developing key competences at school, in Europe: Challenges and opportunities for policy. Eurydice Report. Luxembourg: Publications Office of the European Union. Retrieved from <a href="http://eacea.ec.europa.eu/education/eurydice/documents/thematic_reports/145EN.pdf" target="_blank">http://eacea.ec.europa.eu/education/eurydice/documents/thematic_reports/145EN.pdf</a></div>
<div class="reference">Government of Alberta. (2016). Education business plan 2015–18. Retrieved from <a href="https://education.alberta.ca/media/159439/alberta-education-business-plan-2015-18.pdf" target="_blank">https://education.alberta.ca/media/159439/alberta-education-business-plan-2015-18.pdf</a></div>
<div class="reference">Hinton, C., & Fischer, K. W. (2010). Learning from the developmental and biological perspective. From H. Dumont, D. Istance, & F. Benavides (Eds.), The Nature of Learning: Using Research to Inspire Practice (pp. 113–133). Paris, France: Organisation for Economic Co-operation and Development (OECD).</div>
<div class="reference">Inuulitsivik. (2011–2016). Who are the Inuits? Retrieved from <a href="http://www.inuulitsivik.ca/northern-life-and-inuit-culture/who-are-the-inuits" target="_blank">http://www.inuulitsivik.ca/northern-life-and-inuit-culture/who-are-the-inuits</a></div>
<div class="reference">Istance, D., & Dumont, H. (2010). Future directions for learning environments in the 21st century. In H. Dumont, D. Istance, & F. Benavides (Eds.), The nature of learning: Using research to inspire practice (pp. 317–340). Paris: Organisation for Economic Co-operation and Development.</div>
<div class="reference">McTighe, J., & Wiggins, G. (2004). Understanding by design professional development workbook. Alexandria, VA: Association for Supervision and Curriculum Development.</div>
<div class="reference">Métis Nation of Alberta. (2016). Who is Métis. Retrieved from <a href="http://albertametis.com/metis-rights/who-is-metis/" target="_blank">http://albertametis.com/metis-rights/who-is-metis/</a></div>
<div class="reference">National Governors Association Center for Best Practices, Council of Chief State School Officers. (2010). Common core state standards. Washington, DC: National Governors Association Center for Best Practices, Council of Chief State School Officers.</div>
<div class="reference">Ontario Ministry of Education. (2016). 21st century competencies: Foundation document for discussion. Phase 1: Towards defining 21st century competencies for Ontario. Toronto, ON: Ontario Ministry of Education. Retrieved from <a href="http://www.edugains.ca/resources21CL/About21stCentury/21CL_21stCenturyCompetencies.pdf" target="_blank">http://www.edugains.ca/resources21CL/About21stCentury/21CL_21stCenturyCompetencies.pdf</a></div>
<div class="reference">Organisation for Economic Co-operation and Development (OECD). (2003). Key competencies for a successful life and well-functioning society. The definition and selection of key competencies: Executive summary. Summary of report by S. D. Rychen & L. H. Salganik (Eds.). Göttingen: Hogrefe and Huber Publishers. Retrieved from <a href="http://www.oecd.org/dataoecd/47/61/35070367.pdf" target="_blank">www.oecd.org/dataoecd/47/61/35070367.pdf</a></div>
<div class="reference">School Act (RSA 2000, cS-3). Edmonton, AB: Alberta Queen’s Printer. Retrieved July 2016 from <a href="http://www.qp.alberta.ca/documents/Acts/s03.pdf" target="_blank">http://www.qp.alberta.ca/documents/Acts/s03.pdf</a></div>
<div class="reference">Sinclair, M., Littlechild, W., & Wilson, M. (2015). What we have learned: Principles of truth and reconciliation. Winnipeg, MB: Truth and Reconciliation Commission. Retrieved June 2016 from <a href="http://www.trc.ca/websites/trcinstitution/File/2015/Findings/Principles_2015_05_31_web_o.pdf" target="_blank">http://www.trc.ca/websites/trcinstitution/File/2015/Findings/Principles_2015_05_31_web_o.pdf</a></div>
<div class="reference">United Nations Permanent Forum on Indigenous Issues. (n.d.). Indigenous peoples, indigenous voices (factsheet). Retrieved May 2016 from <a href="http://www.un.org/esa/socdev/unpfii/documents/5session_factsheet1.pdf" target="_blank">http://www.un.org/esa/socdev/unpfii/documents/5session_factsheet1.pdf</a></div>
<div class="reference">Wiggins, G., & McTighe, J. (1998). Understanding by design. Alexandria, VA: Association for Supervision and Curriculum Development.</div>

<h3>Bibliography</h3>
<ul>
    <li>This list contains all resources consulted during the development of the document, whether cited or not. For those directly cited, see list of References (Page 32).</li>
</ul>
<div class="reference">Adam, S. (2004). Using learning outcomes. Report for United Kingdom Bologna Seminar 1-2. Edinburgh, Scotland: Heriot-Watt University (Edinburgh Conference Centre). Retrieved from <a href="http://www.gov.scot/resource/doc/25725/0028779.pdf" target="_blank">http://www.gov.scot/resource/doc/25725/0028779.pdf</a></div>
<div class="reference">Adler, M. (1982). The Paideia proposal: An educational manifesto. New York: MacMillan.</div>
<div class="reference">Alberta Education. (1987). Essential concepts, skills and attitudes for grade 12 (second draft). Edmonton, AB: Alberta Education.</div>
<div class="reference">Alberta Education. (1997). Teaching Quality Standard Applicable to the Provision of Basic Education in Alberta. Ministerial Order (#016/97). Retrieved from <a href="https://education.alberta.ca/media/1626523/english-tqs-card-2013_3.pdf" target="_blank">https://education.alberta.ca/media/1626523/english-tqs-card-2013_3.pdf</a></div>
<div class="reference">Alberta Education. (2005). Our words, our ways: Teaching First Nations, Métis and Inuit learners. Edmonton, AB: Alberta Education. Retrieved from <a href="https://education.alberta.ca/media/563982/our-words-our-ways.pdf" target="_blank">https://education.alberta.ca/media/563982/our-words-our-ways.pdf</a></div>
<div class="reference">Alberta Education. (2005, 2006, 2007). Social studies kindergarten to grade 12 program of studies. Edmonton, AB: Alberta Education. Retrieved November 2015 from <a href="https://education.alberta.ca/teachers/program/socialstudies/programs/" target="_blank">https://education.alberta.ca/teachers/program/socialstudies/programs/</a></div>
<div class="reference">Alberta Education. (2007a). Primary programs framework for teaching and learning (kindergarten to grade 3): Introduction. Edmonton, AB: Alberta Education. Retrieved from <a href="https://education.alberta.ca/media/656568/intro.pdf" target="_blank">https://education.alberta.ca/media/656568/intro.pdf</a></div>
<div class="reference">Alberta Education. (2007b). Primary programs framework for teaching and learning (kindergarten to grade 3): Guiding principles. Edmonton, AB: Alberta Education. Retrieved from <a href="https://education.alberta.ca/media/656591/guiding.pdf" target="_blank">https://education.alberta.ca/media/656591/guiding.pdf</a></div>
<div class="reference">Alberta Education. (2009a). Setting the direction framework. Edmonton, AB: Alberta Education. Retrieved from <a href="https://education.alberta.ca/media/1082136/sc_settingthedirection_ framework.pdf" target="_blank">https://education.alberta.ca/media/1082136/sc_settingthedirection_ framework.pdf</a></div>
<div class="reference">Alberta Education. (2009b). Kindergarten to grade 6 science program of studies (draft). Edmonton, AB: Alberta Education.</div>
<div class="reference">Alberta Education. (2011). Walking together: First Nations, Métis and Inuit perspectives in curriculum. Retrieved from <a href="http://www.learnalberta.ca/content/aswt/" target="_blank">http://www.learnalberta.ca/content/aswt/</a></div>
<div class="reference">Alberta Education. (2012). From knowledge to action: Shaping the future of curriculum development in Alberta. Edmonton, AB: Alberta Education. Retrieved from <a href="https://archive.education.alberta.ca/media/6808607/knowledge_action.pdf" target="_blank">https://archive.education.alberta.ca/media/6808607/knowledge_action.pdf</a></div>
<div class="reference">Alberta Education. (2013a). Ministerial order on student learning (#001/2013). Retrieved from <a href="http://education.alberta.ca/department/policy/standards/goals.aspx" target="_blank">http://education.alberta.ca/department/policy/standards/goals.aspx</a></div>
<div class="reference">Alberta Education. (2015a). Curriculum development: For a better future for all our kids (unpublished).</div>
<div class="reference">Alberta Education. (2015b). Revised draft literacy and numeracy progressions (unpublished).</div>
<div class="reference">Alberta Education. (2015c). Guiding voices: A curriculum development tool for weaving First Nation, Métis and Inuit perspectives throughout curriculum. Retrieved from <a href="http://www.learnalberta.ca/content/fnmigv/index.html" target="_blank">http://www.learnalberta.ca/content/fnmigv/index.html</a></div>
<div class="reference">Alberta Education. (2015a, September). Guide to education: ECS to Grade 12, 2015–2016. Edmonton, AB: Alberta Education. Retrieved from <a href="https://education.alberta.ca/media/1626501/guide_to_ed_school_act_2015-june-4-with-active-links.pdf" target="_blank">https://education.alberta.ca/media/1626501/guide_to_ed_school_act_2015-june-4-with-active-links.pdf</a></div>
<div class="reference">Alberta Education. (2015b, September). Revised draft guiding principles for curriculum development (unpublished).</div>
<div class="reference">Alberta Education. (2015, October). Competencies and competency indicators (unpublished).</div>
<div class="reference">Alberta Education. (2016). Guidelines for best practices: Creating learning environments that respect diverse sexual orientations, gender identities and gender expressions. Retrieved from <a href="https://education.alberta.ca/media/1626737/91383-attachment-1-guidelines-final.pdf" target="_blank">https://education.alberta.ca/media/1626737/91383-attachment-1-guidelines-final.pdf</a></div>
<div class="reference">Alberta Education. (2016, February). Revised draft standards for curriculum development (unpublished).</div>
<div class="reference">Alberta Education. (2016, May). Streamlined expression of competencies—descriptions, indicators and examples. Retrieved from <a href="https://education.alberta.ca/media/3115412/competency-descriptions-indicators-and-examples-approved.pdf" target="_blank">https://education.alberta.ca/media/3115412/competency-descriptions-indicators-and-examples-approved.pdf</a></div>
<div class="reference">Alberta School Boards Association, Alberta School Councils’ Association, College of Alberta School Superintendents. (n.d.). Measuring up: Student assessment in Alberta. Retrieved July 2016 from <a href="http://o.b5z.net/i/u/10063916/h/Communications/Student_Assessment_Report_ FINAL.pdf" target="_blank">http://o.b5z.net/i/u/10063916/h/Communications/Student_Assessment_Report_ FINAL.pdf</a></div>
<div class="reference">Amadio, M. (2013). A rapid assessment of curricula for general education focusing on cross-curricular themes and generic competences or skills. Background paper prepared for the Education for All Global Monitoring Report 2013/2014, Teaching and learning: Achieving quality for all. Geneva, UNESCO IBE. Retrieved from <a href="http://unesdoc.unesco.org/images/0022/002259/225939e.pdf" target="_blank">http://unesdoc.unesco.org/images/0022/002259/225939e.pdf</a></div>
<div class="reference">Ananiadou, K., & Claro, M. (2009). 21st century skills and competences for new millennium learners in OECD countries. OECD Education Working Papers, No. 41. Paris: OECD Publishing. Retrieved from <a href="http://dx.doi.org/10.1787/218525261154" target="_blank">http://dx.doi.org/10.1787/218525261154</a></div>
<div class="reference">Armstrong, D. G. (2003). Developing and documenting the curriculum. Boston: Allyn & Bacon.</div>
<div class="reference">Assembly of First Nations. (n.d.). A declaration of First Nations. Retrieved from <a href="http://www.afn.ca/en/about-afn/a-declaration-of-first-nations" target="_blank">http://www.afn.ca/en/about-afn/a-declaration-of-first-nations</a></div>
<div class="reference">Australian Curriculum, Assessment and Reporting Authority and Education Services Australia. (n.d.a). Foundation to year 10 curriculum: Science. Retrieved April 2015 from <a href="http://www.australiancurriculum.edu.au/science/curriculum/f-10?layout=1" target="_blank">http://www.australiancurriculum.edu.au/science/curriculum/f-10?layout=1</a></div>
<div class="reference">Australian Curriculum, Assessment and Reporting Authority and Education Services Australia. (n.d.b). Literacy – Learning continuum. Retrieved April 2015 from <a href="http://v7-5.australiancurriculum.edu.au/generalcapabilities/literacy/continuum#layout=columns" target="_blank">http://v7-5.australiancurriculum.edu.au/generalcapabilities/literacy/continuum#layout=columns</a></div>
<div class="reference">Australian Curriculum, Assessment and Reporting Authority and Education Services Australia. (n.d.c). Senior secondary curriculum: Physics, Unit 1. Retrieved April 2015 from <a href="http://www.australiancurriculum.edu.au/seniorsecondary/science/physics/curriculum/seniorsecondary#page=1" target="_blank">http://www.australiancurriculum.edu.au/seniorsecondary/science/physics/curriculum/seniorsecondary#page=1</a></div>
<div class="reference">Australian Curriculum, Assessment and Reporting Authority and Education Services Australia. (n.d.d). Senior secondary curriculum: Physics, Unit 3. Retrieved April 2015 from <a href="http://www.australiancurriculum.edu.au/seniorsecondary/science/physics/curriculum/seniorsecondary#page=3" target="_blank">http://www.australiancurriculum.edu.au/seniorsecondary/science/physics/curriculum/seniorsecondary#page=3</a></div>
<div class="reference">Australian Curriculum, Assessment and Reporting Authority. (2013a). Curriculum design paper, version 3.1. Retrieved November 2015 from <a href="http://www.acara.edu.au/verve/_resources/07_04_Curriculum_Design_Paper_version_3+1_(June_2012).pdf" target="_blank">http://www.acara.edu.au/verve/_resources/07_04_Curriculum_Design_Paper_version_3+1_(June_2012).pdf</a></div>
<div class="reference">Australian Curriculum, Assessment and Reporting Authority. (2013b). General capabilities in the Australian curriculum. Retrieved November 2015 from <a href="http://www.australiancurriculum.edu.au/generalcapabilities/pdf/overview" target="_blank">http://www.australiancurriculum.edu.au/generalcapabilities/pdf/overview</a></div>
<div class="reference">Baroody, A. J., Feil, Y., & Johnson, A. R. (2007, March). An alternative reconceptualization of procedural and conceptual knowledge. Journal for Research in Mathematics Education, 38(2), 115–131.</div>
<div class="reference">Barron, B., & Darling-Hammond, L. (2010). Prospects and challenges of inquiry-based approaches to learning. From H. Dumont, D. Istance, & F. Benavides (Eds.), The Nature of Learning: Using Research to Inspire Practice (pp. 199–225). Paris, France: Organisation for Economic Co-operation and Development (OECD).</div>
<div class="reference">Behar, L. (1994). The knowledge base of the curriculum: An empirical analysis. New York: University Press of America.</div>
<div class="reference">Bernal, J. D. (1971). Science in history, vol. 4. Cambridge, MA: MIT Press.</div>
<div class="reference">Bestor, A. (1953). Educational wastelands: The retreat from learning in our public schools. Champaign, IL: University of Illinois Press.</div>
<div class="reference">Bristow, S., & Patrick, S. (2014). An international study in competency education: Postcards from abroad. CompetencyWorks Issue Brief. Vienna, VA: International Association for K–12 Online Learning. Retrieved from <a href="http://www.inacol.org/wp-content/uploads/2015/03/CW-An-International-Study-in-Competency-Education-Postcards-from-Abroad-October-2014.pdf" target="_blank">http://www.inacol.org/wp-content/uploads/2015/03/CW-An-International-Study-in-Competency-Education-Postcards-from-Abroad-October-2014.pdf</a></div>
<div class="reference">British Columbia Ministry of Education. (2008). Making space: Teaching for diversity and social justice throughout the K–12 curriculum. Victoria, BC: British Columbia Ministry of Education. Retrieved November 2015 from <a href="http://www.bced.gov.bc.ca/irp/pdfs/making_space/makingSpace_full.pdf" target="_blank">http://www.bced.gov.bc.ca/irp/pdfs/making_space/makingSpace_full.pdf</a></div>
<div class="reference">British Columbia Ministry of Education. (2013). Exploring curriculum design: Transforming curriculum and assessment. Retrieved November 2015 from <a href="https://www.bced.gov.bc.ca/irp/docs/exp_curr_design.pdf" target="_blank">https://www.bced.gov.bc.ca/irp/docs/exp_curr_design.pdf</a></div>
<div class="reference">British Columbia Ministry of Education. (2014a). Curriculum redesign update. Spring 2014. Retrieved November 2015 from <a href="https://curriculum.gov.bc.ca/sites/curriculum.gov.bc.ca/files/pdf/curriculum_redesign_update.pdf" target="_blank">https://curriculum.gov.bc.ca/sites/curriculum.gov.bc.ca/files/pdf/curriculum_redesign_update.pdf</a></div>
<div class="reference">British Columbia Ministry of Education. (2014b). Curriculum redesign update. Winter 2014. Retrieved November 2015 from <a href="https://curriculum.gov.bc.ca/sites/curriculum.gov.bc.ca/files/pdf/Curriculum%20Redesign%20Update%20Winter.pdf" target="_blank">https://curriculum.gov.bc.ca/sites/curriculum.gov.bc.ca/files/pdf/Curriculum%20Redesign%20Update%20Winter.pdf</a></div>
<div class="reference">British Columbia Ministry of Education. (2015a). Aboriginal worldviews and perspectives in the classroom: Moving forward. Victoria, BC: British Columbia Ministry of Education. Retrieved November 2015 from <a href="https://www.bced.gov.bc.ca/abed/awp_moving_forward.pdf" target="_blank">https://www.bced.gov.bc.ca/abed/awp_moving_forward.pdf</a></div>
<div class="reference">British Columbia Ministry of Education. (2015b). Communication. Retrieved November 2015 from <a href="https://curriculum.gov.bc.ca/competencies/communication" target="_blank">https://curriculum.gov.bc.ca/competencies/communication</a></div>
<div class="reference">British Columbia Ministry of Education. (2015c). Core competencies. Retrieved November 2015 from <a href="https://curriculum.gov.bc.ca/competencies" target="_blank">https://curriculum.gov.bc.ca/competencies</a></div>
<div class="reference">British Columbia Ministry of Education. (2015d). Creative thinking. Retrieved November 2015 from <a href="https://curriculum.gov.bc.ca/competencies/creative_thinking" target="_blank">https://curriculum.gov.bc.ca/competencies/creative_thinking</a></div>
<div class="reference">British Columbia Ministry of Education. (2015e). Critical thinking. Retrieved November 2015 from <a href="https://curriculum.gov.bc.ca/competencies/critical_thinking" target="_blank">https://curriculum.gov.bc.ca/competencies/critical_thinking</a></div>
<div class="reference">British Columbia Ministry of Education. (2015f). Curriculum redesign: Frequently asked questions. Retrieved November 2015 from <a href="https://curriculum.gov.bc.ca/sites/curriculum.gov.bc.ca/files/pdf/faq.pdf" target="_blank">https://curriculum.gov.bc.ca/sites/curriculum.gov.bc.ca/files/pdf/faq.pdf</a></div>
<div class="reference">British Columbia Ministry of Education. (2015g). Curriculum redesign: What’s new? What’s the same? Retrieved November 2015 from <a href="https://curriculum.gov.bc.ca/sites/curriculum.gov.bc.ca/files/pdf/redesign.pdf" target="_blank">https://curriculum.gov.bc.ca/sites/curriculum.gov.bc.ca/files/pdf/redesign.pdf</a></div>
<div class="reference">British Columbia Ministry of Education. (2015h). Introduction to British Columbia’s redesigned curriculum. Retrieved November 2015 from <a href="https://curriculum.gov.bc.ca/sites/curriculum.gov.bc.ca/files/pdf/curriculum_intro.pdf" target="_blank">https://curriculum.gov.bc.ca/sites/curriculum.gov.bc.ca/files/pdf/curriculum_intro.pdf</a></div>
<div class="reference">British Columbia Ministry of Education. (2015i). Personal awareness and responsibility. Retrieved November 2015 from <a href="https://curriculum.gov.bc.ca/competencies/personal-awareness-responsibility" target="_blank">https://curriculum.gov.bc.ca/competencies/personal-awareness-responsibility</a></div>
<div class="reference">British Columbia Ministry of Education. (2015j). Positive personal & cultural identity. Retrieved November 2015 from <a href="https://curriculum.gov.bc.ca/competencies/ppci" target="_blank">https://curriculum.gov.bc.ca/competencies/ppci</a></div>
<div class="reference">British Columbia Ministry of Education. (2015k). Social responsibility. Retrieved November 2015 from <a href="https://curriculum.gov.bc.ca/competencies/social-responsibility" target="_blank">https://curriculum.gov.bc.ca/competencies/social-responsibility</a></div>
<div class="reference">British Columbia Ministry of Education. (n.d.a). Aboriginal education. Retrieved November 2015 from <a href="https://curriculum.gov.bc.ca/sites/curriculum.gov.bc.ca/files/pdf/aboriginal_education_bc.pdf" target="_blank">https://curriculum.gov.bc.ca/sites/curriculum.gov.bc.ca/files/pdf/aboriginal_education_bc.pdf</a></div>
<div class="reference">British Columbia Ministry of Education. (n.d.b). Graduation years curriculum: Proposed directions. Retrieved November 2015 from <a href="https://curriculum.gov.bc.ca/sites/curriculum.gov.bc.ca/files/pdf/GradYrCurriculumDirections.pdf" target="_blank">https://curriculum.gov.bc.ca/sites/curriculum.gov.bc.ca/files/pdf/GradYrCurriculumDirections.pdf</a></div>
<div class="reference">British Columbia Ministry of Education. (n.d.c). Learning standards and flexible learning environments. Retrieved November 2015 from <a href="https://curriculum.gov.bc.ca/sites/curriculum.gov.bc.ca/files/pdf/flexible_learning_environments.pdf" target="_blank">https://curriculum.gov.bc.ca/sites/curriculum.gov.bc.ca/files/pdf/flexible_learning_environments.pdf</a></div>
<div class="reference">British Columbia Ministry of Education. (n.d.d). References relevant to BC’s curriculum and assessment transformation. Retrieved November 2015 from <a href="https://curriculum.gov.bc.ca/sites/curriculum.gov.bc.ca/files/pdf/references.pdf" target="_blank">https://curriculum.gov.bc.ca/sites/curriculum.gov.bc.ca/files/pdf/references.pdf</a></div>
<div class="reference">British Columbia Ministry of Education. (n.d.e). Student supports. Retrieved November 2015 from <a href="https://curriculum.gov.bc.ca/sites/curriculum.gov.bc.ca/files/pdf/student_supports.pdf" target="_blank">https://curriculum.gov.bc.ca/sites/curriculum.gov.bc.ca/files/pdf/student_supports.pdf</a></div>
<div class="reference">Canadian Charter of Rights and Freedoms. (1982). Part I of the Constitution Act, 1982, RSC 1985, app. II, no. 44.</div>
<div class="reference">Canadian Council on Learning. (2007). Redefining How Success is Measured in First Nations, Inuit and Métis Learning, Report on Learning in Canada 2007 (Ottawa: 2007.) pages(s).</div>
<div class="reference">Carr, J., & Harris, D. (1993). Getting it together. Boston: Allyn & Bacon.</div>
<div class="reference">Center for Applied Special Technology (CAST). (2011). Universal design for learning guidelines version 2.0. Wakefield, MA: CAST.</div>
<div class="reference">Christensen, N., & Lane, J. (2016). Know, do, understand: Development of competencies in Canada’s schoolchildren. Calgary, AB: Canada West Foundation. Retrieved from <a href="http://cwf.ca/wp-content/uploads/2016/03/HCP_KnowDoUnderstand_Report_MARCH2016-2.pdf" target="_blank">http://cwf.ca/wp-content/uploads/2016/03/HCP_KnowDoUnderstand_Report_MARCH2016-2.pdf</a></div>
<div class="reference">Cook, R. C., & Doll, R. C. (1973). The elementary school curriculum. Boston: Allyn & Bacon.</div>
<div class="reference">Counts, G. (1932). Dare the schools build a new social order? New York: World Book.</div>
<div class="reference">Cultural Survival. (n.d.). Who are Indigenous peoples? Retrieved from <a href="https://www.culturalsurvival.org/who-are-indigenous-peoples" target="_blank">https://www.culturalsurvival.org/who-are-indigenous-peoples</a></div>
<div class="reference">Davis, B., Sumara, D., & Luce-Kapler, R. (2008). Engaging minds: Changing teaching in complex times. New York, NY: Routledge.</div>
<div class="reference">De Corte, E. (2010). Historical developments in the understanding of learning. From H. Dumont, D. Istance, & F. Benavides (Eds.), The Nature of Learning: Using Research to Inspire Practice (pp. 35–67). Paris, France: Organisation for Economic Co-operation and Development (OECD).</div>
<div class="reference">Dewey, J. (1916). Democracy and education. New York: MacMillan.</div>
<div class="reference">Dewey, J. (1933). How we think: A restatement of the relation of reflective thinking to the education process. Chicago: Henry Regnery.</div>
<div class="reference">Education Act (2012, cE-0.3). Edmonton, AB: Alberta Queen’s Printer.</div>
<div class="reference">Education Scotland. (n.d.a) Curriculum for excellence: Literacy across learning: Principles and practice. Retrieved November 2015 from <a href="http://www.educationscotland.gov.uk/learningand teaching/learningacrossthecurriculum/responsibilityofall/literacy/principlesandpractice/index.asp" target="_blank">http://www.educationscotland.gov.uk/learningand teaching/learningacrossthecurriculum/responsibilityofall/literacy/principlesandpractice/index.asp</a></div>
<div class="reference">Education Scotland. (n.d.b) Curriculum for excellence: Numeracy across learning: Principles and practice. Retrieved November 2015 from <a href="http://www.educationscotland.gov.uk/learningandteaching/learningacrossthecurriculum/responsibilityofall/numeracy/principlesandpractice/index.asp" target="_blank">http://www.educationscotland.gov.uk/learningandteaching/learningacrossthecurriculum/responsibilityofall/numeracy/principlesandpractice/index.asp</a></div>
<div class="reference">Education Scotland. (n.d.c). Our strategic goals. Retrieved from <a href="http://www.educationscotland.gov.uk/about/remitandframework/strategicgoals.asp" target="_blank">http://www.educationscotland.gov.uk/about/remitandframework/strategicgoals.asp</a></div>
<div class="reference">English, F. W. (1992). Deciding what to teach and test. California: Corwin Press.</div>
<div class="reference">European Commission/EACEA/Eurydice. (2012). Developing key competences at school, in Europe: Challenges and opportunities for policy. Eurydice Report. Luxembourg: Publications Office of the European Union. Retrieved from <a href="http://eacea.ec.europa.eu/education/eurydice/documents/thematic_reports/145EN.pdf" target="_blank">http://eacea.ec.europa.eu/education/eurydice/documents/thematic_reports/145EN.pdf</a></div>
<div class="reference">Executive summary. (2010). From H. Dumont, D. Istance, & F. Benavides (Eds.), The Nature of Learning: Using Research to Inspire Practice (pp. 13–18). Paris, France: Organisation for Economic Co-operation and Development (OECD).</div>
<div class="reference">Fink, L. D. (2003). Creating significant learning experiences: An integrated approach to designing college courses. New York: Jossey-Bass.</div>
<div class="reference">Freire, P. (1970). Pedagogy of the oppressed. New York: Herder & Herder.</div>
<div class="reference">Gagné, R. M., & Briggs, L. J. (1974). Principles of instructional design. New York: Holt, Rinehart and Winston.</div>
<div class="reference">Gatherer, W. A. (1989). Curriculum development in Scotland. Edinburgh: Scottish Academic Press.</div>
<div class="reference">Glass, K. T. (2012). Mapping comprehensive units to the ELA common core standards, K–5. Thousand Oaks, CA: Corwin Press.</div>
<div class="reference">Government of Alberta. (2016). Education business plan 2015–18. Retrieved from <a href="https://education.alberta.ca/media/159439/alberta-education-business-plan-2015-18.pdf" target="_blank">https://education.alberta.ca/media/159439/alberta-education-business-plan-2015-18.pdf</a></div>
<div class="reference">Hinton, C., & Fischer, K. W. (2010). Learning from the developmental and biological perspective. From H. Dumont, D. Istance, & F. Benavides (Eds.), The Nature of Learning: Using Research to Inspire Practice (pp. 113–133). Paris, France: Organisation for Economic Co-operation and Development (OECD).</div>
<div class="reference">Holmes, B., & McLean, M. (1989). The curriculum: A comparative perspective. London: Unwin Hyman.</div>
<div class="reference">Hutchins, R. (1963). A conversation in education. Santa Barbara, CA: The Fund for the Republic.</div>
<div class="reference">Inuulitsivik. (2011–2016). Who are the Inuits? Retrieved from <a href="http://www.inuulitsivik.ca/northern-life-and-inuit-culture/who-are-the-inuits" target="_blank">http://www.inuulitsivik.ca/northern-life-and-inuit-culture/who-are-the-inuits</a></div>
<div class="reference">Istance, D., & Dumont, H. (2010). Future directions for learning environments in the 21st century. In H. Dumont, D. Istance, & F. Benavides (Eds.), The nature of learning: Using research to inspire practice (pp. 317–340). Paris: Organisation for Economic Co-operation and Development.</div>
<div class="reference">Kircher, E. (1974). Philosophy of education – Directive doctrine or liberal discipline? In J. Park (Ed.), Selected Readings in the Philosophy of Education (pp. 200–229). New York: MacMillan Publishing.</div>
<div class="reference">Knight, G. (2008). Issues and alternatives in educational philosophy, 4th ed. Michigan: Andrews University Press.</div>
<div class="reference">Leithwood, K. (2010, June). Turning around underperforming school systems: Guidelines for district leaders. Edmonton, AB: College of Alberta School Superintendents. Retrieved July 2016 from <a href="http://o.b5z.net/i/u/10063916/h/Communications/CASS_Research_Paper_3_ Leithwood_Turning_Around_School_Systems.pdf" target="_blank">http://o.b5z.net/i/u/10063916/h/Communications/CASS_Research_Paper_3_ Leithwood_Turning_Around_School_Systems.pdf</a></div>
<div class="reference">Leithwood, K. (2008, September). Characteristics of high performing school districts; A review of empirical evidence. Edmonton, AB: College of Alberta School Superintendents. Retrieved July 2016 from <a href="http://o.b5z.net/i/u/10063916/h/Communications/CASS_Research_ Paper_1_Leithwood_Characteristics_of_High_Performing_Districts.pdf" target="_blank">http://o.b5z.net/i/u/10063916/h/Communications/CASS_Research_ Paper_1_Leithwood_Characteristics_of_High_Performing_Districts.pdf</a></div>
<div class="reference">Leithwood, K., McAdie, P., Bascia, N., & Rodrigue, A. (2004). Teaching for deep understanding: Towards the Ontario curriculum that we need. Toronto, ON: OISE/UT and ETFO.</div>
<div class="reference">Lewey, A. (1991). National and school-based curriculum development. Paris: UNESCO: International Institute for Educational Planning.</div>
<div class="reference">Longstreet, W., & Shane, H. (1993). Curriculum for the new millennium. Boston: Allyn & Bacon.</div>
<div class="reference">Manitoba Education. (2010). Engaging middle years students in learning: Transforming middle years education in Manitoba. Winnipeg, MB: Manitoba Education. Retrieved November 2015 from <a href="http://www.edu.gov.mb.ca/k12/docs/support/my_foundation/full_doc.pdf" target="_blank">http://www.edu.gov.mb.ca/k12/docs/support/my_foundation/full_doc.pdf</a></div>
<div class="reference">Marzano, R. J. (2003). What works in schools: Translating research into action. Alexandria, VA: Association for Supervision and Curriculum Development.</div>
<div class="reference">McNeil, J. D. (1985). Curriculum: A comprehensive introduction, 3rd ed. Boston: Little, Brown & Co.</div>
<div class="reference">McTighe, J., & Wiggins, G. (2004). Understanding by design professional development workbook. Alexandria, VA: Association for Supervision and Curriculum Development.</div>
<div class="reference">Métis Nation of Alberta. (2016). Who is Métis. Retrieved from <a href="http://albertametis.com/metis-rights/who-is-metis/" target="_blank">http://albertametis.com/metis-rights/who-is-metis/</a></div>
<div class="reference">Miller, J. P., & Seller, W. (1985). Curriculum perspectives and practices. New York: Longman.</div>
<div class="reference">Ministerial Council on Education, Employment, Training and Youth Affairs. (2008). Melbourne Declaration on Educational Goals for Young Australians. Retrieved November 2015 from <a href="http://www.curriculum.edu.au/verve/_resources/National_Declaration_on_the_Educational_Goals_for_Young_Australians.pdf" target="_blank">http://www.curriculum.edu.au/verve/_resources/National_Declaration_on_the_Educational_Goals_for_Young_Australians.pdf</a></div>
<div class="reference">Mueller, D. (1991). A guide for curriculum writers. New York: University Press of America.</div>
<div class="reference">National Commission on Excellence in Education. (1983). A nation at risk: The imperative for educational reform. Washington, DC: US Government Printing Office.</div>
<div class="reference">National Council for Curriculum and Assessment. (2003). Junior certificate: Science syllabus (ordinary level and higher level). Dublin, Ireland: National Council for Curriculum and Assessment. Retrieved November 2015 from <a href="http://www.curriculumonline.ie/getmedia/7fc1db67-cd62-4be3-a4b6-ca0c88556e5e/JCSEC23_Science_syllabus.pdf" target="_blank">http://www.curriculumonline.ie/getmedia/7fc1db67-cd62-4be3-a4b6-ca0c88556e5e/JCSEC23_Science_syllabus.pdf</a></div>
<div class="reference">National Council for Curriculum and Assessment. (2014). Key skills of junior cycle. Dublin, Ireland: National Council for Curriculum and Assessment.</div>
<div class="reference">National Council for Curriculum and Assessment. (n.d.). Physical education: Overview: Course. Retrieved November 2015 from <a href="http://www.curriculumonline.ie/Junior-cycle/Short-Courses/Physical-Education/Overview-Course" target="_blank">http://www.curriculumonline.ie/Junior-cycle/Short-Courses/Physical-Education/Overview-Course</a></div>
<div class="reference">National Governors Association Center for Best Practices, Council of Chief State School Officers. (2010). Common core state standards. Washington, DC: National Governors Association Center for Best Practices, Council of Chief State School Officers.</div>
<div class="reference">New Zealand Government, Ministry of Education. (2007a). Purpose and scope. Retrieved November 2015 from <a href="http://nzcurriculum.tki.org.nz/The-New-Zealand-Curriculum/Purpose-and-scope" target="_blank">http://nzcurriculum.tki.org.nz/The-New-Zealand-Curriculum/Purpose-and-scope</a></div>
<div class="reference">New Zealand Government, Ministry of Education. (2007b). The Education Act and the curriculum. Retrieved November 2015 from <a href="http://nzcurriculum.tki.org.nz/The-New-Zealand-Curriculum/The-Education-Act-and-the-curriculum" target="_blank">http://nzcurriculum.tki.org.nz/The-New-Zealand-Curriculum/The-Education-Act-and-the-curriculum</a></div>
<div class="reference">New Zealand Government, Ministry of Education. (2007c). The New Zealand curriculum. Wellington, New Zealand: Ministry of Education.</div>
<div class="reference">New Zealand Government, Ministry of Education. (2007d). The New Zealand curriculum. Retrieved November 2015 from <a href="http://nzcurriculum.tki.org.nz/The-New-Zealand-Curriculum" target="_blank">http://nzcurriculum.tki.org.nz/The-New-Zealand-Curriculum</a></div>
<div class="reference">New Zealand Government, Ministry of Education. (2007e). Vision. Retrieved November 2015 from <a href="http://nzcurriculum.tki.org.nz/The-New-Zealand-Curriculum/Vision" target="_blank">http://nzcurriculum.tki.org.nz/The-New-Zealand-Curriculum/Vision</a></div>
<div class="reference">Ontario Ministry of Education. (2016). 21st century competencies: Foundation document for discussion. Phase 1: Towards defining 21st century competencies for Ontario. Toronto, ON: Ontario Ministry of Education. Retrieved from <a href="http://www.edugains.ca/resources21CL/About21stCentury/21CL_21stCenturyCompetencies.pdf" target="_blank">http://www.edugains.ca/resources21CL/About21stCentury/21CL_21stCenturyCompetencies.pdf</a></div>
<div class="reference">Organisation for Economic Co-operation and Development (OECD). (2003). Key competencies for a successful life and well-functioning society. The definition and selection of key competencies: Executive summary. Summary of report by S. D. Rychen & L. H. Salganik (Eds.). Göttingen: Hogrefe and Huber Publishers. Retrieved from <a href="www.oecd.org/dataoecd/47/61/35070367.pdf" target="_blank">www.oecd.org/dataoecd/47/61/35070367.pdf</a></div>
<div class="reference">Ornstein, A. C., & Hunkins, F. P. (2013). Curriculum: Foundations, principles, and issues, 6th ed. New Jersey: Prentice Hall.</div>
<div class="reference">Overgaard, V. (1990). A handbook for curriculum development. Vancouver, BC: Vancouver School Board.</div>
<div class="reference">Panwar, R. (2015, September 4). Programs of study design: Elements, components and relationships (unpublished).</div>
<div class="reference">Paris, C. (1993). Teacher agency and curriculum making in classrooms. New York: Teachers College Press.</div>
<div class="reference">Perkins, D. (2014). Future wise: Educating our children for a changing world. San Francisco, CA: Jossey-Bass.</div>
<div class="reference">Reynolds, W. (1989). Reading curriculum theory: The development of a new hermeneutic. New York: Peter Lang.</div>
<div class="reference">Rickover, H. G. (1958). A comparison: European vs. American secondary schools. Phi Delta Kappan, 40(2), 60–64.</div>
<div class="reference">Ritchhart, R., Church, M., & Morrison, K. (2011). Making thinking visible: How to promote engagement, understanding, and independence for all learners. San Francisco, CA: Jossey-Bass.</div>
<div class="reference">Rugg, H., & Schumaker, A. (1928). The child-centered school. New York: World Book.</div>
<div class="reference">Schleicher, A. (2013). The case for 21st-century learning. Retrieved from <a href="http://www.oecd.org/general/thecasefor21st-centurylearning.htm" target="_blank">http://www.oecd.org/general/thecasefor21st-centurylearning.htm</a></div>
<div class="reference">School Act (RSA 2000, cS-3). Edmonton, AB: Alberta Queen’s Printer. Retrieved July 2016 from <a href="http://www.qp.alberta.ca/documents/Acts/s03.pdf" target="_blank">http://www.qp.alberta.ca/documents/Acts/s03.pdf</a></div>
<div class="reference">Schubert, W. H. (1980). Curriculum books: The first eighty years. New York: University Press of America.</div>
<div class="reference">Schubert, W. H. (1986). Curriculum: Perspective, paradigm, and possibility. New York: MacMillan.</div>
<div class="reference">Sharpes, D. K. (1988). Curriculum traditions and practices. London: Routledge.</div>
<div class="reference">Shepherd, G., & Ragan, W. (1992). Modern elementary curriculum, 7th ed. New York: Harcourt Brace Jovanovich.</div>
<div class="reference">Sinclair, M., Littlechild, W., & Wilson, M. (2015). What we have learned: Principles of truth and reconciliation. Winnipeg, MB: Truth and Reconciliation Commission. Retrieved June 2016 from <a href="http://www.trc.ca/websites/trcinstitution/File/2015/Findings/Principles_2015_05_31_web_o.pdf" target="_blank">http://www.trc.ca/websites/trcinstitution/File/2015/Findings/Principles_2015_05_31_web_o.pdf</a></div>
<div class="reference">Singapore Ministry of Education. (2007). Science syllabus: Lower secondary, express/normal (academic). Retrieved November 2015 from <a href="http://www.moe.gov.sg/education/syllabuses/sciences/files/science-lower-secondary-2008.pdf" target="_blank">http://www.moe.gov.sg/education/syllabuses/sciences/files/science-lower-secondary-2008.pdf</a></div>
<div class="reference">Skolverket (The Swedish National Agency for Education). (2013). Curriculum for the upper secondary school. Retrieved November 2015 from <a href="http://www.skolverket.se/om-skolverket/publikationer/visa-enskild-publikation?_xurl_=http%3A%2F%2F www5.skolverket.se%2Fwtpub%2Fws%2Fskolbok%2Fwpubext%2Ftrycksak%2FBlob%2Fpdf2975.pdf%3Fk%3D2975" target="_blank">http://www.skolverket.se/om-skolverket/publikationer/visa-enskild-publikation?_xurl_=http%3A%2F%2F www5.skolverket.se%2Fwtpub%2Fws%2Fskolbok%2Fwpubext%2Ftrycksak%2FBlob%2Fpdf2975.pdf%3Fk%3D2975</a></div>
<div class="reference">Tanner, D., & Tanner, L. (2007). Curriculum development: Theory into practice, 4th ed. New York: MacMillan.</div>
<div class="reference">Tedesco, J. C., Opertti, R., & Amadio, M. (2013). IBE Working Papers on Curriculum Issues N° 10: The curriculum debate: Why is it important today? Retrieved from <a href="http://www.ibe.unesco.org/en/global-news-archive/single-news/news/the-curriculum-debate-why-it-is-important-today-ibe-working-papers-on-curriculum-issues-n-10-j.html" target="_blank">http://www.ibe.unesco.org/en/global-news-archive/single-news/news/the-curriculum-debate-why-it-is-important-today-ibe-working-papers-on-curriculum-issues-n-10-j.html</a></div>
<div class="reference">The Alberta Teachers’ Association. (2010). The courage to choose, Emerging trends and strategic possibilities for informed transformation in Alberta schools: 2010-2011. Edmonton, AB: Alberta Teachers’ Association. Retrieved from <a href="http://www.teachers.ab.ca/SiteCollection Documents/ATA/Publications/Research/PD-86-11c%20The%20Courage%20to%20 Choose.pdf" target="_blank">http://www.teachers.ab.ca/SiteCollection Documents/ATA/Publications/Research/PD-86-11c%20The%20Courage%20to%20 Choose.pdf</a></div>
<div class="reference">The Alberta Teachers’ Association. (2012). A great school for all—Transforming education in Alberta. Edmonton, AB: The Alberta Teachers’ Association. Retrieved from <a href="http://www.teachers.ab.ca/SiteCollectionDocuments/ATA/Publications/Research/PD-86-26%20A%20Great%20School%20for%20All-Transforming%20Education%20in%20 Alberta.pdf" target="_blank">http://www.teachers.ab.ca/SiteCollectionDocuments/ATA/Publications/Research/PD-86-26%20A%20Great%20School%20for%20All-Transforming%20Education%20in%20 Alberta.pdf</a></div>
<div class="reference">Thijs, A., & van den Akker, J. (Eds.). (2009). Curriculum in development. Enschede, The Netherlands: SLO (Netherlands Institute for Curriculum Development). Retrieved November 2015 from <a href="http://www.slo.nl/downloads/2009/curriculum-in-development.pdf/" target="_blank">http://www.slo.nl/downloads/2009/curriculum-in-development.pdf/</a></div>
<div class="reference">Tiana, A., Moya, J., & Luengo, F. (2011). Implementing key competences in basic education: Reflections on curriculum design and development in Spain. European Journal of Education, 46(3), 307–322. doi:10.1111/j.1465-3435.2011.01482.x</div>
<div class="reference">United Nations. (2008). United Nations Declaration on the Rights of Indigenous Peoples. Retrieved from<a href=" http://www.un.org/esa/socdev/unpfii/documents/DRIPS_en.pdf" target="_blank"> http://www.un.org/esa/socdev/unpfii/documents/DRIPS_en.pdf</a></div>
<div class="reference">United Nations Permanent Forum on Indigenous Issues. (n.d.). Indigenous peoples, indigenous voices (factsheet). Retrieved May 2016 from <a href="http://www.un.org/esa/socdev/unpfii/documents/5session_factsheet1.pdf" target="_blank">http://www.un.org/esa/socdev/unpfii/documents/5session_factsheet1.pdf</a></div>
<div class="reference">Walberg, H. J. (1983). We can raise standards. Educational Leadership, 41(2), 4–6.</div>
<div class="reference">Welsh Government, Department for Education and Skills. (2014). Qualified for life: An educational improvement plan for 3 to 19-year-olds in Wales. Retrieved November 2015 from <a href="http://gov.wales/docs/dcells/publications/141001-qualified-for-life-en.pdf" target="_blank">http://gov.wales/docs/dcells/publications/141001-qualified-for-life-en.pdf</a></div>
<div class="reference">Western and Northern Canadian Protocol for Collaboration in Education. (2011). Guiding principles for WNCP curriculum framework projects. Retrieved November 2015 from <a href="http://www.education.gov.sk.ca/adx/aspx/adxGetMedia.aspx?DocID=7413,1429,107,81,1,Documents&MediaID=15800&Filename=Guiding_Principles_FEB2011.pdf" target="_blank">http://www.education.gov.sk.ca/adx/aspx/adxGetMedia.aspx?DocID=7413,1429,107,81,1,Documents&MediaID=15800&Filename=Guiding_Principles_FEB2011.pdf</a></div>
<div class="reference">Western and Northern Canadian Protocol for Collaboration in Education. (2013). Our way is a valid way: Professional educator resource. A WNCP professional development resource for educators. Retrieved from <a href="https://education.alberta.ca/media/563981/our-way-is-a-valid-way.pdf" target="_blank">https://education.alberta.ca/media/563981/our-way-is-a-valid-way.pdf</a></div>
<div class="reference">Wiggins, G., & McTighe, J. (1998). Understanding by design. Alexandria, VA: Association for Supervision and Curriculum Development.</div>
<div class="reference">Wiles, J., & Bondi, J. (2005). Curriculum development: A guide to practice, 2nd ed. Columbus, OH: C.E. Merrill Publishing Co.</div>
<div class="reference">Wingo, G. M. (1974). Philosophy of education: An introduction. Lexington: D.C. Health.</div>
<div class="reference">Zais, R. S. (1976). Curriculum principles and foundations. New York: Thomas Y. Crowell.</div>
