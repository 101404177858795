import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { OldCurriculum } from './old-curriculum.actions';
import { IPos, OldCurriculumService } from '@services/old-curriculum.service';
import { combineLatest } from 'rxjs';

export class OldCurriculumStateModel {
    programs: IPos[];
}

const defaults: OldCurriculumStateModel = {
    programs: []
};

@State<OldCurriculumStateModel>({
    name: 'oldCurriculum',
    defaults: defaults
})
@Injectable()
export class OldCurriculumState {
    constructor(private oldCurriculumService: OldCurriculumService) {

    }

    @Action(OldCurriculum.Load)
    onLoad(ctx: StateContext<OldCurriculumStateModel>, action: OldCurriculum.Load) {
        const state = ctx.getState();
        if (state.programs.length > 0) {
            return;
        }

        return combineLatest([
            this.oldCurriculumService.getArt('FARART'),
            this.oldCurriculumService.get('LANENG'),
            this.oldCurriculumService.get('OLAFLA'),
            this.oldCurriculumService.get('LANFRA'),
            this.oldCurriculumService.get('MAT'),
            this.oldCurriculumService.get('PDE'),
            this.oldCurriculumService.get('SCI'),
            this.oldCurriculumService.get('SSS'),
            this.oldCurriculumService.get('CALM'),
        ]).pipe(
            map(([farart, laneng, olafla, lanfra, mat, pde, sci, sss, calm]) => {
                ctx.patchState({ programs: [...farart, ...laneng, ...olafla, ...lanfra, ...mat, ...pde, ...sci, ...sss, ...calm] });
            })
        );
    }

    @Selector()
    static programs(state: OldCurriculumStateModel): IPos[] {
        return state.programs;
    }
}
