import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngxs/store';
import { IGeneralCurriculum } from '@state/compare.state';
import { FilterState, IFilters, ISelectedFilters, PanelType } from '@state/filter.state';

@Component({
  selector: 'curriculum-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
})
export class PanelComponent implements OnInit {
  @Input() filters: IFilters;
  @Input() selectedCurriculum: IGeneralCurriculum;
  @Input() selectedFilters: ISelectedFilters;
  @Input() isExpanded;
  @Input() panelType: PanelType;

  PanelType = PanelType;

  @Output() prevGrade = new EventEmitter<void>();
  @Output() nextGrade = new EventEmitter<void>();
  @Output() filterChange = new EventEmitter<ISelectedFilters>();

  nlaCurriculumOptions = [];

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.nlaCurriculumOptions = this.store.selectSnapshot(FilterState.nlaCurriculumOptions);
  }

  onPrev(): void {
    this.prevGrade.emit();
  }

  onNext(): void {
    this.nextGrade.emit();
  }

  onFilterChange(): void {
    this.filterChange.emit(this.selectedFilters);
  }

  getSubjectName(): string {
    if (!this.filters || !this.selectedFilters) {
      return '';
    }

    return this.filters.subjects.find((x) => x.id === this.selectedFilters.selectedSubjectId)?.name;
  }
}
