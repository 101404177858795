<header>
    <div class="name">
        <mat-form-field>
            <mat-label>Search</mat-label>
            <input matInput type="text" [(ngModel)]="searchTerm" />
        </mat-form-field>
        <!-- <mat-form-field>
            <mat-label>Curriculum</mat-label>
            <mat-select [(ngModel)]="selectedCurriculumId" (selectionChange)="onCurriculumChange($event)">
                <mat-option *ngFor="let curriculum of curriculumOptions" [value]="curriculum.id">
                    {{curriculum.name}}
                </mat-option>
            </mat-select>
        </mat-form-field> -->
    </div>
</header>
<div class="curriculum">
    <ng-container>
        <!-- <curriculum-old-grade *ngIf="selectedCurriculumId !== 'Draft20182021'" [program]="selectedOld" [selectedGrade]="selectedGrade"></curriculum-old-grade>
        <curriculum-draft-v2018-grade *ngIf="selectedCurriculumId !== 'Current2021'" [eus]="eus" [selectedGrade]="selectedGrade"></curriculum-draft-v2018-grade>
        <curriculum-new-grade *ngIf="selectedCurriculumId !== 'Current2018'" [course]="selectedNew" [organizingIdeas]="selectedOrganizingIdeas"
            [language]="selectedLanguage"></curriculum-new-grade> -->
    </ng-container>
</div>
<curriculum-footer></curriculum-footer>