import { Component, Input, OnInit } from '@angular/core';
import { ICourse, ICurriculum } from '@services/new-curriculum.service';

@Component({
    selector: 'curriculum-show-differences',
    templateUrl: './show-differences.component.html',
    styleUrls: ['./show-differences.component.scss']
})
export class ShowDifferencesComponent implements OnInit {
    @Input() sourceCourse: ICourse;
    @Input() sourceOrganizingIdeas: ICurriculum[] = [];

    @Input() targetCourse: ICourse;
    @Input() targetOrganizingIdeas: ICurriculum[] = [];

    constructor() { }

    ngOnInit(): void {
    }

}
