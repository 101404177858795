import { Component, Input, OnInit } from '@angular/core';
import { IEu, IGq } from '@services/draft-2018.service';

@Component({
    selector: 'curriculum-v2018',
    templateUrl: './v2018.component.html',
    styleUrls: ['./v2018.component.scss']
})
export class V2018Component implements OnInit {
    @Input() eus: IEu[] = [];
    @Input() selectedGradeId: string;
    @Input() subject: string;

    constructor() { }

    ngOnInit(): void {
    }

    getAsList(value: string): string {
        if (!value) {
            return "<ul><li>None identified</li></ul>";
        }

        const split = value.split('• ');
        const list = split.map(x => x ? `<li>${x}</li>` : '').join('');

        return `<ul>${list}</ul>`
    }

    getGqs(eu: IEu): IGq[] {
        if (!this.selectedGradeId || !eu) {
            return [];
        }

        return eu.gqs.filter(x => x.grade === this.selectedGradeId);
    }

    hasDataForGrade(): boolean {
        if (!this.selectedGradeId) {
            return false;
        }

        return this.selectedGradeId !== "5" && this.selectedGradeId !== "6";
    }
}
