import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { ICourse, ICurriculum, TypeCode } from '@services/new-curriculum.service';
import DiffMatchPatch from 'diff-match-patch';

@Component({
  selector: 'curriculum-v2021',
  templateUrl: './v2021.component.html',
  styleUrls: ['./v2021.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class V2021Component implements OnInit {
  @Input() course: ICourse;
  @Input() organizingIdeas: ICurriculum[] = [];
  @Input() subject: string;
  @Input() gradeId: String;
  @Input() month: string;
  @Input() year: string;

  @Input() name: string;
  @Input() diffName?: string;

  @Input() diffCourse?: ICourse;
  @Input() diffOrganizingIdeas?: ICurriculum[] = [];

  language = 'en-ca';
  linkLang = 'en';

  isShowingDiffs: boolean = false;
  hasDiffs: boolean = false;

  TypeCode = TypeCode;
  dmp = new DiffMatchPatch();

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.isShowingDiffs = this.diffCourse !== null && this.diffOrganizingIdeas !== null;
    this.hasDiffs = this.diffCourse !== null && this.diffOrganizingIdeas !== null;
  }

  hasDiff(leftVal: string, rightVal: string): boolean {
    return leftVal !== rightVal;
  }

  toggleDiff(event: any, item: any) {
    event.stopPropagation();
    event.preventDefault();
    item.isShowingDiff = !item.isShowingDiff;
    this.cdr.detectChanges();
  }

  trackByCode(ix: number, item: any) {
    return item.code;
  }

  getOis(): ICurriculum[] {
    let ois = this.organizingIdeas
      ? this.organizingIdeas.sort((x, y) => x.sortOrder - y.sortOrder)
      : [];

    if (this.isShowingDiffs && this.diffOrganizingIdeas && this.diffOrganizingIdeas.length > 0) {
      const removed = this.diffOrganizingIdeas
        .filter((x) => !this.organizingIdeas.some((y) => y.code === x.code))
        .map((x) => {
          return {
            ...x,
            contents: {
              'en-ca': `<span class="removed">${x.contents[this.language]}</span>`,
              'fr-ca': `<span class="removed">${x.contents[this.language]}</span>`,
            },
          };
        });

      if (removed?.length > 0) {
        ois = [...ois, ...removed];
      }

      for (let oi of ois) {
        if (this.diffOrganizingIdeas.find((x) => x.code === oi.code) === undefined) {
          oi.contents[this.language] = `<span class="added">${oi.contents[this.language]}</span>`;
        }
      }
    }

    return ois;
  }

  // <span class="ql-formula" data-value="\frac{1}{60}"
  getHtmlVal(html: Document): string {
    let qlFormulae = html.getElementsByClassName('ql-formula');
    for (let i = 0; i < qlFormulae.length; i++) {
      let formula = qlFormulae[i];
      formula.innerHTML = `$${formula.getAttribute('data-value')}$`;
    }
    return html.documentElement.innerText;
  }

  getDiff(leftVal: string, rightVal: string): any {
    if (!this.isShowingDiffs) {
      return rightVal ?? '<span></span>';
    }
    if (leftVal === null && rightVal) {
      return `<span class="added">${rightVal ?? '<span></span>'}</span>`;
    }
    if (rightVal === null && leftVal) {
      return `<span class="removed">${leftVal ?? '<span></span>'}</span>`;
    }

    // tag stripping regex: /<.*?>/ig
    const listEnd = /<\/li>/gi;

    const domParser = new DOMParser();
    const leftHtml = domParser.parseFromString(leftVal?.replace(listEnd, ' </li>'), 'text/html');
    const rightHtml = domParser.parseFromString(rightVal?.replace(listEnd, ' </li>'), 'text/html');

    const leftText = this.getHtmlVal(leftHtml);
    const rightText = this.getHtmlVal(rightHtml);

    // const leftText = leftHtml.documentElement.innerText;
    // const rightText = rightHtml.documentElement.innerText;

    const leftBracket = /</gi;
    const rightBracket = />/gi;

    if (leftText.trim() === rightText.trim()) {
      return rightVal ?? '<span></span>';
    }
    try {
      let result = '';
      const diffs = this.dmp.diff_main(leftText ?? '', rightText ?? '');
      this.dmp.diff_cleanupSemantic(diffs);

      for (let item of diffs) {
        const textVal = item[1]?.replace(leftBracket, '&lt;')?.replace(rightBracket, '&gt;');
        const className = item[0] === -1 ? 'removed' : item[0] === 1 ? 'added' : 'same';
        result += `<span class="${className}">${textVal}</span>`;
      }
      return result ?? '<span></span>'; // this.domSanitizer.bypassSecurityTrustHtml(result);
    } catch (exception: any) {
      return rightVal ?? '<span></span>'; //this.domSanitizer.bypassSecurityTrustHtml(rightVal);
    }
  }

  getGqs(oi: ICurriculum): ICurriculum[][] {
    return this.course
      ? this.course.curriculums
          .filter((x) => x.parentCurriculumCode === oi.code)
          .map((x) => {
            const matchingGq = this.diffCourse?.curriculums.find((y) => y.code === x.code);
            return [
              matchingGq,
              {
                ...x,
                isShowingDiff: true,
              },
            ];
          })
          .sort((x, y) => x[1].sortOrder - y[1].sortOrder)
      : [];
  }

  getChildrenWithMatches(parentOld: ICurriculum, parentNew: ICurriculum): ICurriculum[][] {
    return [
      ...parentNew.curriculums.map((x) => {
        const match = parentOld?.curriculums.find((y) => y.code === x.code);
        return [match, x];
      }),
    ].sort((x, y) => x[1].sortOrder - y[1].sortOrder);
  }

  getChildren(parent: ICurriculum): ICurriculum[] {
    return [
      ...parent.curriculums.map((x) => {
        const matchingCurriculums = this.diffCourse?.curriculums.find((y) => y.code === x.code);
        return {
          ...x,
        };
      }),
    ].sort((x, y) => x.sortOrder - y.sortOrder);
  }

  getByTypeCode(lo: ICurriculum, typeCode: TypeCode): ICurriculum {
    return lo?.curriculums?.find((x) => x.typeCode === typeCode);
  }

  onLangChange(event: MatSelectChange): void {
    this.language = event.value;
    this.linkLang = event.value === 'en-ca' ? 'en' : 'fr';
    this.cdr.detectChanges();
  }
}
