<h2 mat-dialog-title>About</h2>
<mat-dialog-content>
  This website was generated with data from the 2021 Draft Curriculum as of Apr 4, 2021, Dec 21,
  2021. The Apr 2022 Pilot curriculum was added on Apr 11, 2022. Please verify against source
  material whenever validating errors as some could have occurred as a result of transcription.
  <h3>Sources:</h3>
  <ul>
    <li>
      <a href="https://curriculum.learnalberta.ca/curriculum/en" target="_blank"
        >2022 Pilot Curriculum</a
      >
    </li>
    <li>
      <a href="assets/2018%20Draft-K-4-Curriculum.pdf" target="_blank">2018 Draft Curriculum</a>
    </li>
    <li>
      <a href="http://www.learnalberta.ca/programsofstudy.aspx" target="_blank"
        >Alberta Programs of Study</a
      >
    </li>
    <li>
      <a href="https://open.alberta.ca/publications/9781460130971#summary" target="_blank"
        >The 2016-2017 Guiding Framework</a
      >
    </li>
    <li>
      <a
        href="https://open.alberta.ca/publications/guiding-framework-design-development-k-12-curriculum"
        target="_blank"
        >The 2020 Guiding Framework</a
      >
    </li>
    <li>
      <a href="https://www.edonline.sk.ca/webapps/moe-curriculum-BB5f208b6da4613/" target="_blank"
        >Saskatchewan Curriculum</a
      >
    </li>
    <li>
      <a href="https://curriculum.gov.bc.ca/curriculum" target="_blank"
        >British Columbia Curriculum</a
      >
    </li>
  </ul>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button [mat-dialog-close]="true">Close</button>
</mat-dialog-actions>
