<h4>{{ name }} - {{ subject }}</h4>
<div>
  <mat-form-field>
    <mat-label>Language</mat-label>
    <mat-select [(ngModel)]="language" (selectionChange)="onLangChange($event)">
      <mat-option [value]="'en-ca'"> English </mat-option>
      <mat-option [value]="'fr-ca'"> Français </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div *ngIf="hasDiffs" style="margin-bottom: 12px; padding: 5px">
  <mat-checkbox [(ngModel)]="isShowingDiffs">Show differences? </mat-checkbox>
  <div style="margin-left: 28px; font-size: 14px">
    * Uses google diff-match-patch and semantic cleanup to show potential differences between the
    <strong>{{ diffName }}</strong>
    and
    <strong>{{ name }}</strong> curriculum. Html formatting has been removed from blocks where
    differences are detected.
  </div>
  <div style="margin-top: 8px" *ngIf="isShowingDiffs">
    <div style="margin-bottom: 8px">
      <span class="removed">deleted text is highlighted in red</span>
    </div>
    <div>
      <span class="added">new text is highlighted in green</span>
    </div>
  </div>
</div>
<div class="new-grade" *ngIf="course">
  <ng-container *ngFor="let oi of getOis(); trackBy: trackByCode">
    <div class="oi">
      <div class="header">
        <small *ngIf="language === 'en-ca'">Organizing Idea</small>
        <small *ngIf="language === 'fr-ca'">IDÉE ORGANISATRICE</small>
        <a
          target="_blank"
          href="https://curriculum.learnalberta.ca/curriculum/{{ linkLang }}/c/{{
            oi.categoryCode
          }}{{ gradeId }}?code={{ oi.code }}"
        >
          <mat-icon>launch</mat-icon>
        </a>
      </div>
      <div [innerHTML]="oi.contents[language]"></div>
    </div>
    <ng-container *ngFor="let gq of getGqs(oi); trackBy: trackByCode">
      <div class="gq">
        <div class="header">
          <small *ngIf="language === 'en-ca'">Guiding Question</small>
          <small *ngIf="language === 'fr-ca'">QUESTION DIRECTRICE</small>
          <a
            target="_blank"
            href="https://curriculum.learnalberta.ca/curriculum/{{ linkLang }}/c/{{
              gq[1].categoryCode
            }}{{ gradeId }}?code={{ gq[1].code }}"
          >
            <mat-icon>launch</mat-icon>
          </a>
        </div>
        <div *ngIf="!isShowingDiffs" [innerHTML]="gq[1].contents[language]"></div>
        <div
          *ngIf="isShowingDiffs"
          [innerHTML]="getDiff(gq[0]?.contents[language], gq[1]?.contents[language])"
        ></div>
      </div>
      <ng-container *ngFor="let lo of getChildrenWithMatches(gq[0], gq[1]); trackBy: trackByCode">
        <div class="lo">
          <div class="header">
            <small *ngIf="language === 'en-ca'">Learning Outcome</small>
            <small *ngIf="language === 'fr-ca'">RÉSULTAT D’APPRENTISSAGE</small>
            <a
              target="_blank"
              href="https://curriculum.learnalberta.ca/curriculum/{{ linkLang }}/c/{{
                gq[1].categoryCode
              }}{{ gradeId }}?code={{ lo[1].code }}"
            >
              <mat-icon>launch</mat-icon>
            </a>
          </div>
          <div *ngIf="!isShowingDiffs" [innerHTML]="lo[1].contents[language]"></div>
          <div
            *ngIf="isShowingDiffs"
            [innerHTML]="getDiff(lo[0]?.contents[language], lo[1]?.contents[language])"
          ></div>
        </div>

        <div
          class="knowledge"
          *ngFor="let knowledges of getChildrenWithMatches(lo[0], lo[1]); trackBy: trackByCode"
        >
          <ng-container *ngIf="getByTypeCode(knowledges[1], TypeCode.knowledge) as knowledge">
            <div>
              <div class="header">
                <small *ngIf="language === 'en-ca'">Knowledge</small>
                <small *ngIf="language === 'fr-ca'">CONNAISSANCES</small>
                <a
                  target="_blank"
                  href="https://curriculum.learnalberta.ca/curriculum/{{ linkLang }}/c/{{
                    gq[1].categoryCode
                  }}{{ gradeId }}?code={{ knowledge.code }}"
                >
                  <mat-icon>launch</mat-icon>
                </a>
              </div>
              <ng-katex-html *ngIf="!isShowingDiffs" [html]="knowledge.contents[language]">
              </ng-katex-html>
              <ng-katex-html
                *ngIf="isShowingDiffs"
                [html]="
                  getDiff(
                    getByTypeCode(knowledges[0], TypeCode.knowledge)?.contents[language],
                    knowledge.contents[language]
                  )
                "
              ></ng-katex-html>
            </div>
          </ng-container>
          <ng-container
            *ngIf="getByTypeCode(knowledges[1], TypeCode.understanding) as understanding"
          >
            <div>
              <div class="header">
                <small *ngIf="language === 'en-ca'">Understanding</small>
                <small *ngIf="language === 'fr-ca'">COMPRÉHENSION</small>
                <a
                  target="_blank"
                  href="https://curriculum.learnalberta.ca/curriculum/{{ linkLang }}/c/{{
                    gq[1].categoryCode
                  }}{{ gradeId }}?code={{ understanding.code }}"
                >
                  <mat-icon>launch</mat-icon>
                </a>
              </div>
              <ng-katex-html *ngIf="!isShowingDiffs" [html]="understanding.contents[language]">
              </ng-katex-html>
              <div
                *ngIf="isShowingDiffs"
                [innerHTML]="
                  getDiff(
                    getByTypeCode(knowledges[0], TypeCode.understanding)?.contents[language],
                    understanding.contents[language]
                  )
                "
              ></div>
            </div>
          </ng-container>
          <ng-container
            *ngIf="
              getByTypeCode(knowledges[1], TypeCode.skillsAndProcedures) as skillsAndProcedures
            "
          >
            <div>
              <div class="header">
                <small *ngIf="language === 'en-ca'">Skills & Procedures</small>
                <small *ngIf="language === 'fr-ca'">HABILETÉS ET PROCÉDURES</small>
                <a
                  target="_blank"
                  href="https://curriculum.learnalberta.ca/curriculum/{{ linkLang }}/c/{{
                    gq[1].categoryCode
                  }}{{ gradeId }}?code={{ skillsAndProcedures.code }}"
                >
                  <mat-icon>launch</mat-icon>
                </a>
              </div>
              <ng-katex-html
                *ngIf="!isShowingDiffs"
                [html]="skillsAndProcedures.contents[language]"
              >
              </ng-katex-html>
              <div
                *ngIf="isShowingDiffs"
                [innerHTML]="
                  getDiff(
                    getByTypeCode(knowledges[0], TypeCode.skillsAndProcedures)?.contents[language],
                    skillsAndProcedures.contents[language]
                  )
                "
              ></div>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
