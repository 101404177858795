import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { CompareState, IGeneralCurriculum } from '@state/compare.state';
import { Filter } from '@state/filter.actions';
import { FilterState, IFilters, ISelectedFilters, PanelType } from '@state/filter.state';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'curriculum-compare-container',
    template: `<curriculum-compare 
        [leftFilters]="leftFilters$ | async" 
        [selectedLeftCurriculum]="selectedLeftCurriculum$ | async" 
        [selectedLeftFilters]="selectedLeftFilters$ | async"
        [rightFilters]="rightFilters$ | async" 
        [selectedRightCurriculum]="selectedRightCurriculum$ | async" 
        [selectedRightFilters]="selectedRightFilters$ | async" 
    >
    </curriculum-compare>`,
})
export class CompareContainerComponent implements OnInit {
    @Select(FilterState.leftFilters) leftFilters$: Observable<IFilters>;
    @Select(CompareState.selectedLeft) selectedLeftCurriculum$: Observable<IGeneralCurriculum>;
    @Select(FilterState.selectedLeft) selectedLeftFilters$: Observable<ISelectedFilters>;

    @Select(FilterState.rightFilters) rightFilters$: Observable<IFilters>;
    @Select(CompareState.selectedRight) selectedRightCurriculum$: Observable<IGeneralCurriculum>;
    @Select(FilterState.selectedRight) selectedRightFilters$: Observable<ISelectedFilters>;

    constructor(private route: ActivatedRoute, private store: Store) { }

    ngOnInit(): void {
        const params = this.route.snapshot.params;

        let selectedLeft = this.store.selectSnapshot(FilterState.selectedLeft);
        let selectedRight = this.store.selectSnapshot(FilterState.selectedRight);

        if (params.gid) {
            selectedLeft.selectedGradeId = params.gid;
            selectedRight.selectedGradeId = params.gid;
        }

        if (params.sid) {
            selectedLeft.selectedSubjectId = params.sid;
            selectedRight.selectedSubjectId = params.sid;
        }

        if (params.lid) {
            selectedLeft.selectedCurriculumId = params.lid;
        }

        if (params.rid) {
            selectedRight.selectedCurriculumId = params.rid;
        }

        if (params.cid) {
            switch (params.cid) {
                case 'Current2021':
                    selectedLeft.selectedCurriculumId = 'Current';
                    selectedRight.selectedCurriculumId = '2021';
                    break;
                case 'Current2018':
                    selectedLeft.selectedCurriculumId = 'Current';
                    selectedRight.selectedCurriculumId = '2018';
                    break;
                case 'Draft20182021':
                    selectedLeft.selectedCurriculumId = '2018';
                    selectedRight.selectedCurriculumId = '2021';
                    break;
                default:
                    break;
            }
        }

        if (params.filters) {
            const split = params.filters.split('|');
            this.store.dispatch([
                new Filter.Update(JSON.parse(split[0]), PanelType.Left, false),
                new Filter.Update(JSON.parse(split[1]), PanelType.Right, false),
            ])
        } else {
            this.store.dispatch([
                new Filter.Update(selectedLeft, PanelType.Left, false),
                new Filter.Update(selectedRight, PanelType.Right, false)
            ]).pipe(map(() => {
                this.store.dispatch([new Filter.UpdateUrl(true)]);
            })).subscribe();
        }
    }
}

@Component({
    selector: 'curriculum-compare',
    templateUrl: './compare.component.html',
    styleUrls: ['./compare.component.scss'],
})
export class CompareComponent implements OnInit, OnDestroy {
    @Input() leftFilters: IFilters;
    @Input() selectedLeftCurriculum: IGeneralCurriculum;
    @Input() selectedLeftFilters: ISelectedFilters;

    @Input() rightFilters: IFilters;
    @Input() selectedRightCurriculum: IGeneralCurriculum;
    @Input() selectedRightFilters: ISelectedFilters;

    PanelType = PanelType;
    isExpanded = true;

    mediaQueryList: MediaQueryList;

    constructor(private store: Store) { }

    ngOnInit(): void {
        // break point for expand and collapse is 1024 px...
        const mediaQuery = '(max-width: 1024px)';
        this.mediaQueryList = window.matchMedia(mediaQuery);
        this.mediaQueryList.addEventListener('change', e => this.onMediaWidthChangeEvent(e));
    }

    ngOnDestroy(): void {
        this.mediaQueryList.removeEventListener('change', e => this.onMediaWidthChangeEvent(e));
    }

    onMediaWidthChangeEvent(event: MediaQueryListEvent): void {
        if (!event.matches) {
            this.isExpanded = true;
        } else {
            this.isExpanded = false;
        }
    }

    onPrev(panelType: PanelType): void {
        this.store.dispatch([new Filter.PrevGrade(panelType)]);
    }

    onNext(panelType: PanelType): void {
        this.store.dispatch([new Filter.NextGrade(panelType)]);
    }

    onSelectionChange(panelType: PanelType): void {
        if (panelType === PanelType.Left) {
            this.store.dispatch([new Filter.Update(this.selectedLeftFilters, panelType)]);
        } else {
            this.store.dispatch([new Filter.Update(this.selectedRightFilters, panelType)]);
        }
    }
}
