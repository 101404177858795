import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CompareContainerComponent } from './compare/compare.component';
// import { SaskComponent, SaskContainerComponent } from './convert/sask/sask.component';
// import { SearchContainerComponent } from './search/search.component';

const routes: Routes = [
    { path: 'compare/:sid/:gid/:lid/:rid', component: CompareContainerComponent },
    { path: 'compare/:sid/:gid/:cid', component: CompareContainerComponent },
    { path: 'compare/:sid/:gid', component: CompareContainerComponent },
    { path: 'compare/:filters', component: CompareContainerComponent },
    { path: 'compare', component: CompareContainerComponent },
    // { path: 'search', component: SearchContainerComponent },
    // { path: 'convert/sask', component: SaskContainerComponent },
    { path: '', redirectTo: '/compare', pathMatch: 'full' },
    { path: '**', component: CompareContainerComponent },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }