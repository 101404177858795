import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BcCurriculumService {
    constructor(private http: HttpClient) { }

    get(): Observable<IBcCategory[]> {
        return this.http.get<IBcCategory[]>('/assets/bc041121.json');
    }
}

export interface IBcCategory {
    categoryCode: string;
    intendedGradeLevel: string;
    url: string;
    coreCompetencies: IBcCoreCompetency[];
    bigIdeas: IBcBigIdea[],
    curriculumCompetencies: IBcCurriculumCompetencySection[];
    content: IBcContent[];
}

export interface IBcBigIdea {
    name: string;
    elaborations: string;
    defs: string[];
}

export interface IBcCoreCompetency {
    name: string;
    colour: string;
}

export interface IBcCurriculumCompetencySection {
    section: string;
    elaborations: string;
    isElaborationVisible: boolean;
    competencies: IBcCurriculumCompetency[];
}

export interface IBcCurriculumCompetency {
    values: IBcElaborativeValue[];
}

export interface IBcElaborativeValue {
    name: string;
    elaborations: string;
    isElaborationVisible: boolean;
    content: IBcContent[];
}

export interface IBcContent {
    values: IBcElaborativeValue[];
}