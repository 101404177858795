import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { SaskCurriculum } from './sask-curriculum.actions';
import { ISaskCategory, SaskCurriculumService } from '@services/sask-curriculum.service';

export class SaskCurriculumStateModel {
    categories: ISaskCategory[];
}

const defaults: SaskCurriculumStateModel = {
    categories: [],
};

@State<SaskCurriculumStateModel>({
    name: 'saskCurriculum',
    defaults: defaults
})
@Injectable()
export class SaskCurriculumState {
    constructor(private saskCurriculumService: SaskCurriculumService) {

    }

    @Action(SaskCurriculum.Load)
    onLoad(ctx: StateContext<SaskCurriculumStateModel>, action: SaskCurriculum.Load) {
        const state = ctx.getState();
        if (state.categories.length > 0) {
            return;
        }

        return this.saskCurriculumService.get().pipe(
            map(results => {
                ctx.patchState({
                    categories: results
                });
            })
        );
    }

    @Selector()
    static categories(state: SaskCurriculumStateModel): ISaskCategory[] {
        return state.categories;
    }
}
