import { Component, Input, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { FilterState, IFilters } from '@state/filter.state';
import { ISaskCategory } from '@services/sask-curriculum.service';
import { Observable } from 'rxjs';


@Component({
    selector: 'curriculum-convert-sask-container',
    template: `<curriculum-convert-sask [filters]="leftFilters$ | async"></curriculum-convert-sask>`,
})
export class ConvertSaskContainerComponent {
    @Select(FilterState.leftFilters) leftFilters$: Observable<IFilters>;
}

@Component({
    selector: 'curriculum-convert-sask',
    templateUrl: './convert-sask.component.html',
    styleUrls: ['./convert-sask.component.scss']
})
export class ConvertSaskComponent implements OnInit {
    @Input() filters: IFilters;
    input: string;
    output: string;

    categoryCode: string;
    grade: string;

    results: ISaskCategory[] = [];

    constructor() { }

    ngOnInit(): void {
    }

    onConvert(): void {
        let category = this.results.find(x => x.categoryCode === this.categoryCode);
        if (category === undefined) {
            category = {
                'categoryCode': this.categoryCode,
                strands: []
            };
            this.results.push(category);
        }

        const strandNames = ['strand', 'goal'];
        let strandIx = -1;
        const csvLines = this.input.split('\n');
        for (let csvLine of csvLines) {
            const splitTabs = csvLine.split('\t');
            for (let splitTab of splitTabs) {
                const items = splitTab.split('|');
                if (strandNames.find(x => x === items[0].toLocaleLowerCase())) {
                    strandIx = category.strands.findIndex(s => s.name === items[1]);
                    if (strandIx < 0) {
                        category.strands.push({
                            name: items[1],
                            content: '',
                            outcomes: []
                        });

                        strandIx = category.strands.length - 1;
                    }
                }

                if (items[0].toLocaleLowerCase() === 'outcome') {
                    category.strands[strandIx].outcomes.push({
                        intendedGradeLevel: this.grade,
                        content: items.slice(1).map(x => x.trim()).join(' '),
                        indicators: [],
                        indicatorNote: '',
                        note: '',
                        postIndicatorNote: '',
                        category: '',
                        url: ''
                    });
                }
            }
        }

        this.output = JSON.stringify(this.results);
    }
}
