import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AboutComponent } from '@components/about/about.component';
import { Filter } from '@state/filter.actions';
import { Store } from '@ngxs/store';

@Component({
    selector: 'curriculum-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    constructor(private store: Store, private dialog: MatDialog) { }

    ngOnInit(): void {
    }

    onAbout(): void {
        this.dialog.open(AboutComponent, {});
    }

    onBrowse(): void {
        // this.store.dispatch([new Filter.Select(undefined, undefined, undefined)])
    }
}
