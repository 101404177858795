<div class="content">
  <header>
    <button mat-mini-fab class="grade-btn" *ngIf="filters.grades.length > 0" (click)="onPrev()">
      <mat-icon>chevron_left</mat-icon>
    </button>
    <div
      class="filters collapsed panel-type-{{ panelType }}"
      *ngIf="!isExpanded"
      [ngClass]="{
        'is-gf':
          selectedFilters.selectedCurriculumId === 'gf2016' ||
          selectedFilters.selectedCurriculumId === 'gf2020'
      }"
    >
      <mat-form-field class="select panel-type-{{ panelType }}" *ngIf="filters.grades.length === 0">
        <mat-label>Curriculum</mat-label>
        <mat-select
          [(ngModel)]="selectedFilters.selectedCurriculumId"
          (selectionChange)="onFilterChange()"
        >
          <mat-option *ngFor="let curriculum of filters.curriculumOptions" [value]="curriculum.id">
            {{ curriculum.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="select panel-type-{{ panelType }}" *ngIf="filters.grades.length > 0">
        <mat-label>Grade</mat-label>
        <mat-select
          [(ngModel)]="selectedFilters.selectedGradeId"
          (selectionChange)="onFilterChange()"
        >
          <mat-option *ngFor="let grade of filters.grades" [value]="grade.id">
            {{ grade.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div
      class="filters panel-type-{{ panelType }}"
      *ngIf="isExpanded"
      [ngClass]="{
        'is-gf':
          selectedFilters.selectedCurriculumId === 'gf2016' ||
          selectedFilters.selectedCurriculumId === 'gf2020'
      }"
    >
      <mat-form-field class="select panel-type-{{ panelType }}">
        <mat-label>Curriculum</mat-label>
        <mat-select
          [(ngModel)]="selectedFilters.selectedCurriculumId"
          (selectionChange)="onFilterChange()"
        >
          <mat-option *ngFor="let curriculum of filters.curriculumOptions" [value]="curriculum.id">
            {{ curriculum.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="select panel-type-{{ panelType }}">
        <mat-label>Grade</mat-label>
        <mat-select
          [disabled]="filters.grades.length <= 0"
          [(ngModel)]="selectedFilters.selectedGradeId"
          (selectionChange)="onFilterChange()"
        >
          <mat-option *ngFor="let grade of filters.grades" [value]="grade.id">
            {{ grade.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="select panel-type-{{ panelType }}">
        <mat-label>Subject</mat-label>
        <mat-select
          [disabled]="filters.subjects.length <= 0"
          [(ngModel)]="selectedFilters.selectedSubjectId"
          (selectionChange)="onFilterChange()"
        >
          <mat-option *ngFor="let subject of filters.subjects" [value]="subject.id">
            {{ subject.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <button mat-mini-fab class="grade-btn" *ngIf="filters.grades.length > 0" (click)="onNext()">
      <mat-icon>chevron_right</mat-icon>
    </button>
  </header>
  <div class="curriculum">
    <div *ngIf="selectedCurriculum">
      <curriculum-current
        *ngIf="selectedCurriculum.albertaCurrent"
        [program]="selectedCurriculum.albertaCurrent"
        [subject]="getSubjectName()"
      >
      </curriculum-current>
      <curriculum-v2018
        *ngIf="selectedCurriculum.albertaDraft2018"
        [eus]="selectedCurriculum.albertaDraft2018"
        [subject]="getSubjectName()"
        [selectedGradeId]="selectedFilters.selectedGradeId"
      >
      </curriculum-v2018>

      <ng-container *ngFor="let option of nlaCurriculumOptions">
        <curriculum-v2021
          [id]="option.id"
          [name]="option.name"
          [diffName]="option.diffName"
          [hasDiff]="option.hasDiff"
          *ngIf="selectedFilters.selectedCurriculumId === option.id"
          [gradeId]="selectedFilters.selectedGradeId"
          [course]="selectedCurriculum[option.curriculumName].course"
          [organizingIdeas]="selectedCurriculum[option.curriculumName].curriculum"
          [diffCourse]="
            option.diffCurriculumName ? selectedCurriculum[option.diffCurriculumName].course : null
          "
          [diffOrganizingIdeas]="
            option.diffCurriculumName
              ? selectedCurriculum[option.diffCurriculumName].curriculum
              : null
          "
          [subject]="getSubjectName()"
        >
        </curriculum-v2021>
      </ng-container>

      <curriculum-gf2016 *ngIf="selectedFilters.selectedCurriculumId === 'gf2016'">
      </curriculum-gf2016>
      <curriculum-gf2020 *ngIf="selectedFilters.selectedCurriculumId === 'gf2020'">
      </curriculum-gf2020>
      <curriculum-sask
        *ngIf="selectedCurriculum.sask"
        [selectedGradeId]="selectedFilters.selectedGradeId"
        [subject]="getSubjectName()"
        [category]="selectedCurriculum.sask"
      ></curriculum-sask>
      <curriculum-bc
        *ngIf="selectedCurriculum.bc"
        [category]="selectedCurriculum.bc"
        [subject]="getSubjectName()"
      ></curriculum-bc>
    </div>
  </div>
</div>
