<main>
    <div>
        <h3>input</h3>
        <mat-form-field class="select">
            <mat-label>Grade</mat-label>
            <mat-select [(ngModel)]="grade">
                <mat-option *ngFor="let grade of filters.grades" [value]="grade.id">
                    {{grade.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <br />
        <mat-form-field class="select">
            <mat-label>Subject</mat-label>
            <mat-select [(ngModel)]="categoryCode">
                <mat-option *ngFor="let subject of filters.subjects" [value]="subject.id">
                    {{subject.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <br />
        <textarea [(ngModel)]="input" name="" id="" cols="90" rows="50"></textarea>
    </div>
    <div class="actions">
        <button (click)="onConvert()">Convert</button>
    </div>
    <div>
        <h3>output</h3>
        <textarea [(ngModel)]="output" name="" id="" cols="90" rows="50"></textarea>
    </div>
</main>