import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';

import { NgxsModule } from '@ngxs/store';
import { NgxsRouterPluginModule } from "@ngxs/router-plugin";

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { CompareComponent, CompareContainerComponent } from './compare/compare.component';
import { V2021Component } from './compare/v2021/v2021.component';
import { NewCurriculumState } from '../state/new-curriculum.state';
import { environment } from '../environments/environment';
import { OldCurriculumState } from '@state/old-curriculum.state';
import { FilterState } from '@state/filter.state';
import { CompareState } from '@state/compare.state';
import { AboutComponent } from './about/about.component';
import { Draft2018State } from '@state/draft-2018.state';
import { HighlightPipe } from './pipes/highlight.pipe';
import { SearchComponent, SearchContainerComponent } from './search/search.component';
import { FooterComponent } from './footer/footer.component';
import { V2018Component } from './compare/v2018/v2018.component';
import { CurrentComponent } from './compare/current/current.component';
import { Gf2020Component } from './compare/gf2020/gf2020.component';
import { Gf2016Component } from './compare/gf2016/gf2016.component';
import { ConvertSaskComponent, ConvertSaskContainerComponent } from './convert/sask/convert-sask.component';
import { PanelComponent } from './compare/panel/panel.component';
import { SaskCurriculumState } from '@state/sask-curriculum.state';
import { SaskComponent } from './compare/sask/sask.component';
import { BcCurriculumState } from '@state/bc-curriculum.state';
import { BcComponent } from './compare/bc/bc.component';
import { SafePipe } from './pipes/safe.pipe';
import { ElaborableComponent } from './compare/elaborable/elaborable.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { KatexModule } from 'ng-katex';
import { ShowDifferencesComponent } from './show-differences/show-differences.component';

@NgModule({
    declarations: [
        AppComponent,
        CompareContainerComponent,
        CompareComponent,
        CurrentComponent,
        V2021Component,
        AboutComponent,
        V2018Component,
        HighlightPipe,
        SearchContainerComponent,
        SearchComponent,
        FooterComponent,
        Gf2020Component,
        Gf2016Component,
        ConvertSaskContainerComponent,
        ConvertSaskComponent,
        PanelComponent,
        SaskComponent,
        BcComponent,
        SafePipe,
        ElaborableComponent,
        ShowDifferencesComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        MatFormFieldModule,
        MatSelectModule,
        MatIconModule,
        MatDialogModule,
        MatButtonModule,
        MatInputModule,
        MatCheckboxModule,
        MatTooltipModule,
        NgxsRouterPluginModule.forRoot(),
        NgxsModule.forRoot([NewCurriculumState, OldCurriculumState, FilterState, CompareState, Draft2018State, SaskCurriculumState, BcCurriculumState], {
            developmentMode: !environment.production
        }),
        KatexModule,
    ],
    providers: [SafePipe],
    bootstrap: [AppComponent],
})
export class AppModule { }
