import { ISelectedFilters, PanelType } from "./filter.state";

export namespace Filter {
    export class Update {
        static readonly type = '[Filter] Update';
        constructor(public selectedFilters: ISelectedFilters, public panelType: PanelType, public isUpdatingUrl: boolean = true) { }
    }

    export class NextGrade {
        static readonly type = '[Filter] NextGrade';
        constructor(public panelType: PanelType) { }
    }

    export class PrevGrade {
        static readonly type = '[Filter] PrevGrade';
        constructor(public panelType: PanelType) { }
    }

    export class UpdateUrl {
        static readonly type = '[Filter] UpdateUrl';
        constructor(public isReplacingHistory: boolean = false) { }
    }
}