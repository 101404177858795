import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { BcCurriculumService, IBcCategory } from '@services/bc-curriculum.service';
import { BcCurriculum } from './bc-curriculum.actions';

export class BcCurriculumStateModel {
    categories: IBcCategory[];
}

const defaults: BcCurriculumStateModel = {
    categories: [],
};

@State<BcCurriculumStateModel>({
    name: 'bcCurriculum',
    defaults: defaults
})
@Injectable()
export class BcCurriculumState {
    constructor(private bcCurriculumService: BcCurriculumService) {

    }

    @Action(BcCurriculum.Load)
    onLoad(ctx: StateContext<BcCurriculumStateModel>, action: BcCurriculum.Load) {
        const state = ctx.getState();
        if (state.categories.length > 0) {
            return;
        }

        return this.bcCurriculumService.get().pipe(
            map(results => {
                ctx.patchState({
                    categories: results
                });
            })
        );
    }

    @Selector()
    static categories(state: BcCurriculumStateModel): IBcCategory[] {
        return state.categories;
    }
}
