import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
    providedIn: 'root'
})
export class OldCurriculumService {

    constructor(private http: HttpClient) { }

    get(categoryCode: string): Observable<IPos[]> {
        return combineLatest([
            this.http.get(`/assets/pos/${categoryCode}/${categoryCode}-K.html`, { responseType: 'text' as 'json' }),
            this.http.get(`/assets/pos/${categoryCode}/${categoryCode}-1.html`, { responseType: 'text' as 'json' }),
            this.http.get(`/assets/pos/${categoryCode}/${categoryCode}-2.html`, { responseType: 'text' as 'json' }),
            this.http.get(`/assets/pos/${categoryCode}/${categoryCode}-3.html`, { responseType: 'text' as 'json' }),
            this.http.get(`/assets/pos/${categoryCode}/${categoryCode}-4.html`, { responseType: 'text' as 'json' }),
            this.http.get(`/assets/pos/${categoryCode}/${categoryCode}-5.html`, { responseType: 'text' as 'json' }),
            this.http.get(`/assets/pos/${categoryCode}/${categoryCode}-6.html`, { responseType: 'text' as 'json' }),
        ]).pipe(
            map(([k, one, two, three, four, five, six]) => {
                return [
                    {
                        intendedGradeLevel: 'K',
                        categoryCode: categoryCode,
                        course: k
                    },
                    {
                        intendedGradeLevel: '1',
                        categoryCode: categoryCode,
                        course: one
                    },
                    {
                        intendedGradeLevel: '2',
                        categoryCode: categoryCode,
                        course: two
                    },
                    {
                        intendedGradeLevel: '3',
                        categoryCode: categoryCode,
                        course: three
                    },
                    {
                        intendedGradeLevel: '4',
                        categoryCode: categoryCode,
                        course: four
                    },
                    {
                        intendedGradeLevel: '5',
                        categoryCode: categoryCode,
                        course: five
                    },
                    {
                        intendedGradeLevel: '6',
                        categoryCode: categoryCode,
                        course: six
                    },
                ] as IPos[];
            })
        );
    }

    getArt(categoryCode: string): Observable<IPos[]> {
        return combineLatest([
            this.http.get(`/assets/pos/${categoryCode}/${categoryCode}-K.html`, { responseType: 'text' as 'json' }),
            this.http.get(`/assets/pos/${categoryCode}/${categoryCode}-1-2.html`, { responseType: 'text' as 'json' }),
            this.http.get(`/assets/pos/${categoryCode}/${categoryCode}-3-4.html`, { responseType: 'text' as 'json' }),
            this.http.get(`/assets/pos/${categoryCode}/${categoryCode}-5-6.html`, { responseType: 'text' as 'json' }),
        ]).pipe(
            map(([k, oneTwo, threeFour, fiveSix]) => {
                return [
                    {
                        intendedGradeLevel: 'K',
                        categoryCode: categoryCode,
                        course: k
                    },
                    {
                        intendedGradeLevel: '1',
                        categoryCode: categoryCode,
                        course: oneTwo
                    },
                    {
                        intendedGradeLevel: '2',
                        categoryCode: categoryCode,
                        course: oneTwo
                    },
                    {
                        intendedGradeLevel: '3',
                        categoryCode: categoryCode,
                        course: threeFour
                    },
                    {
                        intendedGradeLevel: '4',
                        categoryCode: categoryCode,
                        course: threeFour
                    },
                    {
                        intendedGradeLevel: '5',
                        categoryCode: categoryCode,
                        course: fiveSix
                    },
                    {
                        intendedGradeLevel: '6',
                        categoryCode: categoryCode,
                        course: fiveSix
                    },
                ] as IPos[];
            })
        );
    }
}

export interface IPos {
    intendedGradeLevel: string;
    categoryCode: string;
    course: string;
}
