import { Component, Input, OnInit } from '@angular/core';
import { IBcCategory } from '@services/bc-curriculum.service';

@Component({
    selector: 'curriculum-bc',
    templateUrl: './bc.component.html',
    styleUrls: ['./bc.component.scss']
})
export class BcComponent implements OnInit {
    @Input() category: IBcCategory;
    @Input() subject: string;

    isShowingContentElaborations = false;
    isShowingCompetencyElaborations = false;

    constructor() { }

    ngOnInit(): void {
    }

    hasDef(value: string): boolean {
        return value.indexOf('<def>') > -1;
    }

    getBigIdeaSplit(value: string): string[] {
        const results = [];
        let compoundDef = '';
        for (let word of value.split(' ')) {
            if (word.indexOf('<def>') > -1) {
                if (word.indexOf('</def>') > -1) {
                    results.push(word);
                } else {
                    compoundDef = word;
                }
            } else if (compoundDef.length > 0) {
                compoundDef += ` ${word}`
                if (word.indexOf('</def>') > -1) {
                    results.push(compoundDef);
                    compoundDef = '';
                }

            } else if (compoundDef === '') {
                results.push(word);
            }
        }

        return results;
    }

    areAllCompetencyElaborationsVisible(): boolean {
        let areAllVisible = true;
        for (const crossCurricular of this.category.curriculumCompetencies) {
            if (!!crossCurricular.elaborations) {
                areAllVisible = areAllVisible && crossCurricular.isElaborationVisible;
            }

            for (const competency of crossCurricular.competencies) {
                for (const value of competency.values) {
                    if (!!value.elaborations) {
                        areAllVisible = areAllVisible && value.isElaborationVisible;
                    }
                }
            }
        }

        return areAllVisible;
    }

    areAllContentElaborationsVisible(): boolean {
        let areAllVisible = true;
        for (const content of this.category.content) {
            for (const value of content.values) {
                if (!!value.elaborations) {
                    areAllVisible = areAllVisible && value.isElaborationVisible;
                }
                if (value.content) {
                    for (const content2 of value.content) {
                        for (const value2 of content2.values) {
                            if (!!value2.elaborations) {
                                areAllVisible = areAllVisible && value2.isElaborationVisible;
                            }
                        }
                    }
                }
            }
        }

        return areAllVisible;
    }

    onToggleCompetencyElaborations(): void {
        const areAllVisible = this.areAllCompetencyElaborationsVisible();
        this.category.curriculumCompetencies.forEach(x => x.isElaborationVisible = !areAllVisible);
        this.category.curriculumCompetencies.forEach(x => x.competencies.forEach(y => y.values.forEach(z => z.isElaborationVisible = !areAllVisible)));
    }

    onToggleContentElaborations(): void {
        const areAllVisible = this.areAllContentElaborationsVisible();
        this.category.content.forEach(x => x.values.forEach(y => y.isElaborationVisible = !areAllVisible));
        this.category.content.forEach(x => x.values.forEach(y => y.content ?
            y.content.forEach(z => z.values.forEach(a => a.isElaborationVisible = !areAllVisible))
            : null
        ));
    }
}
