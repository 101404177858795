import { Component, Input, OnInit } from '@angular/core';
import { IPos } from '@services/old-curriculum.service';

@Component({
    selector: 'curriculum-current',
    templateUrl: './current.component.html',
    styleUrls: ['./current.component.scss']
})
export class CurrentComponent implements OnInit {
    @Input() program: IPos;
    @Input() subject: string;

    constructor() { }

    ngOnInit(): void {
    }

}
