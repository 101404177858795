import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'curriculum-gf2016',
  templateUrl: './gf2016.component.html',
  styleUrls: ['./gf2016.component.scss']
})
export class Gf2016Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
