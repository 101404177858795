import { Component, Input, OnInit } from '@angular/core';
import { ISaskCategory, ISaskStrand, ISaskOutcome } from '@services/sask-curriculum.service';

@Component({
    selector: 'curriculum-sask',
    templateUrl: './sask.component.html',
    styleUrls: ['./sask.component.scss']
})
export class SaskComponent implements OnInit {
    @Input() category: ISaskCategory;
    @Input() selectedGradeId: string;
    @Input() subject: string;

    constructor() { }

    ngOnInit(): void {
    }

    getStrands(): ISaskStrand[] {
        return this.category.strands.filter(x => x.outcomes.some(y => y.intendedGradeLevel === this.selectedGradeId));
    }

    getLos(strand: ISaskStrand): ISaskOutcome[] {
        if (!strand) {
            return [];
        }

        return strand.outcomes.filter(x => x.intendedGradeLevel === this.selectedGradeId);
    }
}
