<h2 style="color: #333">The Guiding Framework for the Design and Development of Kindergarten to Grade 12 Provincial
    Curriculum - 2020</h2>

<h2>Foreword</h2>

<p>Alberta has a world-renowned tradition of educational excellence. This <em>Guiding Framework</em> builds on that
    strong
    history and applies the Ministerial Order on Student Learning in Alberta<sup>1</sup> in
    light of research into best practices
    and successful innovations from other world-leading jurisdictions. <em>The Guiding Framework</em> sets clear
    direction for
    developmentally appropriate curriculum of high academic standards for personal excellence. Together, The Guiding
    Framework and the curriculum are central elements of formal education in Alberta and the basis for students’
    learning and assessment.</p>
    <p class="footnote"><sup>1</sup> See the next section of this document for the Ministerial Order on Student Learning in full.</p>
<p>The curriculum will prepare students to know where they came from and how they got here. They will gain the
    essential knowledge and skills to shape their future with wisdom, prudence, and hope. All students deserve access to
    “the best that has been thought and said” (Arnold, 1999) throughout history in Alberta, in Canada, and in diverse
    traditions around the world. In a rapidly changing world, students need foundational knowledge more than ever. Every
    Alberta student will have the opportunity to graduate with the mathematics, sciences, and languages skills to lead a
    meaningful, fulfilling life and to succeed in a changing economy. By understanding our civic institutions and our
    history, students will be equipped to be responsible citizens living in healthy communities of responsible freedom
    and prosperity.</p>
<p>
    <em>The Guiding Framework</em> guides curriculum development. Curriculum is the content—what students learn. This
    learning
    includes what students should know and what students should be able to do. The facts and skills that students learn
    are interrelated. For example, research shows that when a child is coached to be able to write with clear grammar
    and logic, the skill of writing about substantive content reinforces long-term memory and clear thinking (Hochman,
    Wexler, & Lemov, 2017; Kirschner & Hendrick, 2020). There is a mutually beneficial relationship between what
    children do and what children know. The breadth and depth of thoughtfully sequenced learning that results in
    long-term memory are necessary for clear thinking and the potential for wisdom. Following the vision of the
    Ministerial Order, students will be introduced to a rich, interconnected knowledge base. Students will be practiced
    in free, lively, and respectful dialogue with varied viewpoints.</p>
    <p>
    One goal of <em>The Guiding Framework</em> is to provide transparent guidelines to help parents, educators, and
    curriculum
    developers understand curriculum development in Alberta. It describes the logical arrangement of the common
    components (architecture) and the relationships among the components (design) for all subjects. This will make it
    easier for parents and teachers to understand what is being taught and why it is being taught in each subject, and
    from grade to grade. Specific content is designed to create connections between subjects and a predictable
    progression of knowledge. Students will build a coherent understanding from grade to grade, even if they move
    between schools. With the foundation of factual knowledge that is necessary for critical thinking, students will
    learn to question assumptions with reason. Students will aim toward the knowledge, understanding, skills, and
    virtues that we value as human beings. With the opportunity to discover and develop their strengths, students will
    strive for and attain personal excellence. All students will attain, at minimum, a basic level of competence in all
    subjects.
</p>
<p>Where possible, <em>The Guiding Framework</em> avoids currently fashionable jargon and abstract language. It
    recommends that
    the curriculum be written without needless technical language so that the findings of the research can be more
    easily accessed and used by teachers and parents alike.<sup>2</sup>
    There is a focus on virtues and common humanity, which have a long history of intellectual meaning and social
    application. The focus is on what brings people together. Diverse backgrounds bring strength and unity. A goal of
    education in Alberta is to help us transcend differences and unite in a peaceful, pluralistic society that respects
    different beliefs, perspectives, experiences, and cultural and religious traditions. <em>The Guiding Framework</em>
    guides
    curriculum to affirm the dignity of every Albertan while highlighting the common goals that Albertans share.</p>
    <p class="footnote"><sup>2</sup> “Ensure curriculum is written in clear, concise, and unbiased language that enables consistent understanding and application” (Curriculum Advisory Panel, 2019, p. 7).</p>
<p>The curriculum will be inclusive from Kindergarten to Grade 12 (K–12) and incorporate a diverse range of insights,
    understandings and perspectives. It includes all Albertans, telling the historical stories of the Indigenous
    inhabitants of this land, the first Europeans to arrive here, and the peoples who have come from every inhabited
    continent on Earth to make a home and a society here. All students will see themselves in the curriculum in multiple
    ways. They will see themselves as complex and developing human beings. They will learn from the greatest works of
    art and science that transcend differences of time and culture, speaking to human hearts and minds regardless of
    differences. They will see themselves represented in a curriculum that presents a variety of perspectives and
    traditions, both similar and different to their own. Students should perpetually seek to both know themselves and
    understand their neighbours. Such knowledge can shine the light of truth on prejudice. Truth overcomes the ignorance
    and lack of empathy and imagination that lead to racism, bigotry, and intolerance, and which stand as a barrier to
    reconciliation with First Nations, Métis, and Inuit Canadians.</p>
<p>Education, when properly conducted, brings us together to learn the accumulated knowledge and wisdom of our society
    and diverse traditions. It asks us to build on that knowledge and understanding, so we may pass it on intact, but
    improved, to the next generation. Students in Alberta will pursue learning thoughtfully, cultivate virtues and
    skills, and gain essential knowledge to set them on the path for productive work and well-rounded personal and
    public lives. They will understand our debt to the past, learning from both the good and the bad of the generations
    that came before us. They will appreciate their obligation to the future to leave their children a flourishing
    society in a clean and sustainable environment. In this way, we will ensure that Alberta today and tomorrow is a
    place of freedom, security, equal opportunity, and welcoming hospitality.</p>
<div class="vision">
    <h2>Visions Statement (Ministerial Order on Student Learning)</h2>
    <p>Education in Alberta will promote the acquisition of skills and the pursuit of knowledge with wisdom, while
        valuing equality of opportunity, parental responsibility, personal responsibility and excellence, and respect
        for difference and the inherent dignity of each individual. Students will understand the rights and benefits of
        democratic citizenship and their personal and community responsibilities. Students will explore life
        opportunities that develop their unique talents and potential, provide a sense of purpose and belonging, and
        affirm the dignity of work. They will become life-long learners, who will cultivate the virtues of wisdom,
        courage, self-control, justice, charity, and hope.</p>
    <h4>Vision for Student Learning</h4>
    <p>Students will gain the knowledge and skills to form the foundations for successful and fulfilling lives, and make
        meaningful contributions to their communities and the world.</p>
    <h4>Foundations for Learning</h4>
    <p>Literacy and numeracy are the foundational building blocks of learning. They shall be pervasive across all
        subjects and grades and specifically taught using age-appropriate, complete texts of high quality in language
        classes and standard algorithms in mathematics. These foundations establish core knowledge, shared civic and
        cultural literacy, and skills that enable students to solve problems, think critically as they become active and
        informed citizens leading healthy lives of meaning.</p>
    <h4>Outcomes for Learning</h4>

    <p><strong>Knowledge Development</strong><br />Students will demonstrate mastery in foundational, subject-specific
        content, and a familiarity with and appreciation of the great works and ideas of world history, with an emphasis
        on the cultures and institutions that have shaped the history of Canada. The development of literacy and
        numeracy will be reinforced, enriched, and supplemented by the study of Alberta, Canadian, and world history,
        geography, mathematics, science, technology, philosophy, literature, languages, mental and physical wellness,
        and the arts, which shall be taught with specific factual content, quality original texts and sources where
        applicable, and measurable outcomes. Students will demonstrate competence in managing personal finance and
        applying their critical thinking to engaging with various media.</p>
    <p>Students will integrate the broad knowledge base of these subjects to learn to think for themselves, solve
        problems creatively, collaborate, and communicate effectively. Students will be encouraged to approach the world
        with intellectual curiosity and humility, understanding our inherited traditions, engaging new ideas and diverse
        viewpoints, questioning assumptions with reason, evaluating sources of information with discernment, and
        applying their learning in a variety of life and work situations.</p>
    <p><strong>Character Development</strong><br />Students develop honesty, integrity, and self-reliance through the
        application of their knowledge and skills. They recognize truth, beauty, and goodness through exposure to the
        best and most enduring art and writing across different time periods and places. They will demonstrate
        resilience and good judgement in a rapidly-changing world, building positive relationships and taking
        responsibility for their personal health and well-being. Students will demonstrate a commitment to the common
        good by exercising compassion, empathy, and support for each other in our diverse society.</p>
    <p><strong>Community Engagement</strong><br />Students will demonstrate an understanding of the needs, beliefs, and
        expectations of diverse communities, and identify connections that transcend difference. They will recognize the
        impact of their actions and demonstrate an understanding of our rights and freedoms, which are secured by
        Canadian law and reflect our shared history and traditions, as well as the social and organizational skills
        required for civic participation. Students will demonstrate an understanding of economic development and
        entrepreneurship, and will recognize the responsibility we share for environmental stewardship and
        sustainability.</p>
    <p>Students will demonstrate respect for others and an understanding of our political institutions as they prepare
        to participate knowledgably and responsibly in our parliamentary democracy and our constitutional Monarchy. They
        will understand Canadian and world history, our debt to previous generations, and our obligations to future
        generations. All students will see themselves, their families, and their communities in the curriculum, with
        space in the curriculum for the study of local traditions, history, and geography, including Alberta’s
        Francophone history. Students will develop an understanding of and respect for the histories, contributions, and
        perspectives of Indigenous peoples in Alberta and Canada, including Treaty Rights and the importance of
        reconciliation.</p>
    <h4>Conclusion</h4>
    <p>Parents deserve the best from those who help them educate their children. Students will be coached to develop
        intellectual ability and practical skills by caring and knowledgeable teachers. They will inherit a rich,
        interconnected knowledge base and be practiced in free, lively, and respectful dialogue with varied viewpoints.
    </p>
</div>
<h2>Overall Guidelines for Drafting the Curriculum</h2>
<p>The curriculum will</p>
<ol>
    <li>be consistent with the Ministerial Order on Student Learning</li>
    <li>require developmentally appropriate high academic standards</li>
    <li>support opportunities for students to reach their personal best</li>
    <li>have content that is logically sequenced within each grade and from grade to grade</li>
    <li>encourage students to examine a rich variety of ideas and viewpoints</li>
    <li>respectfully include First Nations, Métis, and Inuit histories, contributions, and perspectives (with explicit
        core knowledge about treaties and the history and legacy of residential schools, with age-appropriate content)
    </li>
    <li>respectfully include Francophone histories, contributions, and perspectives</li>
    <li>be developed in English and French</li>
    <li>be scientifically rigorous while respecting a variety of beliefs in our pluralistic society</li>
    <li>be developed in six subject areas: language arts and literature (English, Français, and French), mathematics,
        science, social studies, fine arts, and physical education and wellness</li>
    <li>reinforce essential knowledge and skills across subjects (for example, books read aloud in elementary language
        and literature arts can reinforce content knowledge in social studies and science)</li>
    <li>be clear and concise, avoiding specialized jargon so it is easily understandable by parents and teachers alike
    </li>
    <li>focus on content (curriculum), not teaching methods (pedagogy)</li>
    <li>have the following structure for all subjects:
        <ul>
            <li>organizing idea (overall subject-specific theme that may span multiple grades)</li>
            <li>guiding question (a question that leads to the learning outcome)</li>
            <li>learning outcome (what students must know, understand, and do by the end of grade)</li>
            <li>knowledge (what students know)</li>
            <li>understanding (how facts and knowledge fit together in a logical and meaningful order)</li>
            <li>skills and procedures (what students learn to do to demonstrate their knowledge and understanding)</li>
        </ul>
    </li>
    <li>identify opportunities where literacy and numeracy will be developed within and across subjects</li>
    <li>identify opportunities where competencies will be developed within and across subjects</li>
</ol>
<h2>Subject-specific Guidelines for K–12</h2>
<p>Each subject area has a body of knowledge that includes the facts, symbols, rules, principles, and concepts that
    constitute the subject area. Each subject also has skills, strategies, processes, steps, and systematic approaches
    that students use to demonstrate their knowledge and understanding of the subject. The following lists represent,
    but do not limit, the body of knowledge and skills for specific subject areas.</p>
<h3>Language Arts and Literature</h3>
<p>Students will learn</p>
<ul>
    <li>to apply phonics, spell words correctly, expand vocabulary, and read with fluency</li>
    <li>to comprehend (summarize, recall, synthesize, draw inferences) and interpret types of text</li>
    <li>the difference between fact and opinion and to evaluate, use, and record information ethically from a variety of
        sources</li>
    <li>to summarize, analyze, and discuss the arguments as presented by an author</li>
    <li>to view, appreciate, and analyze various texts and media</li>
    <li>to speak and present in various contexts through coaching for skillful public speaking</li>
    <li>to write in various genres for a variety of audiences</li>
    <li>the art of listening well and charitably (even to opposing views)</li>
    <li>to use socially and culturally appropriate verbal and non-verbal communication skills (e.g., respectful and
        inclusive dialogue, body language, tone, clarity of speech)</li>
    <li>to compose and revise their own texts with attention to correct spelling; vocabulary; punctuation; grammar; and
        sentence, paragraph, and essay or story structure for a variety of audiences</li>
    <li>to develop various techniques for adding elements of beauty and persuasion to their composition of texts with
        their audience in mind</li>
    <li>to appreciate a body of age-appropriate unabridged stories, fables, poetry, myths, oral traditions, plays,
        films, novels, and non-fiction texts that all children in Alberta will have in common, forming a foundation of
        cultural knowledge and a sense of historical continuity and change within the tradition of the language being
        studied</li>
    <li>to read a diversity of the greatest and most influential writers whose works show that we as humans have much in
        common that transcends differences and helps us find our own unique voice</li>
    <li>that texts from many traditions have shaped our society and the primary language being studied, including many
        texts brought in from other languages (e.g., <em>The Odyssey, Beowulf, Aesop’s Fables,</em> Indigenous legends)
    </li>
    <li>• that multiple perspectives can be provided though enduring traditional and classic works and contemporary
        texts where all children engage with complete texts from Anglophone, Indigenous, and Francophone traditions as
        well as global texts from Africa, the Americas, Asia, Europe, and the Middle East;<sup>3</sup></li>
    <li>to address racism, sexism, and other forms of bigotry by directly engaging with the greatest enduring works of
        diverse authors</li>
</ul>
<p class="footnote"><sup>3</sup> The approximately 30% of Albertans of non-European background in alphabetical order</p>
<h3>Mathematics</h3>
<p>Students will learn</p>
<ul>
    <li>age-appropriate factual knowledge, procedures, skills, and conceptual understanding to develop mathematical
        thinking</li>
    <li>computational skills, mathematical concepts, and logical reasoning</li>
    <li>standard algorithms for common mathematical operations</li>
    <li>to recall basic number facts, represented in addition and multiplication tables</li>
    <li>knowledge and skills necessary to develop financial literacy</li>
    <li>arithmetic</li>
    <li>geometry and trigonometry</li>
    <li>to communicate mathematical thinking through definitions, justifications, arguments, and basic proofs</li>
    <li>how a unit is essential in measurement and comparisons</li>
    <li>to interpret data and graphical representations</li>
    <li>to apply mathematical thinking to decision making</li>
    <li>to recognize, create, continue, and generalize patterns</li>
    <li>to create and use mathematical models</li>
    <li>the notation and functionality of the decimal number system</li>
    <li>proportionality through fractions, ratios, and rates</li>
    <li>discrete mathematics</li>
    <li>to interpret and analyze functions and their characteristics</li>
    <li>to solve equations</li>
    <li>to interpret probabilities and statistics in order to make informed predictions</li>
    <li>to apply mathematical knowledge and skills in solving more-complex problems</li>
</ul>
<h3>Science</h3>
<p>Students will learn</p>
<ul>
    <li>that the practice of science is iterative and involves refining and developing knowledge and skills as we gain
        new evidence of the natural world in a safe and responsible manner</li>
    <li>that the scientific method is the fundamental tool for using evidence based on careful observation and analysis
        to build explanatory theories of the natural world</li>
    <li>the basics necessary for a fundamental understanding of the natural world based on foundations in the
        overlapping disciplines of physics, chemistry, biology, Earth science, astronomy, and computing science</li>
    <li>that science is advanced by necessity, wonder, curiosity, creativity, and perseverance to obtain a deeper
        understanding of our natural world</li>
    <li>that scientific knowledge and skills are applied in areas such as engineering and design, technology, medicine,
        manufacturing, agriculture, robotics, social sciences, and space industry in ways that can continue to make life
        better</li>
    <li>classification systems (including the intersecting spheres of Earth science, taxonomies of life forms, the
        periodic table, states of matter, and planetary and celestial systems)</li>
    <li>environmental stewardship and sustainability, with specific factual content about the natural historic and
        prehistoric cycles of climate change</li>
    <li>scientific data on human impacts on the environment and climate, including global warming</li>
    <li>the history of scientific ideas and the stories of those whose discoveries resulted in changes to everyday life
        and understanding of the natural world and human-created systems</li>
    <li>computational thinking that builds core skills for logical precision, scientific investigation, coding,
        modelling, data processing, and automation</li>
    <li>the interrelationship of the primary methods of science (i.e., observation, experiment, computing, and theory)
        and to apply these methods and skills following best practices</li>
    <li>to be thoughtful about logical, ethical, and philosophical aspects of our limited human understanding as applied
        to past, present, and future challenges</li>
</ul>
<h3>Social Studies</h3>
<p>Students will learn</p>
<ul>
    <li>Alberta, Canada, and world geography</li>
    <li>the sequential history of Alberta, Canada, and the world from ancient to modern times, with core content
        coherently revisited as knowledge builds</li>
    <li>to understand how those in the past understood themselves and how that helps us know ourselves today, and how to
        engage respectfully with a variety of contrasting opinions</li>
    <li>about histories, contributions, and perspectives from local Indigenous and Francophone communities; Albertans of
        European, African, Asian, and Middle Eastern descent; and newcomers from various parts of the world</li>
    <li>to develop, analyze, and respectfully and charitably discuss opinions that are well-informed by a growing body
        of factual knowledge on controversial issues (historical and contemporary) using various forms of communication,
        including oral and written communication</li>
    <li>about civics, citizenship, and political systems, with an emphasis on the principles of Canadian constitutional
        monarchy, democratic government, and rule of law</li>
    <li>to understand the differences between the principles and practices of democratic and totalitarian governments,
        and the ways that equality, freedom, human rights, and the rule of law have been violated by totalitarian
        regimes and justified by their ideologies throughout history, including through the testimony of those who
        experienced modern genocides such as the Holocaust (age appropriate)</li>
    <li>about economics and economic systems, including principles, practices, and comparative successes and failures of
        free-market, mixed, and command economies</li>
    <li>financial literacy in every grade, including age-appropriate content on earning, investing, spending, borrowing,
        and financial security</li>
    <li>about world religions, philosophy, and belief systems, with an emphasis on reading age-appropriate foundational
        original texts that provide a basis for the origin of ideas, alternative ideas, and how ideas continue and
        change over time to become culturally literate and informed citizens</li>
    <li>about Alberta and Canada’s commitments to reconciliation with Indigenous peoples</li>
    <li>to do research using primary and secondary sources from a variety of perspectives</li>
    <li>about economic development and environmental stewardship and sustainability</li>
    <li>to address racism, sexism, and other forms of bigotry by directly engaging with the greatest enduring works of
        diverse authors and the study the history, civics, and economics</li>
    <li>about injustices and how they have been addressed in the past and may be addressed in the future</li>
</ul>
<h3>Physical Education and Wellness</h3>
<p>Students will learn</p>
<ul>
    <li>movement skills and sporting activities</li>
    <li>to actively participate in daily exercise through physical education (in sufficient quality and quantity
        according to the best scientific research)</li>
    <li>basic health practices, including regular physical activity, good nutrition, quality and quantity of sleep,
        mental health, and hygiene</li>
    <li>that consent is giving someone permission to act (e.g., sharing food, toys, or personal space) and that it
        applies to responsible and wise decision making related to personal health, safety, and positive relationships
        (with age-appropriate content)</li>
    <li>to cultivate the virtues of wisdom, courage, self-control, justice, charity, and hope</li>
    <li>to develop a better understanding of themselves that will allow them to make decisions, achieve goals, build
        resiliency, and adapt to change</li>
    <li>to build resilience and positive mental health skills for suicide prevention (when age-appropriate)</li>
    <li>to know the difference between and how to manage healthy stress and traumatic stress</li>
    <li>age-appropriate information about sexual health and the importance of consent, mutual respect, dignity, and
        responsibility</li>
    <li>the importance of financial management and its significant impact on health and family life</li>
    <li>to foster mental health and wellness skills and understanding, and to know when and where to access credible
        mental health resources and services</li>
    <li>the signs of unhealthy addiction, including to electronic devices and substances, and best practices for
        habituating the virtue of self-control, resisting unhealthy peer pressure, and making wise decisions for a
        healthy community</li>
    <li>how personal qualities, interests, gifts, and virtues contribute to a fulfilling life and career</li>
    <li>about entrepreneurship, work and employment strategies, and various future careers</li>
    <li>how community, relationships, networks, volunteerism, and work-life balance impact their life and career</li>
</ul>
<h3>Fine Arts</h3>
<p>Students will learn</p>
<ul>
    <li>the elements of the art, including artistic skills and techniques, in a logical and developmentally sequenced
        progression according to best practices</li>
    <li>to perform through the systematic development of skills and techniques in various arts through coaching and
        sustained practice</li>
    <li>to practise imitating, improvising, interpreting, and creating, based on the best examples of different art
        forms passed down to us through what is most aspirational in our cultural heritage</li>
    <li>appreciation for the masterworks of music, dance, drama, and visual art in a way that emphasizes the
        interconnectedness of the arts and their truth, goodness, and beauty</li>
    <li>the differences and similarities between popular and fine art</li>
    <li>how artistic styles change over time</li>
    <li>how dynamic traditions of artwork from the past influence current and future works of art</li>
    <li>how artworks contribute to the development and understanding of cultural heritage</li>
    <li>artistic characteristics that contribute to the inspirational and influential nature of great works of art
        throughout history</li>
    <li>the inherited traditions and historical influences that help us to understand great works of literature and art
        in the primary language of study, including the lifelong value of learning by heart</li>
    <li>respectful, complex, and nuanced portrayals of Anglophone, Francophone, Indigenous, and world traditions of the
        fine arts</li>
</ul>
<h2>Considerations for the Development of Alberta’s Kindergarten to Grade 12 Provincial Curriculum</h2>
<p>The K–12 curriculum is grounded in research-informed decisions regarding the knowledge, skills, and good character
    that will best prepare Alberta’s students to achieve successful and fulfilling lives while making meaningful
    contributions to their communities and the world. Alberta is well-known for an academically rigorous educational
    curriculum that is developmentally appropriate. The curriculum will continue to follow the best research in order to
    build on foundations shown to succeed in Alberta<sup>4</sup>
    and in other leading jurisdictions.</p>
    <p class="footnote"><sup>4</sup> Historically, international education scholars and other jurisdictions have recognized the strength of Alberta’s curriculum. They have attributed the success of Alberta students to programs of study that are specific about what students should know and be able to do, and the fact that detailed programs are supported by high-quality resources (Stewart, 2012).</p>
<p>The provincial curriculum is designed to inspire students to develop integrated understandings within a discipline
    and across disciplines. By learning a broad base of knowledge and skills, students learn to inquire and question
    assumptions while they build understanding based on true premises. Students will learn rigorous and up-to-date
    science while also respecting freedom of religion so as to not undermine faith and cultural traditions that are
    important to parents and consistent with virtue and knowledge.</p>
<p>A good curriculum must (1) provide core knowledge as a foundation for cultural and civic literacy as well as critical
    thinking, (2) be logically organized and sequenced, and (3) leave teachers free to decide how to teach in their
    particular context, using common themes and engaging narratives whenever possible. Students are more motivated to
    learn “when they value the subject and have a clear sense of purpose.” The seven “principles of learning” include
    the need for building horizontal connections across subjects<sup>5</sup>
    and acknowledge that “a key feature of learning is that complex knowledge structures are built up by organizing more
    basic pieces of knowledge in a hierarchical way. If well-constructed, such structures provide understanding that can
    transfer to new situations.”<sup>6</sup>
</p>
<p class="footnote"><sup>5</sup> Examples of horizontal connections include learning about the First World War in one class at the same time as studying the revolution in music and poetry and science that erupted at the same time; or learning about ancient Greek history at the same time as studying Greek contributions to mathematics, science, visual arts, literature, music, and sports.</p>
<p class="footnote"><sup>6</sup><span> See the Practitioner Guide for The Nature of Learning, <a href="http://www.oecd.org/education/ceri/50300814.pdf" target="_blank">http://www.oecd.org/education/ceri/50300814.pdf</a> (OECD, 2010).</span></p>
<h4 style="color: #333">Essential Core Knowledge for Critical Thinking and Civic and Cultural Literacy</h4>
<p>Essential (or core) knowledge consists of the global standards for foundations in mathematics and science, as
    well as
    the themes, stories, art, and cultural, political, and legal traditions that are central to a society. Literate
    and
    informed citizens know how to use modern research tools but do not have to stop to look up every statistic,
    literary
    reference, or historical claim while conversing, listening, or reading.<sup>7</sup>
    If students don’t have a basic knowledge of history, geography, science, fine arts, and other subjects, they
    don’t
    know what they don’t know. On the other hand, students with a broad knowledge base built up systematically over
    time
    are better equipped to conduct useful research to fill knowledge gaps for a lifetime of learning.</p>
    <p class="footnote"><sup>7</sup> ”You can only rely on being able to look something up when you know quite a bit about it to begin with. Being able to research something effectively is undoubtedly an important skill. But it is a skill that is dependent on broad knowledge” (Christodoulou, 2014, p. 68).</p>
<p>Education should provide this core knowledge to all students in order to provide equitable opportunities to all.
    No
    student should be denied access to the best shared scientific, literary, and cultural inheritance of the world.
    Examples from literature that have come into the English language, including references to the deep significance
    of
    the buffalo and the Trickster, “turning into a pumpkin,” “crying wolf,” “an Achilles heel,” or Romeo and Juliet,
    will not be fully understood if a student’s education does not include reading local Indigenous stories, classic
    fairy tales, Aesop’s Fables, Greek mythology, and Shakespeare. Some students may learn these things at home, but
    not
    all students have that opportunity. Thus, unless some quality specific core content is required at school, not
    all
    students will be able to take pleasure in understanding allusions in everyday speech, writing, or art.<sup>8</sup>
</p>
<p class="footnote"><sup>8</sup> The same applies to basic familiarity with core scientific concepts (e.g., the scientific method, evolution, Marie Curie’s experiments with radiation, Newton’s laws, relativity); math and statistics (e.g., basic algebraic formulas, geometry, theorems and algorithms, statistical significance, probability and risk); important historical figures (e.g., Nelson Mandela, Chief Crowchild, Rev. Martin Luther King Jr., Frederick Haultain, Queen Elizabeth I, Julius Caesar, Confucius); geographic features (e.g., the continents, main climatic regions, oceans, rivers, mountain ranges of the world, how ancient oceans and ice ages shaped our landscape); historic events (e.g., Indigenous history, the Peloponnesian War, the rise and fall of major world empires from Egypt to Rome to the major Chinese dynasties to the Spanish, French, and English global exploration and expansion, the horrors of the slave trade, the War of 1812, Confederation, the two world wars); countries and world capitals (e.g., their location, history, economies, and major architectural features like the Eiffel Tower, Taj Mahal, Chartres Cathedral, Angkor Wat, and the pyramids of Giza); great works of art, artists, music, and musicians, both older and more recent (e.g., Bill Reid, Georgia O’Keefe, Michelangelo, Buffy Sainte-Marie, Ella Fitzgerald, Glenn Gould, Mozart, Palestrina); and great thinkers and their ideas (Simone Weil, Frederick Douglass, Karl Marx, Edmund Burke, Maimonides, Avicenna, Socrates, Confucius).</p>

<p>Required foundational content ensures teachers are able to build on a core of shared references that foster a
    sense
    of inclusive belonging and a deep feeling of cross-cultural understanding and respect that overcomes ignorance.
    The
    curriculum sets out content that gives all students the opportunity to become culturally literate, while being
    inclusive of the very best of the diverse riches of knowledge of other times and places throughout history and
    around the world. Learning this essential content of language, mathematics, fine arts, sciences, sports,
    history,
    geography, philosophy, economics, and religions will enable students to fully participate in the complexity of
    civic
    discussions in today’s world and to love lifelong learning.</p>
<p>At a time when we are challenged with competing claims and misinformation in almost every area of our lives, we
    need
    graduates who can think clearly. Discernment is necessary to come to well-reasoned and wise judgments. Students
    need
    to understand enduring areas of knowledge so that they can solve problems, innovate, and make sound decisions
    for
    themselves, their communities, and the province.</p>
<p class="quote">“It’s not that people without deep knowledge come to believe nothing, but rather
    they
    will believe anything.” (Egan, 2010, p. 9)</p>
<p>We want our children to learn to think clearly and critically. For this reason, the Alberta curriculum needs to
    identify the core knowledge that builds toward understanding and skills in each subject area. A knowledge-rich
    curriculum is fundamental to Alberta Education’s goal of deep understanding. Learning without facts is like
    singing
    without notes.</p>
<p>Innovation, freedom, and independence require a strong factual foundation drawn from the best of past
    civilizations.
    As Newton famously said, we stand on the shoulders of giants. As students build knowledge into their long-term
    memory, through research-proven teaching methods (Hattie & Zierer, 2019; Kirschner, Sweller, & Clark, 2006),
    additional knowledge continually broadens and deepens their ability for nuanced understanding and creative
    production. Facts do not get in the way of understanding, but are the essential foundation of understanding
    (Christodoulou, 2014). For example, it is not enough to know what happened in history—by also learning when it
    happened and where it happened, we can begin to understand why it happened.</p>
<p>Intellectual capacity is built over time and through multiple opportunities to use foundational knowledge and
    skills.
    With coaching, skills such as writing clearly and organizing thoughts into an orderly and logical structure help
    to
    embed knowledge into long-term memory (Wexler, 2019). As such, knowledge and skills are both necessary building
    blocks for globally competitive educational outcomes. As children mature into educated adults, the best content
    knowledge that has been passed down through generations will be combined with skills, such as those of grammar,
    logic, and rhetoric, to create students who are literate, numerate, wise, innovative, questioning, and creative
    with
    the skills to succeed in work and in life.</p>
<p>Literacy will be taught across all subjects with age-appropriate targeted literacy education in language arts
    classes
    that meet the best global standards of literacy science. Mathematics and science will contain the sequencing and
    content to build a foundation that will enable all students to be functionally literate and prepared to flourish
    in
    all fields, including STEM (science, technology, engineering, and math). When students learn history, geography,
    and
    civics, the curriculum is fact-based and grounded in the history, geography, and civic traditions and
    institutions
    of our province and our country, but also exposes students to the broader world with its diverse history,
    geography,
    and political systems. The curriculum’s approach to language, literature, philosophy, and fine arts follows the
    same
    model. Setting out what students need to know to understand the society and culture that they will inherit,
    curriculum draws upon the rich heritage of the best that has been thought and said at different times and places
    in
    the world, with a specific focus on the traditions that have shaped our province.</p>
<h4 style="color: #333">Sequencing</h4>
<p>In order to give specific guidance on age-appropriate essential knowledge, the curriculum will be sequenced
    within a
    grade and from grade to grade. Why sequenced? If the curriculum fails to set out content in sequence,
    evidence shows
    that it is difficult to build knowledge and understanding because there will be gaps and repetitions, not
    only for
    students who switch schools mid-year, but for all students as they go from grade to grade (Christodoulou,
    2014). If
    a teacher can be confident that all students have already read certain stories or reached a specific level
    of
    mathematical knowledge, they can confidently remind their students of their previous knowledge and move on
    to build
    from there. Education that presents essential core knowledge in a sequenced progression is best practice
    among
    high-performing jurisdictions (Oates, 2011).</p>
<p>The curriculum will also be coordinated horizontally to reinforce learning across multiple subjects. For
    example,
    students playing a variation of the Indigenous game of hockey in physical education and wellness will at the
    same
    time study Indigenous stories in language arts and literature; learn Indigenous geography, place names, and
    history
    in social studies; learn Indigenous ways of telling time in mathematics; and have aligned content in fine
    arts, such
    as Indigenous theatre, beadwork, music, and dance. In this way, students will be immersed in a rich world of
    learning where essential knowledge can build naturally toward understanding and demonstrable skills in a
    natural
    progression. When they cycle back to learn more about Indigenous knowledge, skills, and understanding in
    future
    years of study, they will continue to build on this memorable foundation in a systematic way.<sup>9</sup></p>
    <p class="footnote"><sup>9</sup> Similarly, practicing Olympic Games in physical education is an opportunity for the study of Greek myths, history, geography, and geometry. Essential knowledge about other regions of the world, such as Africa and Asia, can be coordinated across subjects and systematically cycled back to in future years to build deep cross-cultural literacy that undermines bigotry such as racism.
        10 “Ensure curriculum is free from the prescription of pedagogical approaches, such as discovery math” (Curriculum Advisory Panel, 2019, p. 7).</p>
<h3 color="#333">What to Teach, Not How to Teach</h3>
<p>The curriculum sets out the content of what students are to be taught; it does not dictate how to teach.<sup>10</sup>
    Teachers learn how to teach in their post-secondary teacher-preparation programs and through ongoing
    professional development according to research into best practices. Teachers need the flexibility to do what
    works best for their students in a variety of contexts. To be clear, a curriculum that sets out sequenced
    essential content does not imply that the content should be taught by any one method by all teachers and for
    all students. Teaching methods and assessment strategies will help all students understand the content and
    help teachers to identify gaps in understanding that need to be addressed. Students will gain a progressive
    understanding of core knowledge and an increasing skill set to process and demonstrate their growing
    knowledge and understanding.</p>
    <p class="footnote"><sup>10</sup> “Ensure curriculum is free from the prescription of pedagogical approaches, such as discovery math” (Curriculum Advisory Panel, 2019, p. 7).</p>
<p>Parents, Elders, siblings, and friends are encouraged to act in a mutually supportive way with other role
    models, such as teachers, principals, and coaches. By working together as Albertans dedicated to preserving
    the best of our shared heritage and learning from the lessons of history, we have a sacred duty to help
    guide the next generation with virtue and humility. The curriculum and this Guiding Framework will be
    written in plain language whenever possible, free of academic or other jargon. This helps teachers,
    parents,<sup>11</sup>
    and community be fully informed to cooperate in the education of children. When the curriculum writing is
    clear, everyone can more easily come together to help children learn in diverse ways. This is a win for all
    of us, but especially the children.</p>
    <p class="footnote"><sup>11</sup> “WHEREAS parents have the right and the responsibility to make informed decisions respecting the education of their children; WHEREAS parents have a prior right to choose the kind of education that may be provided to their children” (Alberta Education, 2020a, Preamble);.“A parent has the prior right to choose the kind of education that shall be provided to the parent’s child, and as a partner in education, has the responsibility to (a) act as the primary guide and decision-maker with respect to the child’s education” (Alberta Education, 2020a, Section 32);
        “Parents have a prior right to choose the kind of education that shall be given to their children” (United Nations, 2008, Article 26(3)).</p>
<p>Alberta’s provincial curriculum is designed to include essential knowledge and skills for meaningful learning
    and understanding by providing</p>
<ul>
    <li>the factual foundations needed for learning and understanding to emerge. Students build on prior
        knowledge and experiences in a systematic way, with time in the curriculum to cycle back to use prior
        knowledge and skills in increasingly complex and integrated ways as education advances.</li>
    <li>a focus on enduring works taught with an appreciation for how and why they have influenced and inspired
        subsequent generations. Students should be challenged imaginatively to enter into the thinking of
        different times and places rather than just relate history, art, and thought to our own time and place.
    </li>
    <li>learning outcomes that integrate competencies with subject- and grade-specific knowledge, understanding,
        and skills, ensuring that students are also able to apply what they know to a variety of contexts</li>
    <li>learning outcomes that allow students to develop knowledge and skills related to literacy and numeracy
        across all subjects</li>
    <li>opportunities for students to make connections within and across subjects</li>
</ul>
<h3>Pluralism<sup>12</sup></h3>
<p class="footnote"><sup>12</sup> “All courses or programs of study and instructional materials used in a school must reflect the diverse nature and heritage of society in Alberta, promote understanding and respect for others and honour and respect the common values and beliefs of Albertans” (Alberta Education, 2020a, Section 16(1)).</p>
<p>As a pluralistic society, Alberta recognizes and supports unity and a commitment to the common good among a
    diverse citizenry. A peaceful, pluralistic society and an energized civilization requires respect and mutual
    understanding among people of different faiths, experiences, and backgrounds. The curriculum will provide
    coherent foundational learning to help students develop a respectful understanding of the contributions of
    the many linguistic, cultural, and ethnic groups in Canada. The curriculum will prepare students to
    understand the need for civic responsibility within a democracy that fosters welcome and empathy. Pluralism
    is vital to Alberta’s diverse resource-rich economy, beauty, and cultural richness. A strong civil society
    includes charities, volunteer organizations, and religious bodies, with respect for rights and freedoms, the
    rule of law, political institutions, and democracy.</p>
<p>The curriculum will provide a coherent and nuanced narrative about the origins of Canadian democracy, law,
    freedom, art, science, and culture. It will face squarely the times and occasions when we have failed,
    through human weakness and misguided ideas of progress, to treat all persons with equal respect and dignity.
    The curriculum will also celebrate the abundant good in our history and teach both the good and the bad
    lessons about what is required of each of us, individually and collectively, to preserve and improve our
    rich cultural inheritances so that all Albertans can share in them and newcomers can find their place in
    them without prejudice.</p>
<p>By seeing one another in the curriculum and coming to know about our intertwined history and culture in
    specific detail, we overcome prejudice. Students will learn significant Alberta history and stories from a
    variety of perspectives.<sup>13</sup>
</p>
<p class="footnote"><sup>13</sup> For example, students will learn about the Indigenous peoples who were here since prehistoric times; about the 1000 Black Americans who immigrated to Amber Valley and other settlements in Alberta from 1909–11; about how Edmonton’s Christian and Jewish citizens helped raise the money to build Canada’s first mosque in 1938, at a time when there were only 700 Muslims in Canada; and about the wave of Central and Eastern European farmers who cleared much of today’s farmland.</p>
<p>In addition to learning significant Alberta and Canadian history, the history of Indigenous Canadians,
    European history, the coming of the French and the British, and the establishment of modern Canada and
    subsequent waves of immigration, students will learn world history at an age-appropriate level through
    focused and sustained studies of the classical civilizations of Africa, the Americas, Asia, Europe, and the
    Middle East.<sup>14</sup>
</p>
<p class="footnote"><sup>14</sup> Such as the Mali Empire, Great Zimbabwe; pre-contact North America, including local peoples as well as the Incas and Aztecs; China, India, Japan, and the Silk Road; the rise and fall of Greece, Rome, Britain, France; Egypt and Israel.</p>
<p>Albertans from varied backgrounds will see themselves in the curriculum, “regardless of race, religious
    belief, colour, gender, gender identity, gender expression, physical disability, mental disability, family
    status or sexual orientation, or any other factor(s),”<sup>15</sup>
    both in their common humanity and in the diversity of valuable contributions to our shared human
    inheritance.</p>
    <p class="footnote"><sup>15</sup> “To support children and students in attaining the goals as stated in the Ministerial Order on Student Learning, school authorities must ensure that all children and students (Kindergarten to Grade 12), regardless of race, religious belief, colour, gender, gender identity, gender expression, physical disability, mental disability, family status or sexual orientation, or any other factor(s), have access to meaningful and relevant learning experiences that include appropriate instructional supports” (Alberta Education, 2020c).</p>
<p>Texts and materials will reflect a diversity of authors, with an emphasis on those whose works show the
    transcendence of the human spirit through time and across cultures.<sup>16</sup>
    While reading a variety of authors, students will be encouraged to see that the experience of being human
    and the essential problems facing our society today have been considered and addressed by great writers and
    thinkers of very different backgrounds in very different places and in very different ages, and that persons
    of any background can find and come to know themselves through great art and philosophy.</p>
    <p class="footnote"><sup>16</sup> For example, by the end of high school, students will have some experience of direct engagement with oral wisdom traditions of Indigenous peoples; as well as classic texts, such as those of Shakespeare, Dante, Maimonides, Avicenna, Augustine, Lao Tzu, Plato, Confucius, Aesop, Homer, and more recent writers of diverse backgrounds, such as Rohinton Mistry, Alice Munro, V. S. Naipaul, Derek Walcott, James Baldwin, W. H. Auden, Elizabeth Bishop, Emily Dickinson, Dostoevsky, and Jane Austen.</p>
<p>With a nuanced understanding of themselves and their inheritance of humanities and sciences, our students
    will be better prepared to appreciate how to cherish what is good and learn from what is bad in recent
    history and contemporary times.<sup>17</sup>
    Students will learn the value of the Charter’s fundamental freedom, open and orderly institutions, and
    peaceful pluralism to Alberta’s prosperous economy and free society.</p>
    <p class="footnote"><sup>17</sup>For example, students will learn about the current threats to public order, freedom, and democracy; the rise of Fascist and Communist authoritarian movements in the 20th century; the two world wars; and the horrors of modern genocides, such as the Holocaust, the Holodomor, and the Cultural Revolution so that they can help to guard our society against the causes of the downfall of political order in other times and places. This will be done through the works of writers who suffered under or opposed these movements on ethical and moral grounds, including Aleksandr Solzhenitsyn, Hannah Arendt, George Orwell, and Anne Frank, introduced at age-appropriate times.</p>
<h3>Inclusion<sup>18</sup>
</h3>
<p class="footnote"><sup>18</sup>“WHEREAS the Government of Alberta recognizes the importance of an inclusive education system that provides each student with the relevant learning opportunities and supports necessary to achieve success” (Alberta Education, 2020a, p. 11).</p>
<p>A high-quality, publicly funded education system is crucial to Alberta’s democratic society. Diversity is an
    essential part of the human condition and students will learn the value of all people. The Education Act
    recognizes the importance of an inclusive education system that provides each student with relevant learning
    opportunities and supports necessary to achieve success (Alberta Education, 2020a, Preamble, 11, 1–6, and 33
    (e)). Within an inclusive education system, the curriculum plays a vital role in preparing students to
    participate fully in a democratic and civil society. Quality education of the highest academic standards is
    provided to all learners, with a focus on ensuring that our education system delivers the best possible
    outcomes for all children and students.</p>
<p>Alberta’s inclusive K–12 education system promotes equal opportunities for all learners to thrive in safe and
    caring environments that are responsive to their needs. Quality education is extended to all students
    without prejudice.</p>
<h3>First Nations, Métis, and Inuit<sup>19</sup>
    Experiences and Perspectives</h3>
    <p class="footnote"><sup>19</sup>First Nations, Métis, or Inuit is elsewhere in this document referred to respectfully as Indigenous.</p>
<p>First Nations, Métis, and Inuit include many diverse peoples and unique ways of life across Canada. Within
    Alberta, there are 48 First Nations in the territories across Treaty 6, Treaty 7, and Treaty 8; eight Métis
    Settlements; as well as many First Nations, Métis, and Inuit living in urban and rural communities. Students
    from these communities will see themselves within Alberta’s provincial curriculum. Accordingly, the
    curriculum will have an explicitly dedicated space for exploring local content. When appropriate to the
    curriculum content, teachers are encouraged to support education about local tradition by respectfully
    inviting local Elders or Knowledge Keepers who are recognized in their respective communities as those who
    best hand down their cultural knowledge, wisdom, and oral traditions. The curriculum encourages field
    experiences led by local First Nations, Métis, or Inuit, such as traditional ways of living with and on the
    land, such as harvesting, hunting, food-preparing, building, story- and history- telling, and, where
    appropriate and with the permission and guidance of local and knowledgeable community members, culturally
    significant principles, practices, traditions, events, and ceremonies. All students can benefit from, and
    learn to help preserve the teachings of, local wisdom.</p>
<p>To honour the <em>Truth and Reconciliation Commission of Canada: Calls to Action</em> and the <em>United Nations
        Declaration on the Rights of Indigenous Peoples</em>, Alberta is committed to rebalancing the education system
    by
    including the history and legacy of residential schools and local Indigenous knowledge, wisdom, and oral
    traditions in the curriculum to advance reconciliation for all Albertans.</p>
<p>First Nations, Métis, and Inuit histories, contributions and perspectives in the curriculum provide means for
    reconciliation. Increasing inter- and cross-cultural understanding and initiating healthy shifts in thinking
    will build trust to improve relationships among all Albertans. The inclusion of First Nations, Métis, and
    Inuit historical and contemporary experiences and contributions, residential schools and their legacy, and
    the history and continuing importance of the treaties and agreements will help rectify injustices and
    support better relationships. First Nations, Métis, and Inuit songs, stories, histories, languages, arts,
    sciences, and contributions to the rich history of Alberta need to be part of every Alberta student’s
    education.</p>
<p>Recognizing the diversity of Indigenous people within Alberta, teachers are required to include content of
    their local First Nations and Métis communities. Every student in the province, not only First Nations,
    Métis, and Inuit students, will learn about the diverse Indigenous peoples of this land and how First
    Nations, Métis, and Inuit contribute to the vibrancy and fabric of Alberta and Canadian society.</p>
<h3>Francophone Perspectives</h3>
<p>French was the first European language spoken in the land that is now Alberta. As representatives of the
    linguistic duality of Canada, Francophone communities in Alberta continue to grow and actively contribute to
    Alberta’s economy, cultural mosaic, and society as a whole. Today’s Francophone communities include
    generations of Franco-Albertans and newcomers from other Canadian provinces and territories, as well as
    people from many countries around the world. As such, Francophone communities are a vibrant and integral
    part of Alberta society.</p>
<p>Section 23 of the <em>Canadian Charter of Rights and Freedoms</em> (1982) gives Francophones and Anglophones, who
    live
    in a minority setting, the right to a primary and secondary education in their official language. The
    inclusion of Francophone content in Alberta’s provincial curriculum enables every student in the province,
    not only Francophone students, to gain a deeper understanding and appreciation of the linguistic,
    historical, cultural, and contemporary contributions of Francophones and their communities in Alberta and
    Canada.</p>
<p>To ensure equal access to quality education for students studying primarily in French, the curriculum should
    be simultaneously released in English and French.</p>
<h2>Features of the Provincial Curriculum</h2>
<p>The vision for students and the preceding considerations combine to shape Alberta’s provincial curriculum.
    The curriculum states, in subjects and by grade, what students are expected to know, understand, and be able
    to do, building to a rich and coherent body of essential knowledge and skills by the time of graduation.</p>
<p>To ensure the provincial curriculum meets its intended purpose, common features and guidelines are
    consistently applied. The curriculum is to provide clear guidance and support for teachers and accessible
    information for parents who have the right of oversight and choice in the education of their children. For
    these reasons, the curriculum must be clear, concise, and as free as possible from ideology, pedagogy, and
    jargon. The features of the curriculum are consistent across all subjects for easy navigation by all
    participants in our provincial education system.</p>
<p>Alberta’s K–12 provincial curriculum has three common features:</p>
<p><strong>Architecture and design:</strong> The architecture and design provides a systematic and logical
    arrangement that is consistent for all core subjects of the provincial curriculum.</p>
<p><strong>Structure of learning outcomes:</strong> Each learning outcome includes one or more concepts that are
    the focus for students’ learning and assessment experiences.</p>
<p><strong>Clear and concise language:</strong> This helps to give clear direction and accountability for
    teachers and helps enable parental oversight of their children’s education.</p>
<h3>Architecture and Design of the Curriculum</h3>
<h4 style="color: #333">Organizing Idea</h4>
<p>We start with the end in mind. The organizing idea is a statement of the learning within a given section of a
    subject area or discipline. The organizing ideas are intended to be logical categories that communicate the
    goals of education within each section of a subject area. They may span some or all grades.</p>
<h4 style="color: #333">Guiding Questions</h4>
<p>Guiding questions are informed by the organizing idea and frame the learning outcome. Questions spark
    curiosity and wonder, which inspires truth seeking, understanding, and a lifelong love of learning.</p>
<h4 style="color: #333">Learning Outcomes</h4>
<p>The learning outcomes describe what students are required to know, understand, and be able to do by the end
    of a grade. Student achievement of the learning outcome must be assessed and reported.</p>
<h4 style="color: #333">Knowledge<sup>20</sup>
</h4>
<p class="footnote"><sup>20</sup>Cognitive principle 1: “Students learn new ideas by reference to ideas they already know.”
    <br />
    Cognitive principle 2: “A well-sequenced curriculum is important to ensure that students have the prior knowledge they need to master new ideas.”
    <br />
    Cognitive principle 3: “Each subject area has some set of facts that, if committed to long-term memory, aids problem-solving by freeing working memory resources and illuminating contexts in which existing knowledge and skills can be applied. The size and content of this set varies by subject matter.”
    <br />
    Cognitive principle 4: “The transfer of knowledge or skills to a novel problem requires both knowledge of the problem’s context and a deep understanding of the problem’s underlying structure.” (Deans for Impact, 2015)</p>
<p>Well-sequenced core knowledge is foundational to all learning. Knowledge is learned in order for students to
    understand and do something. Knowledge, including facts, is not disconnected trivia, but instead builds
    toward understanding and skills and procedures.</p>
<h3>Understanding</h3>
<p>Understanding is about putting pieces of knowledge into logical and meaningful order with other knowledge.
    Understanding is more complex than knowledge, showing that a student is learning how to organize knowledge to
    understand a concept. By understanding, one can apply what they have learned to new situations in other contexts.
    Understanding is more than knowledge, but impossible without it.</p>
<h4 style="color: #333">Skills and Procedures</h4>
<p>Skills and procedures are what students do to demonstrate their knowledge and understanding. They are specific
    skills, methods, tools, strategies, and processes that students will develop as they achieve the learning outcome.
</p>
<h4 style="color: #333">Literacy and Numeracy</h4>
<p>Learning outcomes are designed to support the development of literacy and numeracy within grade levels and across
    subjects. This foundation builds essential knowledge, shared cultural literacy, and skills that enable students to
    solve problems, think critically, and manage information that surrounds them.</p>
<p>Literacy involves acquiring and applying the understanding and skills necessary to decode, construct, evaluate, and
    logically communicate ideas, using oral, written, visual, and multimedia sources. Literacy is embedded in learning
    across all subject areas. It is foundational to living, learning, and working as knowledgeable, active participants
    in a democratic society.</p>
<p>Numeracy involves acquiring and applying the mathematical knowledge and skills needed to engage with quantitative and
    spatial information in a variety of situations. Numeracy is embedded in learning experiences across all subject
    areas. It is foundational to making informed decisions as knowledgeable, active participants in our democratic
    society.</p>
<h3>Competencies</h3>
<p>Competencies are combinations of knowledge, skills, and characteristics that students develop and apply for
    successful learning, living, and working. They emphasize aspects of learning that apply within and across all
    subjects. Competencies enhance the foundational building blocks of literacy and numeracy and work together with
    subject-area learning outcomes to ensure Alberta students are knowledge rich and well-prepared to live successful
    and fulfilling lives and make meaningful contributions to their communities and the world.</p>
<div class="word-list">
    <div class="col">
        <div>Critical Thinking</div>
        <div>Problem Solving</div>
        <div>Research and Managing Information</div>
        <div>Creativity and Innovation</div>
    </div>
    <div class="col">
        <div>Communication</div>
        <div>Collaboration</div>
        <div>Citizenship</div>
        <div>Personal Growth and Well-being</div>
    </div>
</div>
<p><strong>Critical Thinking</strong> involves reasoning logically to analyze and synthesize new knowledge with existing
    knowledge in a coherent way. Students reflect on their thinking to improve it. They reason from sound premises. They
    evaluate arguments and avoid contradictions. They know how to identify and avoid logical fallacies. Critical
    thinkers can discern the difference between fact and opinion and can back up their opinions with evidence. Students
    demonstrate the humility to be open to critique, value honesty, and have the courage to question assumptions with
    demonstrable facts and reason.</p>
<ul>
    <li>uestioning and analyzing evidence, assertions, or assumptions using logic, the law of non-contradiction, and
        factual knowledge</li>
    <li>pplying reasoned approaches or relevant criteria to conceptualize, analyze, or make judgments, and spotting and
        avoiding logical fallacies</li>
    <li>eflecting upon and evaluating the reasoning behind thoughts, beliefs, or actions</li>
    <li>ynthesizing thoughts and information to discover or extend understanding</li>
    <li>emonstrating intellectual integrity, humility, fairness, and respect for freedom of expression and the diversity
        of thoughtful opinions</li>
</ul>
<p><strong>Problem Solving</strong> involves selecting strategies and resources to move from what is known to what is
    sought. Students analyze situations, create plans of action, and implement solutions. They evaluate alternatives and
    their consequences. Students approach challenges using factual knowledge, critical thinking, reason, logic,
    creativity, flexibility, resilience, and determination.</p>
<ul>
    <li>starting by identifying the problem</li>
    <li>identifying what is known and what is required to understand a problem</li>
    <li>doing quality research from reliable sources to fill in gaps in one’s knowledge relevant to solving the problem
    </li>
    <li>exploring strategies using relevant information, resources, or criteria</li>
    <li>assessing options to generate courses of action</li>
    <li>evaluating the impact of possible solutions to carry out the most viable option</li>
    <li>approaching challenges with humility, truth-seeking, creativity, flexibility, and determination</li>
</ul>
<p><strong>Research and Managing Information</strong> involves research skills as well as organizing and using
    information for specific purposes. Students access, interpret, evaluate, and share information from a variety of
    sources. Students learn about standards of professional peer-review and the comparative reliability of sources. They
    are ethical and effective in how they use and share information. Students value reliability, validity, and integrity
    of information. Students seek truth and know the difference between factual knowledge, opinion, and conjecture.</p>
<ul>
    <li>assessing information from a variety of sources</li>
    <li>synthesizing or organizing multiple pieces of information to enhance or clarify understanding</li>
    <li>evaluating truth, soundness, probability, and validity to appropriately interpret or use information</li>
    <li>using, sharing, or storing information effectively and ethically</li>
</ul>
<p><strong>Creativity and Innovation</strong> involves generating and applying knowledge and skill to create something
    new of value. Students are inspired by enduring truth, goodness, and beauty in art and nature to imitate and create.
    They are open to and play with ideas, take risks, and adapt to changing conditions. Students demonstrate wonder,
    curiosity, initiative, and ingenuity.</p>
<ul>
    <li>recognizing opportunities and imagining possibilities to apply knowledge, skills, and ideas in new ways</li>
    <li>identifying continuities, breaks, and influences between art and thought across different times and places</li>
    <li>exploring or playing with ideas, materials, or processes to create something new</li>
    <li>evaluating and adapting ideas, materials, or processes in response to feedback or emerging conditions</li>
    <li>demonstrating initiative, resourcefulness, and perseverance when transforming ideas into actions, products, or
        services</li>
</ul>
<p><strong>Communication</strong> involves sharing ideas through oral, written, or non-verbal media. Students engage in
    formal and informal exchanges for a variety of purposes with their audience in mind. They consider how culture,
    context, and experience impact messaging. Students demonstrate respect, empathy, and responsibility when
    communicating with others.</p>
<ul>
    <li>clarifying the purpose or intention of a message in relation to audience, context, or culture</li>
    <li>considering perspectives, emotions, and experiences when seeking to understand or be understood</li>
    <li>decoding and interpreting ideas or information shared through verbal or non-verbal formats</li>
    <li>expressing ideas or concepts using context-appropriate language and other relevant conventions or protocols</li>
    <li>demonstrating respect, humility, and responsibility when communicating with others</li>
</ul>
<p><strong>Collaboration</strong> involves working with others to achieve worthy common goals. Students participate,
    exchange ideas, and share responsibilities in a way that gives everyone the opportunity to contribute their talents
    and to learn. They respectfully challenge competing views and acknowledge the inherent dignity of each individual.
    They demonstrate listening skills and choose persuasion rather than force to resolve differences. Students are
    adaptable, fair, and willing to negotiate reasonable compromises.</p>
<ul>
    <li>sharing responsibilities and supporting others to achieve good common goals</li>
    <li>demonstrating sensitivity and actively seeking empathy and understanding of variations in culture, audience, or
        contexts when working with others</li>
    <li>seeking to understand what is shared in common despite apparent or actual differences</li>
    <li>seeking to earn and respect reciprocity and trust when sharing ideas or roles</li>
    <li>valuing flexibility, compromise, and the contributions of others to nurture positive working relationships
        without compromising virtue</li>
</ul>
<p><strong>Citizenship</strong> involves participating in the institutions of our society as educated citizens. Students
    acquire knowledge and skills to support informed judgments that recognize the value of inherited traditions and the
    obligation to future generations. Students will build an appreciation of living respectfully in a pluralistic
    society in such a way that prepares them to one day lead our country to be a respected and responsible player on the
    world stage. Students leverage understandings of cultural, political, environmental, or economic systems to identify
    connections that transcend differences and make meaningful contributions to their communities and the world. They
    recognize a shared responsibility for environmental stewardship and sustainability. Students approach the world with
    intellectual curiosity and humility, demonstrate respect for the inherent dignity of each individual, and develop
    empathy for human well-being locally and globally.</p>
<ul>
    <li>whether on the playground or in the classroom, students will learn to consider peaceful and respectful ways of
        resolving differences and cooperating virtuously using persuasion, building common ground, and avoiding the
        unnecessary use of force or violence in resolving disputes</li>
    <li>analyzing various ways in which decisions are made within different cultural, political, or economic systems
    </li>
    <li>demonstrating an understanding of economic development and entrepreneurship, and recognizing the responsibility
        we share for environmental stewardship</li>
    <li>demonstrating an understanding of the needs, beliefs, and expectations of diverse communities, and identifying
        connections between people that transcend differences</li>
    <li>evaluating the moral and ethical impact of decisions or actions on individuals and communities</li>
    <li>understanding the threats of ideology and authoritarian movements</li>
    <li>humbly, courageously, and wisely using their talents to serve their community, their country, or the world</li>
</ul>
<p><strong>Personal Growth and Well-being</strong> involves managing emotional, intellectual, physical, social, and
    spiritual aspects of living well. Students set learning, career, or wellness goals and work toward them. They draw
    upon their strengths to develop interests, skills, and talents and good character. Students are reflective,
    resourceful, and optimistic as they strive for personal excellence. They demonstrate a commitment to the common good
    by exercising compassion, empathy, and support for each other. They will become life-long learners who will
    cultivate the virtues of wisdom, courage, self-control, justice, charity, and hope.</p>
<ul>
    <li>identifying interests, virtues, or skills to set learning, life, or career goals</li>
    <li>exploring, selecting, or adapting strategies and resources that support personal growth in good character,
        academics, or career pathways</li>
    <li>making choices or taking action to promote the safety and well-being of self and others</li>
    <li>building healthy relationships to support growth and well-being of self and others</li>
    <li>demonstrating humility, flexibility, and resilience when adapting to new situations and transitions without
        compromising virtue</li>
</ul>
<h2>Conclusion</h2>
<p>This <em>Guiding Framework</em> interprets the Ministerial Order for Student Learning in order to give direction for
    curriculum development. Particular attention has been paid to keeping language free of unnecessary jargon to support
    parents to exercise their right to “act as the primary guide and decision-maker with respect to the child’s
    education” (Alberta Education, 2020a, Section 32). Students will be coached by caring and knowledgeable teachers to
    develop intellectual ability and practical skills. As such, the curriculum will be focused on the content and leave
    the teachers free to make decisions about how to best teach in their particular context. Students will inherit a
    rich, interconnected knowledge base and be practiced in free, lively, and respectful dialogue with varied viewpoints
    to broaden horizons and overcome prejudices. Although some core knowledge is an enduring legacy worthy of being
    taught for generations, other content needs to be kept up to date. Thus, the curriculum in Alberta will be reviewed
    regularly in order to keep up with the latest knowledge in areas where knowledge is clearly progressing. In this
    way, the curriculum will continue to pass down the lessons of history and masterworks of culture while keeping
    constantly up to date on the latest breakthroughs in human understanding.</p>
<h2>References</h2>
<div class="reference">
    Alberta Education. (2020a). <em>Education act.</em> Retrieved from
    <a href="https://www.qp.alberta.ca/documents/Acts/e00p3.pdf"
        target="_blank">https://www.qp.alberta.ca/documents/Acts/e00p3.pdf</a>
</div>
<div class="reference">
    Alberta Education. (2020b). Guide to education: ECS to grade 12, 2020–2021.
    <a href="https://open.alberta.ca/dataset/d119dba4-36cd-4e41-927b-b436fb2e75b1/resource/c9d6fa96-fbd4-433e-b193-b4281184e276/download/edc-guide-to-education-2020-2021.pdf"
        target="_blank">https://open.alberta.ca/dataset/d119dba4-36cd-4e41-927b-b436fb2e75b1/resource/c9d6fa96-fbd4-433e-b193-b4281184e276/download/edc-guide-to-education-2020-2021.pdf</a>
</div>
<div class="reference">
    Alberta Education. (2020c). Inclusive education policy.
    <a href="https://www.alberta.ca/education-guide-policies.aspx"
        target="_blank">https://www.alberta.ca/education-guide-policies.aspx</a>
</div>
<div class="reference">Arnold, M. (1999). <em>Culture and anarchy: An essay in political and social criticism.</em>
    Classics of Liberty Library.</div>
<div class="reference">Christodoulou, D. (2014). <em>Seven myths about education.</em> Routledge.
    <a href="https://doi.org/10.4324/9781315797397" target="_blank">https://doi.org/10.4324/9781315797397</a>
</div>
<div class="reference">Curriculum Advisory Panel. (2019). <em>Recommendations on direction for curriculum.</em>
    <a href="https://open.alberta.ca/publications/curriculum-advisory-panel-recommendations-on-direction-for-curriculum"
        target="_blank">https://open.alberta.ca/publications/curriculum-advisory-panel-recommendations-on-direction-for-curriculum</a>
</div>
<div class="reference">Deans for Impact. (2015). <em>The science of learning.</em>
    <a href="https://deansforimpact.org/resources/the-science-of-learning/"
        target="_blank">https://deansforimpact.org/resources/the-science-of-learning/</a>
</div>
<div class="reference">Egan, K. (2010). <em>Learning in depth: A simple innovation that can transform schooling.</em>
    Althouse Press.</div>
<div class="reference">Hattie, J., & Zierer, K. (2019). <em>Visible learning insights.</em> Routledge.</div>
<div class="reference">Hochman, J., Wexler, N., & Lemov, D. (2017). <em>The writing revolution: A guide to advancing
        thinking through writing in all subjects and grades.</em> Jossey-Bass.</div>
<div class="reference">Kirschner, P. A., & Hendrick, C. (2020). <em>How learning happens: Seminal works in educational
        psychology and what they mean in practice.</em> Routledge.</div>
<div class="reference">Kirschner, P. A., Sweller, J., & Clark, R. E. (2006). Why minimal guidance during instruction
    does not work: an analysis of the failure of constructivist, discovery, problem-based, experiential, and
    inquiry-based teaching. <em>Educational Psychologist,</em> 41(2), 75–86.
    <a href="https://doi.org/10.1207/s15326985ep4102_1" target="_blank">https://doi.org/10.1207/s15326985ep4102_1</a>
</div>
<div class="reference">Oates, T. (2011). Could do better: Using international comparisons to refine the national
    curriculum in England. <em>Curriculum Journal,</em> 22(2), 121–150.
    <a href="https://doi.org/10.1080/09585176.2011.578908"
        target="_blank">https://doi.org/10.1080/09585176.2011.578908</a>
</div>
<div class="reference">OECD. (2010). <em>The nature of learning: Using research to inspire practice.</em> OECD
    Publishing.</div>
<div class="reference">Stewart, V. (2012). <em>A world-class education: Learning from international models of excellence
        and innovation.</em> ASCD.</div>
<div class="reference">United Nations. (2008). <em>Universal Declaration of Human Rights.</em></div>
<div class="reference">Wexler, N. (2019). <em>The knowledge gap: The hidden cause of America’s broken education
        system—and how to fix it.</em> Avery.</div>



<h2>Bibliography</h2>

<div class="reference">Adler, M. J. (1982). <em>The Paideia proposal: An educational manifesto.</em> Simon and Schuster.
</div>
<div class="reference">Alberta Education. (2011). <em>Walking together: First Nations, Métis and Inuit perspectives in
        curriculum.</em> <a href="http://www.learnalberta.ca/content/aswt/"
        target="_blank">http://www.learnalberta.ca/content/aswt/</a></div>
<div class="reference">Alberta Education. (2015). <em>Guiding voices: A curriculum development tool for weaving First
        Nation, Métis and Inuit perspectives throughout curriculum.</em> <a
        href="http://www.learnalberta.ca/content/fnmigv/index.html"
        target="_blank">http://www.learnalberta.ca/content/fnmigv/index.html</a></div>
<div class="reference">Alberta Education. (2019). <em>Professional practice standards.</em> <a
        href="https://www.alberta.ca/professional-practice-standards.aspx"
        target="_blank">https://www.alberta.ca/professional-practice-standards.aspx</a></div>
<div class="reference">Alberta Education (2020). <em>Ministerial order on student learning.</em> <a
        href="https://www.alberta.ca/ministerial-order-on-student-learning.aspx#toc-4"
        target="_blank">https://www.alberta.ca/ministerial-order-on-student-learning.aspx#toc-4</a></div>
<div class="reference">Amadio, M. (2013). <em>A rapid assessment of curricula for general education focusing on
        cross-curricular themes and generic competences or skills.</em> Background paper prepared for the Education for
    All
    Global Monitoring Report 2013/2014, Teaching and learning: Achieving quality for all. UNESCO IBE. <a
        href="http://unesdoc.unesco.org/images/0022/002259/225939e.pdf"
        target="_blank">http://unesdoc.unesco.org/images/0022/002259/225939e.pdf</a></div>
<div class="reference">Armstrong, D.G. (2003). <em>Curriculum today.</em> Merrill Prentice Hall.</div>
<div class="reference">Baer, J. (2003). The impact of the core knowledge curriculum on creativity. <em>Creativity
        Research
        Journal,</em> 15(2–3), 297–300. <a href="https://doi.org/10.1080/10400419.2003.9651422"
        target="_blank">https://doi.org/10.1080/10400419.2003.9651422</a></div>
<div class="reference">Bennett, P. W. (2018). What can be learned from Quebec’s math prowess? <em>Policy Options
        Politiques.</em>
    <a href="https://policyoptions.irpp.org/magazines/october-2018/what-can-be-learned-from-quebecs-math-prowess/"
        target="_blank">https://policyoptions.irpp.org/magazines/october-2018/what-can-be-learned-from-quebecs-math-prowess/</a>
</div>
<div class="reference">Bennett, P. W. (2020). <em>The state of the system: A reality check on Canada's schools.</em>
    McGill-Queen’s University Press.</div>
<div class="reference">Birbili, M. (2007). Making the case for a conceptually based curriculum in early childhood
    education. <em>Early Childhood Education Journal,</em> 35(2), 141–147.</div>
<div class="reference">Brann, E. T. H. (1979). <em>Paradoxes of education in a republic.</em> University of Chicago
    Press.</div>
<div class="reference">Bransford, J. D., & Johnson, M. K. (1972). Contextual prerequisites for understanding: Some
    investigations of comprehension and recall. <em>Journal of Verbal Learning and Verbal Behavior,</em> 11(6), 717–726.
    <a href="https://doi.org/10.1016/S0022-5371(72)80006-9"
        target="_blank">https://doi.org/10.1016/S0022-5371(72)80006-9</a>
</div>
<div class="reference">Brant, C. C. (1990). Native ethics and rules of behaviour. <em>Canadian Journal of
        Psychiatry,</em> 35(6),
    534–539. <a href="https://doi.org/10.1177/070674379003500612"
        target="_blank">https://doi.org/10.1177/070674379003500612</a></div>
<div class="reference">British Columbia Ministry of Education. (2015). <em>Aboriginal worldviews and perspectives in the
        classroom: Moving forward.</em> Victoria, BC: Ministry of Education. Retrieved November 2015 from <a
        href="https://www.bced.gov.bc.ca/abed/awp_moving_forward.pdf"
        target="_blank">https://www.bced.gov.bc.ca/abed/awp_moving_forward.pdf</a></div>


<div class="reference">Brown, C. S., Tam, M., & Aboud, F. (2018). Ethnic prejudice in young children in Indonesia:
    Intervention attempts using multicultural friendship stories. <em>International Journal of Early Childhood,</em>
    50(1),
    67–84. <a href="https://doi.org/10.1007/s13158-018-0214-z"
        target="_blank">https://doi.org/10.1007/s13158-018-0214-z</a></div>
<div class="reference">Carlson, D. (2012). Out of the classroom and into the voting booth? <em>Analyzing the effects of
        education on political participation.</em> The University of Wisconsin, Madison.</div>
<div class="reference">Castles, A., Rastle, K., & Nation, K. (2018). Ending the reading wars: Reading acquisition from
    novice to expert. <em>Psychological Science in the Public Interest,</em> 19(1), 5–51. <a
        href="https://doi.org/10.1177/1529100618786959" target="_blank">https://doi.org/10.1177/1529100618786959</a>
</div>



<div class="reference">Christensen, N., & Lane, J. (2016). <em>Know>do>understand: Development of competencies in
        Canada’s
        schoolchildren.</em> Canada West Foundation. <a
        href="http://cwf.ca/wp-content/uploads/2016/03/HCP_KnowDoUnderstand_Report_MARCH2016-2.pdf"
        target="_blank">http://cwf.ca/wp-content/uploads/2016/03/HCP_KnowDoUnderstand_Report_MARCH2016-2.pdf</a></div>
<div class="reference">Curriculum Advisory Panel. (2019). <em>Recommendations on direction for curriculum.</em> <a
        href="https://open.alberta.ca/publications/curriculum-advisory-panel-recommendations-on-direction-for-curriculum"
        target="_blank">https://open.alberta.ca/publications/curriculum-advisory-panel-recommendations-on-direction-for-curriculum</a>
</div>
<div class="reference">Deans for Impact. (2015). <em>The science of learning.</em> <a
        href="https://deansforimpact.org/wp-content/uploads/2016/12/The_Science_of_Learning.pdf"
        target="_blank">https://deansforimpact.org/wp-content/uploads/2016/12/The_Science_of_Learning.pdf</a></div>
<div class="reference">Delgado, R., & Stefancic, J., & Harris, A. P. (2017). <em>Critical race theory: An
        introduction</em> (3rd
    ed.). New York University Press.</div>
<div class="reference">Desrochers, A., Manolitsis, G., Gaudreau, P., & Georgiou, G. (2018). Early contribution of
    morphological awareness to literacy skills across languages varying in orthographic consistency. <em>Reading and
        Writing: an Interdisciplinary Journal,</em> 31(8), 1695–1719. <a
        href="https://doi.org/10.1007/s11145-017-9772-y" target="_blank">https://doi.org/10.1007/s11145-017-9772-y</a>
</div>
<div class="reference">Dewey, J. (1920). <em>Reconstruction in philosophy.</em> Henry Holt and Co.</div>



<div class="reference">Dochy, F., Segers, M., & Buehl, M. M. (1999). The relation between assessment practices and
    outcomes of studies: The case of research on prior knowledge. <em>Review of Educational Research,</em> 69(2),
    145–186. <a href="https://www.jstor.org/stable/1170673" target="_blank">https://www.jstor.org/stable/1170673</a>
</div>
<div class="reference">Dueck, J. (2016). <em>How political correctness weakens schools: Stop losing and start winning
        educational excellence.</em> Rowman & Littlefield.</div>
<div class="reference">Duncan, G. J., Dowsett, C. J., Claessens, A., Magnuson, K., Huston, A. C., Klebanov, P., Pagani,
    L. S., Feinstein, L., Engel, M., Brooks-Gunn, J., Sexton, H., Duckworth, K., & Japel, C. (2007). School readiness
    and later achievement. <em>Developmental Psychology,</em> 43(6), 1428–1446. <a
        href="https://doi.org/10.1037/0012-1649.43.6.1428"
        target="_blank">https://doi.org/10.1037/0012-1649.43.6.1428</a></div>
<div class="reference">Education Scotland. (2015) <em>Curriculum for excellence: Literacy across learning: Principles
        and
        practice.</em> Retrieved November 2015 from <a
        href="https://education.gov.scot/Documents/literacy-across-learning-pp.doc"
        target="_blank">https://education.gov.scot/Documents/literacy-across-learning-pp.doc</a></div>
<div class="reference">Education Scotland. (2015) <em>Curriculum for excellence: Numeracy across learning: Principles
        and
        practice.</em> Retrieved November 2015 from <a
        href="https://education.gov.scot/Documents/numeracy-across-learning-pp.pdf"
        target="_blank">https://education.gov.scot/Documents/numeracy-across-learning-pp.pdf</a></div>







<div class="reference">Ehri, L. C. (2005). Learning to read words: Theory, findings, and issues. <em>Scientific Studies
        of
        Reading,</em> 9(2), 167–188. <a href="https://doi.org/10.1207/s1532799xssr0902_4"
        target="_blank">https://doi.org/10.1207/s1532799xssr0902_4</a></div>
<div class="reference">Elliott, V., & Olive. S. (2019). Secondary Shakespeare in the UK: What gets taught and why?
    <em>English in Education.</em> <a href="https://doi.org/10.1080/04250494.2019.1690952"
        target="_blank">https://doi.org/10.1080/04250494.2019.1690952</a>
</div>
<div class="reference">Erickson, H. L., & Lanning, L. A. (2014). <em>Transitioning to concept-based curriculum and
        instruction: How to bring content and process together.</em> SAGE.</div>
<div class="reference">Frantz, R., & Simon, H. (2003). Artificial intelligence as a framework for understanding
    intuition. <em>Journal of Economic Psychology,</em> 24(2) 265-277.</div>
<div class="reference">Gardner, H. (2008). <em>Five minds for the future.</em> Harvard Business Press.</div>
<div class="reference">Gardner, H. (2011). <em>Truth, beauty, and goodness reframed: Educating for the virtues in the
        twenty-first century.</em> Basic Books.</div>
<div class="reference">Georgiou, G. K., Parrila, R., & Papadopoulos, T. C. (2008). Predictors of word decoding and
    reading fluency across languages varying in orthographic consistency. <em>Journal of Educational Psychology,</em>
    100(3),
    566–580. <a href="https://doi.org/10.1037/0022-0663.100.3.566"
        target="_blank">https://doi.org/10.1037/0022-0663.100.3.566</a></div>
<div class="reference">Gutek, G. L. (2009). <em>New perspectives on philosophy and education.</em> Pearson.</div>
<div class="reference">Gutek, G. L. (2011). <em>Historical and philosophical foundations of education: A biographical
        introduction</em> (5th ed.). Pearson.</div>
<div class="reference">Hattie, J., & Zierer, K. (2019). <em>Visible learning insights.</em> Routledge.</div>
<div class="reference">Hulme, C., & Snowling, M. J. (2013). Learning to read: What we know and what we need to
    understand better. <em>Child Development Perspectives,</em> 7(1), 1–5. <a href="https://doi.org/10.1111/cdep.12005"
        target="_blank">https://doi.org/10.1111/cdep.12005</a></div>
<div class="reference">Hutchins, R. M. (1937). Ideals in education. <em>The American Journal of Sociology,</em> 43(1),
    1–15. <a href="http://www.jstor.org/stable/2768229" target="_blank">http://www.jstor.org/stable/2768229</a></div>
<div class="reference">Hutchins, R. M. (1952). <em>The great conversation: The substance of a liberal education.</em>
    Encyclopaedia Britannica, Inc.</div>
<div class="reference">Irish, T. (2008). <em>Teaching Shakespeare – a history of the teaching of Shakespeare in
        England.</em> The
    Royal Shakespeare Company. <a
        href="https://cdn2.rsc.org.uk/sitefinity/education-pdfs/articles-and-reports/rsc-education-history-of-teaching-shakespeare.pdf?sfvrsn=e5025b21_2"
        target="_blank">https://cdn2.rsc.org.uk/sitefinity/education-pdfs/articles-and-reports/rsc-education-history-of-teaching-shakespeare.pdf?sfvrsn=e5025b21_2</a>
</div>
<div class="reference">Johnson, T.W., & Reed, R.F. (2012). <em>Philosophical documents in education</em> (4th ed.).
    Pearson.
</div>
<div class="reference">Jonassen, D. H. (2006). On the role of concepts in learning and instructional design.
    <em>Educational
        Technology Research and Development,</em> 54(2), 177–196.
</div>
<div class="reference">Kadosh, R. C., & Dowker, A. (2015). <em>The Oxford handbook of numerical cognition.</em> Oxford
    University
    Press.</div>
<div class="reference">Kalenze, E. (2014). <em>Education is upside-down – reframing reform to focus on the right
        problems.</em>
    Rowman & Littlefield.</div>
<div class="reference">King, M. L. (2001). <em>The autobiography of Martin Luther King, Jr.</em> (C. Carson, Ed.) (1st
    trade
    ed.). Intellectual Properties Management in association with Warner Books.</div>
<div class="reference">Kirschner, P. A., Sweller, J., Kirschner, F., & Zambrano, J. R. (2018). From cognitive load
    theory to collaborative cognitive load theory. <em>International Journal of Computer-Supported Collaborative
        Learning,</em>
    13(2), 213–233. <a href="https://doi.org/10.1007/s11412-018-9277-y"
        target="_blank">https://doi.org/10.1007/s11412-018-9277-y</a></div>
<div class="reference">Manolitsis, G., Georgiou, G. K., Inoue, T., & Parrila, R. (2019). Are morphological awareness and
    literacy skills reciprocally related? Evidence from a cross-linguistic study. <em>Journal of Educational
        Psychology,</em>
    111(8), 1362–1381. <a href="https://doi.org/10.1037/edu0000354"
        target="_blank">https://doi.org/10.1037/edu0000354</a></div>
<div class="reference">Marsh, C. J., & Willis, G. (2007). <em>Curriculum: Alternative approaches, ongoing issues</em>
    (4th ed.).
    Pearson/Merrill Prentice Hall.</div>
<div class="reference">McCue, H. (2018). Op-ed: First Nations elementary and secondary school education–a national
    dilemma. <em>Critical Social Work,</em> 19(2), 112–123. <a href="https://doi.org/10.22329/csw.v19i2.5683"
        target="_blank">https://doi.org/10.22329/csw.v19i2.5683</a></div>
<div class="reference">McNergney, R.F., & McNergney, J.M. (2004). <em>Foundations of education: The challenge of
        professional practice</em> (3rd ed.). Allyn and Bacon.</div>
<div class="reference">McTighe, J., & Wiggins, G. P. (2013). <em>Essential questions: Opening doors to student
        understanding.</em> ASCD.</div>
<div class="reference">Miller, S. P., & Hudson, P. J. (2007). Using evidence-based practices to build mathematics
    competence related to conceptual, procedural, and declarative knowledge. <em>Learning Disabilities Research &
        Practice,</em>
    22(1), 47–57. <a href="https://doi.org/10.1111/j.1540-5826.2007.00230.x"
        target="_blank">https://doi.org/10.1111/j.1540-5826.2007.00230.x</a></div>
<div class="reference">Murphy, M. M. (2006). <em>The history and philosophy of education: Voices of educational
        pioneers.</em>
    Pearson/Merrill Prentice Hall.</div>
<div class="reference">Myers, J., & Case, R. (2008). Beyond mere definition: Elementary social studies. <em>The
        anthology of
        social studies: Issues and strategies for elementary teachers,</em> 48–59. Pacific Educational Press.</div>
<div class="reference">National Reading Panel (U.S.) & National Institute of Child Health and Human Development (U.S.).
    (2000). <em>Report of the National Reading Panel: Teaching children to read: An evidence-based assessment of the
        scientific research literature on reading and its implications for reading instruction.</em> U.S. Dept. of
    Health and
    Human Services, Public Health Service, National Institutes of Health, National Institute of Child Health and Human
    Development.</div>
<div class="reference">Neatby, H. (1953). <em> So little for the mind.</em> Clarke, Irwin & Company Ltd.</div>
<div class="reference">Neuman, S. B., Kaefer, T., & Pinkham, A. (2014). Building background knowledge. <em>Reading
        Teacher,</em>
    68(2), 145–148. <a href="https://doi.org/10.1002/trtr.1314" target="_blank">https://doi.org/10.1002/trtr.1314</a>
</div>
<div class="reference">Oakley, B. A. (2014). <em>A mind for numbers: How to excel at math and science (even if you
        flunked
        algebra).</em> Jeremy P. Tarcher/Penguin.</div>
<div class="reference">Oliva, P. F., & Gordon, W. R. (2013). <em>Developing the curriculum</em> (8th ed.). Pearson/Allyn
    and
    Bacon.</div>
<div class="reference">Ontario Ministry of Education. (2016). <em>21st century competencies: Foundation document for
        discussion. Phase 1: Towards defining 21st century competencies for Ontario.</em> Ontario Ministry of Education.
    <a href="http://www.edugains.ca/resources21CL/About21stCentury/21CL_21stCenturyCompetencies.pdf"
        target="_blank">http://www.edugains.ca/resources21CL/About21stCentury/21CL_21stCenturyCompetencies.pdf</a>
</div>
<div class="reference">Organisation for Economic Co-operation and Development (OECD). (2016). Education 2030: Key
    competencies for the future. <a
        href="http://www.oecd.org/education/2030/E2030-CONCEPTUAL-FRAMEWORK-KEY-COMPETENCIES-FOR-2030.pdf"
        target="_blank">http://www.oecd.org/education/2030/E2030-CONCEPTUAL-FRAMEWORK-KEY-COMPETENCIES-FOR-2030.pdf</a>
</div>
<div class="reference">Ornstein, A. C., Pajak, E., & Ornstein, S. B. (2011). <em>Contemporary issues in curriculum</em>
    (5th
    ed.). Pearson.</div>
<div class="reference">Ornstein, A. C., & Hunkins, F. P. (2013). <em>Curriculum: foundations, principles, and
        issues</em> (6th
    ed.). Pearson.</div>



<div class="reference">Ozmon, H. (2012). <em>Philosophical foundations of education</em> (9th ed.). Pearson.</div>
<div class="reference">Parkay, F. W., Anctil, E. J., & Hass, G. (2014). <em>Curriculum leadership: Readings for
        developing
        quality educational programs</em> (10th ed.). Pearson Education.</div>
<div class="reference">Parkay, F. W., Anctil, E. J., & Hass, G. (2006). <em>Curriculum planning: A contemporary
        approach</em>
    (8th ed.). Pearson.</div>
<div class="reference">Parrila, R., Kirby, J. R., & McQuarrie, L. (2004). Articulation rate, naming speed, verbal
    short-term memory, and phonological awareness: Longitudinal predictors of early reading development? <em>Scientific
        Studies of Reading,</em> 8(1), 3–26. <a href="https://doi.org/10.1207/s1532799xssr0801_2"
        target="_blank">https://doi.org/10.1207/s1532799xssr0801_2</a></div>
<div class="reference">Perkins, D. (2014). <em>Future wise: Educating our children for a changing world.</em>
    Jossey-Bass.</div>
<div class="reference">Pluckrose, H., & Lindsay, J. (2020). <em>Cynical theories: How activist scholarship made
        everything
        about race, gender, and identity―and why this harms everybody.</em> Pitchstone Publishing.</div>
<div class="reference">Posner, G. J., & Rudnitsky, A. N. (2006). <em>Course design: A guide to curriculum development
        for
        teachers (7th ed.).</em> Pearson/Allyn and Bacon.</div>



<div class="reference">Purewal, S. (2017). Shakespeare in the classroom: to be or not to be? <em>Warwick Journal of
        Education (WJETT),</em> 1, 26–35.</div>
<div class="reference">Quick, R. H. (1890). <em>Essays on educational reformers.</em> <a
        href="https://archive.org/details/cu31924068565088"
        target="_blank">https://archive.org/details/cu31924068565088</a></div>
<div class="reference">Raptis, H., & Baxter, L. (2006). Analysis of an abandoned reform initiative: the case of
    mathematics in British Columbia. <em>Canadian Journal of Educational Administration and Policy,</em> 49, 1-22. <a
        href="https://files.eric.ed.gov/fulltext/EJ843434.pdf"
        target="_blank">https://files.eric.ed.gov/fulltext/EJ843434.pdf</a></div>
<div class="reference">Rayner, K., Foorman, B. R., Perfetti, C. A., Pesetsky, D., & Seidenberg, M. S. (2001). How
    psychological science informs the teaching of reading. <em>Psychological Science in the Public Interest,</em> 2(2),
    31–74. <a href="https://doi.org/10.1111/1529-1006.00004" target="_blank">https://doi.org/10.1111/1529-1006.00004</a>
</div>
<div class="reference">Ritchhart, R. (2015). <em>Creating cultures of thinking: The 8 forces we must master to truly
        transform our schools.</em> Jossey-Bass & Pfeiffer Imprints, Wiley.</div>
<div class="reference">Ritchhart, R., Church, M., & Morrison, K., & Perkins, D. N. (2011). <em>Making thinking visible:
        How
        to promote engagement, understanding, and independence for all learners.</em> Jossey-Bass.</div>
<div class="reference">Robinson, M. (2013). Trivium 21c: <em>Preparing young people for the future with lessons from the
        past.</em> Independent Thinking Press.</div>


<div class="reference">Robinson, M. (2019). <em>Curriculum: Athena versus the machine.</em> Crown House Publishing.
</div>
<div class="reference">Ryerson, E. (1846). <em>Report on a system of public elementary instruction for Upper
        Canada.</em> <a href="https://archive.org/download/reportonsystem00ryer/reportonsystem00ryer.pdf"
        target="_blank">https://archive.org/download/reportonsystem00ryer/reportonsystem00ryer.pdf</a></div>
<div class="reference">Schleicher, A. (2013). <em>The case for 21st-century learning.</em> <a
        href="https://www.oecd.org/general/thecasefor21st-centurylearning.htm"
        target="_blank">https://www.oecd.org/general/thecasefor21st-centurylearning.htm</a></div>
<div class="reference">Shapiro, A. M. (2004). How including prior knowledge as a subject variable may change outcomes of
    learning research. <em>American Educational Research Journal,</em> 41(1), 159–189. <a
        href="https://doi.org/10.3102/00028312041001159" target="_blank">https://doi.org/10.3102/00028312041001159</a>
</div>
<div class="reference">Shorris, E. (2000). Promoting the humanities, or: How to make the poor dangerous. <em>American
        Libraries,</em> 31(5), 46–48. <a href="http://www.jstor.org/stable/25637625"
        target="_blank">http://www.jstor.org/stable/25637625</a></div>
<div class="reference">Shorris, E. (1997, September). On the uses of a liberal education: II. As a weapon in the hands
    of the restless poor. <em>Harper’s Magazine,</em> 295(1768), 50–59.</div>
<div class="reference">Sinclair, M., Littlechild, W., & Wilson, M. (2015). <em>What we have learned: Principles of truth
        and
        reconciliation.</em> Winnipeg, MB: Truth and Reconciliation Commission. Retrieved June 2016 from <a
        href="http://www.trc.ca/assets/pdf/Principles%20of%20Truth%20and%20Reconciliation.pdf"
        target="_blank">http://www.trc.ca/assets/pdf/Principles%20of%20Truth%20and%20Reconciliation.pdf</a></div>
<div class="reference">Sowell, E. J. (2005). <em>Curriculum: an integrative introduction</em> (3rd ed.). Pearson/Merrill
    Prentice
    Hall.</div>
<div class="reference">Sowell, T. (2018). <em>Discrimination and disparities.</em> Basic Books.</div>
<div class="reference">Tanner, D., & Tanner, L. (2007). <em>Curriculum development: Theory into practice (4th ed.).</em>
    Pearson/Merrill Prentice Hall.</div>
<div class="reference">Tehie, J. B. (2007). <em>Historical foundations of education: Bridges from the ancient world to
        the
        present.</em> Pearson/Merrill Prentice Hall.</div>


<div class="reference">Truth and Reconciliation Commission of Canada. (2015). <em>Truth and Reconciliation Commission of
        Canada: Calls to Action.</em> <a href="http://www.trc.ca/assets/pdf/Calls_to_Action_English2.pdf"
        target="_blank">http://www.trc.ca/assets/pdf/Calls_to_Action_English2.pdf</a></div>
<div class="reference">Ungerleider, C., (2003). <em>Failing our kids: How we are ruining our public schools.</em>
    McClelland &
    Stewart.</div>
<div class="reference">United Nations. (2008). <em>United Nations declaration on the rights of Indigenous peoples.</em>
    <a href="http://www.un.org/esa/socdev/unpfii/documents/DRIPS_en.pdf"
        target="_blank">http://www.un.org/esa/socdev/unpfii/documents/DRIPS_en.pdf</a>
</div>
<div class="reference">Vezzali, L., Stathi, S., & Giovannini, D. (2012). Indirect contact through book reading:
    Improving adolescents’ attitudes and behavioural intentions toward migrants. <em>Psychology in the Schools,</em>
    49(2),
    148–162. <a href="https://doi.org/10.1002/pits.20621" target="_blank">https://doi.org/10.1002/pits.20621</a></div>

<div class="reference">Watras, J. (2002). <em>The foundations of educational curriculum and diversity: 1565 to the
        present.</em>
    Allyn and Bacon.</div>
<div class="reference">Wheeler, W. (2001). Thoughts on the responsibilities for indigenous/native studies. <em>The
        Canadian
        Journal of Native Studies,</em> 21(1), 97–104.</div>
<div class="reference">Wieman, C. (2000). “A simple country doctor”: Remembering Dr Clare Brant. <em>The Canadian
        Journal of
        Psychiatry,</em> 45(7), 627–629. <a href="https://doi.org/10.1177/070674370004500704"
        target="_blank">https://doi.org/10.1177/070674370004500704</a></div>
<div class="reference">Wiggins, G. P., & McTighe, J. (2005). <em>Understanding by design.</em> Association for
    Supervision and
    Curriculum Development.</div>
<div class="reference">Wiles, J. (2005). <em>Curriculum essentials: A resource for educators</em> (2nd ed.).
    Pearson/Allyn &
    Bacon.</div>
<div class="reference">Wiles, J., & Bondi, J. (2005). <em>Curriculum development: A guide to practice</em> (2nd ed.). C.
    E.
    Merrill.</div>
<div class="reference">Willingham, D. T. (2009). <em>Why don’t students like school?: A cognitive scientist answers
        questions about how the mind works and what it means for your classroom.</em> Jossey-Bass.</div>
<div class="reference">Winston, J., & Tandy, M. (2012). <em>Beginning Shakespeare 4–11.</em> Routledge.</div>
<div class="reference">Wren, S. (2001). <em>The cognitive foundations of learning to read: A framework.</em> Southwest
    Educational Development Laboratory.</div>
<div class="reference">Zeidner, M., Roberts, R. D., & Matthews, G. (2002). Can emotional intelligence be schooled? A
    critical review. <em>Educational Psychologist,</em> 37(4), 215–231. <a
        href="https://doi.org/10.1207/S15326985EP3704_2" target="_blank">https://doi.org/10.1207/S15326985EP3704_2</a>
</div>
<div class="reference">Zwaagstra, M. (2020). <em>A sage on the stage: Common-sense reflections on teaching and
        learning.</em>
    John Catt Educational Ltd.</div>