import { Component, Input, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { FilterState, IFilters, IOption, ISelectedFilters } from '@state/filter.state';
import { Observable } from 'rxjs';

@Component({
    selector: 'curriculum-search-container',
    template: `<curriculum-search 
    >
    </curriculum-search>`,
})
export class SearchContainerComponent implements OnInit {
    // @Select(FilterState.selected) selected$: Observable<ISelectedFilters>;
    // @Select(FilterState.filters) filters$: Observable<IFilters>;

    selectedCurriculumId: string;

    constructor(private route: ActivatedRoute, private store: Store) { }

    ngOnInit(): void {
        // const params = this.route.snapshot.params;
        // if (params.sid && params.gid && params.cid) {
        //     this.store.dispatch([new OldCurriculum.Load(params.sid), new Filter.Select(params.gid, params.sid, params.cid), new Draft2018.Load()]);
        // } else if (params.sid && params.gid) {
        //     const cid = this.store.selectSnapshot(FilterState.selectedCurriculum).id;
        //     this.store.dispatch([new OldCurriculum.Load(params.sid), new Filter.Select(params.gid, params.sid, cid), new Draft2018.Load()]);
        // }
    }
}

@Component({
    selector: 'curriculum-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
    @Input() selected: ISelectedFilters;
    @Input() filters: IFilters;

    searchTerm: string;
    selectedCurriculumId: string;

    constructor(private store: Store) { }

    ngOnInit(): void {
    }


    onCurriculumChange(event: MatSelectChange): void {
        // this.store.dispatch([new Filter.Select(event.value)]);
    }
}
