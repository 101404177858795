import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight',
})
export class HighlightPipe implements PipeTransform {
  transform(value: string, searchTerm: string): string {
    if (!value || !searchTerm) {
      return value;
    }

    let result = value;
    searchTerm.split(' ').map((x) => {
      let reg = '(' + x + ')(?![^<]*>|[^<>]*</)';
      let regex = new RegExp(reg, 'i');
      if (!value.match(regex)) {
        return value;
      }

      const matchStartPosition = value.match(regex).index;
      const matchEndPosition = matchStartPosition + value.match(regex)[0].toString().length;
      const originalTextFoundByRegex = value.substring(matchStartPosition, matchEndPosition);

      result = result.replace(
        regex,
        `<mark class="sch-Result_Highlight">${originalTextFoundByRegex}</mark>`
      );
    });

    return result;

    // const startIndex = value.toLowerCase().indexOf(searchTerm.toLowerCase());
    // if (startIndex === -1) {
    //     return value;
    // }

    // const endIndex = startIndex + searchTerm.length;
    // const textBeforeHighlight = value.substring(0, startIndex);
    // const highlightedText = value.substring(startIndex, endIndex);
    // const textAfterHighlight = value.substring(endIndex);

    // return `${textBeforeHighlight}${highlightedText}${textAfterHighlight}`;
  }
}
