import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SaskCurriculumService {

    constructor(private http: HttpClient) { }

    get(): Observable<ISaskCategory[]> {
        return this.http.get<ISaskCategory[]>('/assets/pos/sask04092021.json');
    }
}

export interface ISaskCategory {
    categoryCode: string;
    strands: ISaskStrand[];
}

export interface ISaskStrand {
    name: string;
    content: string;
    outcomes: ISaskOutcome[];
}

export interface ISaskOutcome {
    intendedGradeLevel: string;
    content: string;
    note: string;
    indicatorNote: string;
    postIndicatorNote: string;
    indicators: string[];
    category: string;
    url: string;
}
