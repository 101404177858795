import { Injectable } from '@angular/core';
import { Selector, State } from '@ngxs/store';
import { IBcCategory } from '@services/bc-curriculum.service';
import { IEu } from '@services/draft-2018.service';
import { IAlbertaDraft2021Curriculum, ICurriculum } from '@services/new-curriculum.service';
import { IPos } from '@services/old-curriculum.service';
import { ISaskCategory } from '@services/sask-curriculum.service';
import { BcCurriculumState, BcCurriculumStateModel } from './bc-curriculum.state';
import { Draft2018State, Draft2018StateModel } from './draft-2018.state';
import {
  CurriculumType,
  FilterState,
  FilterStateModel,
  ICurriculumOption,
  ISelectedFilters,
} from './filter.state';
import { NewCurriculumState, NewCurriculumStateModel } from './new-curriculum.state';
import { OldCurriculumState, OldCurriculumStateModel } from './old-curriculum.state';
import { SaskCurriculumState, SaskCurriculumStateModel } from './sask-curriculum.state';

import * as _ from 'lodash';

export class CompareStateModel {}

const defaults: CompareStateModel = {};

@State<CompareStateModel>({
  name: 'compare',
  defaults: defaults,
})
@Injectable()
export class CompareState {
  constructor() {}

  @Selector([
    FilterState,
    NewCurriculumState,
    OldCurriculumState,
    Draft2018State,
    SaskCurriculumState,
    BcCurriculumState,
  ])
  static selectedLeft(
    state: CompareStateModel,
    filterState: FilterStateModel,
    newCurriculumState: NewCurriculumStateModel,
    oldCurriculumState: OldCurriculumStateModel,
    draft2018State: Draft2018StateModel,
    saskCurriculumState: SaskCurriculumStateModel,
    bcCurriculumState: BcCurriculumStateModel
  ): IGeneralCurriculum {
    const curriculumOption = filterState.filters.curriculumOptions.find(
      (x) => x.id === filterState.selectedLeft.selectedCurriculumId
    );
    return this.getSelected(
      curriculumOption.curriculumType,
      filterState.selectedLeft,
      newCurriculumState,
      oldCurriculumState,
      draft2018State,
      saskCurriculumState,
      bcCurriculumState,
      filterState.nlaCurriculumOptions
    );
  }

  @Selector([
    FilterState,
    NewCurriculumState,
    OldCurriculumState,
    Draft2018State,
    SaskCurriculumState,
    BcCurriculumState,
  ])
  static selectedRight(
    state: CompareStateModel,
    filterState: FilterStateModel,
    newCurriculumState: NewCurriculumStateModel,
    oldCurriculumState: OldCurriculumStateModel,
    draft2018State: Draft2018StateModel,
    saskCurriculumState: SaskCurriculumStateModel,
    bcCurriculumState: BcCurriculumStateModel
  ): IGeneralCurriculum {
    const curriculumOption = filterState.filters.curriculumOptions.find(
      (x) => x.id === filterState.selectedRight.selectedCurriculumId
    );
    const results = this.getSelected(
      curriculumOption.curriculumType,
      filterState.selectedRight,
      newCurriculumState,
      oldCurriculumState,
      draft2018State,
      saskCurriculumState,
      bcCurriculumState,
      filterState.nlaCurriculumOptions
    );
    return results;
  }

  static getSelected(
    curriculumType: CurriculumType,
    selectedFilters: ISelectedFilters,
    newCurriculumState: NewCurriculumStateModel,
    oldCurriculumState: OldCurriculumStateModel,
    draft2018State: Draft2018StateModel,
    saskCurriculumState: SaskCurriculumStateModel,
    bcCurriculumState: BcCurriculumStateModel,
    nlaCurriculumOptions: ICurriculumOption[]
  ): IGeneralCurriculum {
    let result: IGeneralCurriculum = {
      albertaCurrent: undefined,
      albertaDraft2018: undefined,
      albertaDraft2021: undefined,
      sask: undefined,
      bc: undefined,
    };

    for (let item of nlaCurriculumOptions) {
      result[item.curriculumName] = undefined;
    }

    if (curriculumType === CurriculumType.AlbertaCurrent) {
      result.albertaCurrent = oldCurriculumState.programs.find(
        (x) =>
          x.categoryCode === selectedFilters.selectedSubjectId &&
          x.intendedGradeLevel === selectedFilters.selectedGradeId
      );
    }

    if (curriculumType === CurriculumType.AlbertaDraft2018) {
      result.albertaDraft2018 = draft2018State.programs.find(
        (x) => x.course === selectedFilters.selectedSubjectId
      ).eus;
    }

    for (let item of nlaCurriculumOptions) {
      const course = newCurriculumState[item.courseName].find(
        (c) =>
          c.intendedGradeLevel === selectedFilters.selectedGradeId &&
          c.categoryCode === selectedFilters.selectedSubjectId
      );
      const ois = newCurriculumState[item.organizingIdeasName].filter(
        (x: ICurriculum) =>
          x.categoryCode === selectedFilters.selectedSubjectId &&
          x.courseCodes.some((y) => y[y.length - 1] === selectedFilters.selectedGradeId)
      );
      result[item.curriculumName] = {
        curriculum: ois,
        course: course,
      };
    }

    if (curriculumType === CurriculumType.Sask) {
      result.sask = saskCurriculumState.categories.find(
        (x) => x.categoryCode === selectedFilters.selectedSubjectId
      );
    }

    if (curriculumType === CurriculumType.Bc) {
      result.bc = bcCurriculumState.categories.find(
        (x) =>
          x.categoryCode === selectedFilters.selectedSubjectId &&
          x.intendedGradeLevel === selectedFilters.selectedGradeId
      );
    }

    return _.cloneDeep(result);
  }
}

export interface IGeneralCurriculum {
  albertaCurrent: IPos;
  albertaDraft2018: IEu[];
  albertaDraft2021: IAlbertaDraft2021Curriculum;
  albertaDraftDec2021?: IAlbertaDraft2021Curriculum;
  albertaDraftApr2022?: IAlbertaDraft2021Curriculum;
  alberta20220912?: IAlbertaDraft2021Curriculum;
  alberta20230308?: IAlbertaDraft2021Curriculum;
  alberta20240315?: IAlbertaDraft2021Curriculum;
  alberta2024Oct?: IAlbertaDraft2021Curriculum;
  sask: ISaskCategory;
  bc: IBcCategory;
}
